/* eslint-disable no-undef */
import {
  Box,
  Container,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../components/TitleHeader';
import SendIcon from '../../assets/images/Send message icon.png';
import { useSendMessageToAdminMutation } from '../../store/middlewares/messages';

function Help() {
  const { t } = useTranslation();
  const [problemText, setProblemText] = useState('');
  const [suggestionText, setSuggestionText] = useState('');

  const [sendMessageToAdmin, { isError, error: messageSendError }] =
    useSendMessageToAdminMutation();

  const handleSendSuggestion = () => {
    if (suggestionText.trim() === '') {
      toast.error(`${t('Help.PleaseEnterSuggestion')}`);
      return;
    }

    try {
      sendMessageToAdmin({
        messageText: suggestionText,
      }).unwrap();

      setSuggestionText('');
      toast.success(`${t('Help.MessageSent')}`);
    } catch (error) {
      if (isError) {
        toast.error(`${t('Help.ThereWasError')}`);
      }
    }
  };

  const handleSendProblem = () => {
    if (problemText.trim() === '') {
      toast.error(`${t('Help.PleaseEnterProblem')}`);
      return;
    }

    try {
      sendMessageToAdmin({
        messageText: problemText,
      }).unwrap();

      setProblemText('');
      toast.success(`${t('Help.MessageSent')}`);
    } catch (error) {
      if (isError) {
        toast.error(`${t('Help.ThereWasError')}`);
      }

      console.log(messageSendError);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, px: 2, pb: 8 }}>
        <TitleHeader title={t('Help.Help')} color="#4A4A4A" />
        <Stack>
          <Typography
            fontSize={14}
            fontWeight={600}
            color="#4A4A4A"
            sx={{ mb: 2 }}
          >
            {t('Help.HavingAny')}
          </Typography>
          <OutlinedInput
            sx={{
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adjust the values as needed
              backgroundColor: 'white',
            }}
            size="small"
            multiline
            rows={4}
            name="Description"
            value={problemText}
            onChange={(e) => setProblemText(e.target.value)}
            placeholder={t('Help.Describe')}
          />
          <Paper
            elevation={1}
            sx={{
              py: 1,
              px: 2,
              borderRadius: 2,
              display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              mt: 2,
              width: 'auto',
              maxWidth: 'fit-content', // Ensures the component doesn't grow beyond the size of its content

              cursor: 'pointer',
              userSelect: 'none',
              marginLeft: 'auto',
              mb: 3,
            }}
            onClick={handleSendProblem}
          >
            <Box
              component="img"
              sx={{
                width: 15,
              }}
              src={SendIcon}
              alt="Send Icon"
            />
            <Typography fontSize={12} fontWeight={600} color="#6964E6">
              {t('Help.SendMessage')}
            </Typography>
          </Paper>
          <Typography
            fontSize={14}
            fontWeight={600}
            color="#4A4A4A"
            sx={{ mb: 2 }}
          >
            {t('Help.DoYouHave')}
          </Typography>
          <OutlinedInput
            sx={{
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adjust the values as needed
              backgroundColor: 'white',
            }}
            size="small"
            multiline
            rows={4}
            name="Description"
            value={suggestionText}
            onChange={(e) => setSuggestionText(e.target.value)}
            placeholder={t('Help.WriteSuggestion')}
          />
          <Paper
            elevation={1}
            sx={{
              py: 1,
              px: 2,
              borderRadius: 2,
              display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
              mt: 2,
              width: 'auto',
              maxWidth: 'fit-content', // Ensures the component doesn't grow beyond the size of its content

              cursor: 'pointer',
              userSelect: 'none',
              marginLeft: 'auto',
              mb: 4,
            }}
            onClick={handleSendSuggestion}
          >
            <Box
              component="img"
              sx={{
                width: 15,
              }}
              src={SendIcon}
              alt="Send Icon"
            />
            <Typography fontSize={12} fontWeight={600} color="#6964E6">
              {t('Help.SendMessage')}
            </Typography>
          </Paper>
        </Stack>
      </Box>
    </Container>
  );
}

export default Help;
