/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Dialog, DialogTitle, IconButton, Box } from '@mui/material';
import cancelIcon from '../assets/images/Cross (gray background).png';

function LogoutDialog({ open, handleClose, handleLogout, yes, no, content }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <DialogTitle
        id="logout-dialog-title"
        sx={{
          fontSize: '16px',
          fontFamily: 'Inter,Regular',
          fontWeight: 600,
          color: '#707070',
          mt: 3,
        }}
      >
        {content}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src={cancelIcon} width={20} alt="close" />
        </IconButton>
      </DialogTitle>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          px: 3,
          justifyContent: 'space-evenly',
          gap: '20px',
          mb: 3,
        }}
      >
        <Button
          fullWidth
          onClick={handleClose}
          variant="outlined"
          sx={{
            color: '#707070',
            fontWeight: 600,
            border: '1px solid #A7A7A77A',
            boxShadow: 'none',
          }}
        >
          {no}
        </Button>
        <Button
          fullWidth
          onClick={handleLogout}
          color="primary"
          variant="contained"
          sx={{
            color: '#368C36',
            backgroundColor: '#E0EECE',
            fontWeight: 600,
            border: '1px solid #368C367A',
            '&:hover': {
              fontWeight: 600,
              border: '1px solid #368C36',
              backgroundColor: '#E0EECE',
              boxShadow: 'none',
            },
          }}
        >
          {yes}
        </Button>
      </Box>
    </Dialog>
  );
}

export default LogoutDialog;
