/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Chip,
  Stack,
  TextField,
  InputAdornment,
  Paper,
  Checkbox,
  useMediaQuery,
  Grid,
  AppBar,
  Toolbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useParams } from 'react-router-dom';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import BlockIcon from '@mui/icons-material/Block';
import { toast } from 'react-toastify';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  useDeleteMessageThreadMutation,
  useGetMessagesBetweenTwoUsersQuery,
} from '../../store/middlewares/messages';
import Loader from '../../components/Loader';
import formatCreatedAt from '../../utils/formatCreatedDate';
import ListingsFooter from '../../components/ListingsFooter';
import ChatTitleHeader from '../../components/ChatTitleHeader';
import LogoutDialog from '../../components/LogoutDialog';
import ReportDialog from '../../components/ReportDialog';
import {
  setChatStatus,
  setSelectedListingId,
  setSelectedUserData,
} from '../../store/reducers/chat';
import {
  useBlockUserMutation,
  useGetUserProfileDetailsQuery,
  useUnblockUserMutation,
} from '../../store/middlewares/userApi';
import WebHeader from '../../components/WebHeader';
import ChatPage from './ChatPage';
import AdminChatPage from './AdminChatPage';
import { setSuccessMessage } from '../../store/reducers/auth';
import SucessMessage from '../../components/SucessMessage';
import NoImageUploaded from '../../assets/images/NoImageUploaded.png';

// import DeleteIcon from '../../assets/images/Delete.png';

function CategoryList() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { selectedUserName, selectedUserId, selectedUserBusinessname } =
    useSelector((state) => state.chat);
  const [searchTerm, setSearchTerm] = useState('');
  const [messages, setMessages] = useState('');
  const [deleteMode, setDeleteMode] = useState(false);
  const [selectedThreads, setSelectedThreads] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const { userId } = useParams();

  const [userChatOpen, setUserChatOpen] = useState(false);
  const [currentListingId, setCurrentListingId] = useState(null);
  const {
    data: groupedMessages,
    error,
    isLoading,
    isError,
    refetch,
  } = useGetMessagesBetweenTwoUsersQuery(userId);

  const { data: userDetails } = useGetUserProfileDetailsQuery({ userId });
  const [deleteMessages] = useDeleteMessageThreadMutation();
  const [BlockUser] = useBlockUserMutation();
  const [UnblockUser] = useUnblockUserMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    dispatch(
      setSelectedUserData({
        name: userDetails?.FullName,
        id: userId,
        businessName: userDetails?.BusinessName,
      })
    );
  }, [userDetails]);

  useEffect(() => {
    // Ensure groupedMessages is an array before setting it to messages
    if (Array.isArray(groupedMessages)) {
      setMessages(groupedMessages);
    }
  }, [groupedMessages]);

  // This function filters the messages based on the searchTerm
  const filterMessages = (term) => {
    const lowercasedTerm = term.toLowerCase();

    if (!groupedMessages) {
      return; // Exit early if groupedMessages is undefined or null
    }

    const filteredMessages = groupedMessages?.filter(
      (item) =>
        item.CategoryName.toLowerCase().includes(lowercasedTerm) ||
        item.SubCategoryName.toLowerCase().includes(lowercasedTerm) ||
        item.LastMessageSenderName.toLowerCase().includes(lowercasedTerm) ||
        item.LastMessageText.toLowerCase().includes(lowercasedTerm) ||
        // If you want to include date in the search, you'll need to format it to a string
        new Date(item.LastMessageTime)
          .toLocaleString()
          .toLowerCase()
          .includes(lowercasedTerm)
    );

    setMessages(filteredMessages);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterMessages(e.target.value);
  };

  const handleBlockUser = async () => {
    try {
      await BlockUser(selectedUserId).unwrap();

      refetch();

      dispatch(
        setSuccessMessage(`${t('Notifications.SuccessfullyBlockedUser')}`)
      );
    } catch (error) {
      toast.error(error.data.message);
    } finally {
      setConfirmBlock(false);
    }
  };

  const handleUnBlockUser = async () => {
    try {
      await UnblockUser(selectedUserId).unwrap();
      refetch();
      dispatch(
        setSuccessMessage(`${t('Notifications.SuccessfullyUnBlockedUser')}`)
      );
    } catch (error) {
      toast.error(error.data.message);
    } finally {
      setConfirmBlock(false);
    }
  };

  const handleReportUser = () => {
    setReportDialogOpen(true);
  };

  const handleSelectThreads = () => {
    setDeleteMode(true);
  };

  const webStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  };

  const handleSelectThread = (e, listingId) => {
    e.stopPropagation();
    if (selectedThreads.includes(listingId)) {
      const filteredThreadIds = selectedThreads.filter(
        (thread) => thread !== listingId
      );
      setSelectedThreads(filteredThreadIds);
    } else {
      setSelectedThreads([...selectedThreads, listingId]);
    }
  };

  const handleNavigate = (listingId, data) => {
    if (deleteMode) {
      return;
    }
    dispatch(setSelectedListingId(listingId));
    dispatch(setChatStatus(false));

    if (isMobile) {
      navigate(`/conversations/chat/${listingId}`);
    } else {
      setUserChatOpen(true);
    }
  };

  const handleBlockClick = (isBlocked) => {
    setConfirmBlock(true);
  };

  const handleConfirmDelete = () => {
    if (selectedThreads.length > 0) {
      setConfirmDelete(true);
    } else {
      toast.info(`${t('Notifications.YouNeedSelectAtLeastOnethread')}`);
    }
  };

  const handleThreadsDelete = () => {
    if (selectedThreads.length < 1) {
      toast.error(`${t('Notifications.PleaseSelectOneThreadToDelete')}`);
      return;
    }
    try {
      deleteMessages(selectedThreads);

      dispatch(setSuccessMessage(`${t('DeletedMessages')}`));
    } catch (err) {
      toast.error(`${t('Notifications.ThereWasErrorDeletingThreads')}`);
    } finally {
      setSelectedThreads([]);
      setDeleteMode(false);
      setConfirmDelete(false);
      refetch();
    }
  };

  if (error?.status === 500) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  if (error?.status === 500) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  const isBlocked = messages[0]?.IsBlocked;

  const threadCount = selectedThreads.length;
  const threadLabel =
    threadCount > 1 ? t('Message.threads') : t('Message.thread');

  const deleteConfirmationMessage = t('Message.deleteConfirmation', {
    count: threadCount,
    thread: threadLabel,
    userName: selectedUserName,
  });

  // Call this function whenever the searchTerm changes

  return (
    <>
      {!isMobile && <WebHeader />}

      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          minHeight={isMobile ? '90vh' : '76vh'}
          // overflow={'scroll'}
          width={'100%'}
          sx={{
            boxShadow: isMobile ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {!isMobile && (
            <AppBar
              position="relative"
              sx={{
                boxShadow: '4px 2px 0px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'white',
                height: '50px',
                width: '98%',
                mt: 0.25,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: 2,
                  height: '50px',
                }}
              >
                <Typography
                  fontWeight={700}
                  color={'#4A4A4A'}
                  fontSize={18}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',

                    gap: 2,
                  }}
                >
                  <ArrowBackIosIcon
                    onClick={() => navigate(-1)}
                    sx={{
                      fontSize: 20,
                      color: '#4A4A4A',
                      fontWeight: 700,
                      cursor: 'pointer',
                    }}
                  />
                  {selectedUserName}
                </Typography>
                <Typography fontWeight={600} color={'#4A4A4A'} fontSize={14}>
                  {selectedUserBusinessname}
                </Typography>
              </Box>
            </AppBar>
          )}
          <SucessMessage />

          <Box
            sx={{
              mt: isMobile ? 8 : 0,
              pb: isMobile ? 8 : 2,

              display: 'flex',
              flexDirection: 'column',
              pt: isMobile ? 0 : 2,
            }}
          >
            <Box sx={{ px: isMobile ? 2 : 4 }}>
              {isMobile && (
                <ChatTitleHeader title={selectedUserName} color="#4A4A4A" />
              )}
              {deleteMode ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  gap="10px"
                  sx={{ mb: 3 }}
                >
                  <Typography
                    variant="h6"
                    fontSize="16px"
                    fontWeight="700"
                    color="#6964E6"
                  >
                    {t('SelectAndDeleteThreads')}
                  </Typography>
                  <Paper
                    elevation={1}
                    sx={{
                      border: '1px solid #6964E6',
                      py: 0.4,
                      px: 1,
                      cursor: 'pointer',
                      borderRadius: 2,
                    }}
                    onClick={() => setDeleteMode(false)}
                  >
                    <Typography fontSize={12} fontWeight={600} color="#6964E6">
                      {t('Message.ExitDelete')}
                    </Typography>
                  </Paper>
                </Box>
              ) : (
                <TextField
                  fullWidth
                  size="small"
                  value={searchTerm}
                  placeholder="Search for.."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  onChange={handleSearchChange}
                  sx={{
                    marginRight: '8px',
                    mb: 2,
                    pl: 0,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px', // Apply border-radius here
                      backgroundColor: 'white',
                      pl: 1,

                      '&:hover fieldset': {
                        borderColor: '#D5D5D57D', // Maintain border color on hover
                      },
                      '&.Mui-focused fieldset': {
                        // Maintain border-radius and style on focus
                        borderRadius: '10px',
                        borderColor: '#D5D5D57D', // Adjust to your theme's focus color if necessary
                      },
                    },
                    '& .MuiOutlinedInput-input': {
                      borderRadius: '10px', // Apply border-radius to the input as well
                      pl: '0 !important',
                      pt: 0.7,
                      pb: 0.9,
                      fontFamily: 'Inter,Regular',
                      fontWeight: '600',
                      // pb: 1.2,
                    },
                  }} // Adjust spacing as needed
                />
              )}
              {isBlocked === 1 && (
                <Typography
                  align="center"
                  fontWeight="600"
                  variant="subtitle2"
                  color="#E21414"
                  sx={{ mb: 2 }}
                >
                  {t('YouHaveBlocked')}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                height: isMobile ? '56vh' : '50vh',
                overflowY: 'scroll',
                overflowX: 'hidden',
                pl: isMobile ? 2 : 4,
                pr: isMobile ? 1 : 4,
                '&::-webkit-scrollbar': {
                  width: '5px', // Adjust scrollbar width
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#F8F8F8', // Scrollbar track color
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#DCDCDC', // Scrollbar handle color
                  borderRadius: '10px', // Scrollbar handle border radius
                  border: '1px solid #DCDCDC', // Optional: Scrollbar handle border
                },
              }}
            >
              {messages &&
                messages?.map((data) => (
                  <Card
                    onClick={() =>
                      messages[0]?.IsBlocked
                        ? null
                        : handleNavigate(data.ListingID, data)
                    }
                    key={data.ListingID}
                    // onClick={handleCardClick}
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      borderRadius: '10px',

                      backgroundColor: '#FDFDFD',
                      pt: 1,
                      pl: 1,
                      pr: 1,
                      mb: 2,
                      pb: 1,
                      position: 'relative',
                      border:
                        data?.ListingType === 'Seller'
                          ? '1px solid #368C369C'
                          : '1px solid #F2BD389C',
                      cursor: messages[0]?.IsBlocked ? 'default' : 'pointer',
                      opacity: messages[0]?.IsBlocked ? 0.5 : 1,
                    }}
                  >
                    {deleteMode ? (
                      <Checkbox
                        name="terms"
                        value={selectedThreads.includes(data?.ListingID)}
                        onChange={(e) => handleSelectThread(e, data?.ListingID)}
                        sx={{
                          position: 'absolute',
                          top: -9,
                          right: -9,
                          color: '#6964E6',
                          '& .MuiSvgIcon-root': { fontSize: 20 },
                          '&&.Mui-checked': {
                            color: '#6964E6',
                          },
                          '&& .MuiIconButton-root': {
                            color: '#6964E6', // color when unchecked
                            '&&:not(.Mui-checked)': {
                              borderColor: '#6964E6', // border color when unchecked
                            },
                          },
                        }}
                      />
                    ) : (
                      <Box
                        position="absolute"
                        top={0}
                        right={0}
                        sx={{
                          backgroundColor:
                            data?.ListingType === 'Seller'
                              ? '#94C754'
                              : '#F2BD38',
                          color: 'white',
                          fontWeight: 'bold',
                          width: '20px',
                          height: '20px',
                          textAlign: 'center',
                          fontSize: 14,
                          display:
                            data?.UnseenMessagesCount > 0 ? 'block' : 'none',
                        }}
                      >
                        {data?.IsBlocked ? 0 : data?.UnseenMessagesCount}
                      </Box>
                    )}

                    <Box
                      component="img"
                      sx={{
                        width: 80, // You can adjust as needed
                        borderRadius: '10%',
                        height: 60,
                      }}
                      src={data.ImageURL ?? NoImageUploaded} // Replace with your image path
                      alt="Admin"
                    />
                    <CardContent
                      sx={{
                        pt: 0.75,
                        pb: 0.75,
                        flex: '1 0',

                        mr: 0,
                        pr: 0.5,
                        pl: 1.5,
                        display: 'flex',
                        gap: '10px',
                        flexDirection: 'column',
                        justifyContent: 'space-around',
                        alignContent: 'space-around',
                        alignItems: 'space-around',
                        justifyItems: 'space-around',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'start',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography
                          gutterBottom
                          fontWeight={600}
                          fontSize={12}
                          sx={{
                            color:
                              data.ListingType === 'Seller'
                                ? '#368C36'
                                : '#EB9D28',
                            // maxWidth: '90px',
                            mb: 0,
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1, // Number of lines you want to display
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre-wrap',
                            width: '50%',
                          }}
                          lineHeight={1.3}
                        >
                          {data.SubCategoryName} &nbsp;
                        </Typography>
                        <Typography
                          gutterBottom
                          fontSize={12}
                          fontWeight={600}
                          lineHeight={1.3}
                          sx={{
                            // backgroundColor: '#94C754',
                            color:
                              data.ListingType === 'Seller'
                                ? '#368C36'
                                : '#EB9D28',
                            mb: 0,

                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 1, // Number of lines you want to display
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'pre-wrap', // Change to 'nowrap' if you don't want the text to wrap to the next line
                          }}
                        >
                          | &nbsp;{data.CategoryName}
                        </Typography>
                      </Box>
                      <Typography
                        color="#5C5C5C"
                        fontSize="12px"
                        lineHeight={1.3}
                        sx={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: 1, // Number of lines you want to display
                          WebkitBoxOrient: 'vertical',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'pre-wrap', // Change to 'nowrap' if you don't want the text to wrap to the next line
                        }}
                      >
                        {!data?.IsBlocked && (
                          <>
                            <b>{data?.LastMessageSenderName} :</b>{' '}
                            {data?.LastMessageText}
                          </>
                        )}
                      </Typography>
                    </CardContent>

                    <Box>
                      <Typography
                        lineHeight={1.1}
                        fontSize={9}
                        fontWeight={600}
                        color="#707070"
                        sx={{ display: 'flex', mt: 0.5 }}
                        align="right"
                      >
                        {
                          formatCreatedAt(data?.LastMessageTime)?.split(
                            ' '
                          )?.[0]
                        }
                      </Typography>
                      <Typography
                        lineHeight={1.1}
                        fontSize={9}
                        fontWeight={600}
                        color="#707070"
                        sx={{ display: 'flex' }}
                        align="right"
                      >
                        {
                          formatCreatedAt(data?.LastMessageTime)?.split(
                            ' '
                          )?.[1]
                        }
                      </Typography>
                    </Box>
                  </Card>
                ))}
            </Box>
            {/* sx={{ position: 'absolute', bottom: 80, width: '90%' }} */}
            {!deleteMode ? (
              <Box
                sx={{
                  // bottom: isMobile ? 75 : 20,
                  // width: isMobile ? '92%' : '100%',
                  width: '100%',

                  px: isMobile ? 2 : 4,
                  marginTop: 'auto',
                }}
              >
                <Box display="flex" gap="10px">
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={handleReportUser}
                  >
                    <ReportGmailerrorredIcon
                      sx={{ color: '#707070', fontSize: 18 }}
                    />

                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t('Report.Report User')}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={() => handleBlockClick(messages[0]?.IsBlocked)}
                  >
                    <BlockIcon
                      sx={{
                        color: messages[0]?.IsBlocked ? 'red' : '#707070',
                        fontSize: 18,
                      }}
                    />
                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color={messages[0]?.IsBlocked ? 'red' : '#707070'}
                    >
                      {messages[0]?.IsBlocked
                        ? `${t('Block.UnblockUser')}`
                        : `${t('Block.BlockUser')}`}
                    </Typography>
                  </Paper>
                </Box>
                <Box>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={handleSelectThreads}
                  >
                    {/* {listing?.listing?.isFavorited === 1 ? (
            <StarIcon
              sx={{
                color:
                  listing?.listing?.isFavorited === 1 ? fontColor : '#707070',
                fontSize: 18,
              }}
            />
          ) : (
            <StarOutlineIcon sx={{ color: '#707070', fontSize: 18 }} />
          )} */}

                    {/* <Box
                component="img"
                sx={{
                  height: 20,
                  width: 20,
                  display: 'block', // Makes it a block-level element
                }}
                src={DeleteIcon}
                alt="Delete Icon"
              /> */}
                    <DeleteForeverIcon
                      sx={{ fontSize: 18, color: '#707070' }}
                    />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t('SelectAndDeleteThreads')}
                    </Typography>
                  </Paper>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',

                  px: isMobile ? 2 : 4,
                  marginTop: 'auto',
                }}
              >
                <Typography
                  align="center"
                  fontSize={12}
                  fontWeight={700}
                  color="#707070"
                  fullWidth
                >
                  {selectedThreads?.length === 0
                    ? `${t('Message.NoThreadsSelected')}`
                    : `${selectedThreads.length} ${
                        selectedThreads.length > 1 ? 'threads' : 'thread'
                      } ${t('Message.Selected')}`}
                </Typography>

                <Paper
                  elevation={1}
                  sx={{
                    py: 1,
                    px: 2,
                    borderRadius: 2,
                    display: 'flex', // Changed from 'flex' to 'inline-flex'
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    mt: 2,
                    cursor: 'pointer',
                    userSelect: 'none',
                    width: '100%',
                  }}
                  onClick={handleConfirmDelete}
                >
                  {/* <Box
              component="img"
              sx={{
                height: 20,
                width: 20,
                display: 'block', // Makes it a block-level element
              }}
              src={DeleteIcon}
              alt="Delete Icon"
            /> */}
                  <DeleteForeverIcon
                    sx={{
                      fontSize: 18,
                      color:
                        selectedThreads?.length === 0 ? 'lightgray' : '#FF0000',
                    }}
                  />
                  <Typography
                    fontSize={12}
                    fontWeight={600}
                    color={
                      selectedThreads?.length === 0 ? 'lightgray' : '#FF0000'
                    }
                  >
                    {t('Message.Delete')} ({selectedThreads?.length}) threads
                  </Typography>
                </Paper>
              </Box>
            )}

            {confirmDelete && (
              <LogoutDialog
                handleLogout={handleThreadsDelete}
                open={confirmDelete}
                handleClose={() => setConfirmDelete(false)}
                yes={t('Yes')}
                no={t('No')}
                content={deleteConfirmationMessage}
              />
            )}
            {confirmBlock && (
              <LogoutDialog
                handleLogout={
                  messages[0]?.IsBlocked ? handleUnBlockUser : handleBlockUser
                }
                open={confirmBlock}
                handleClose={() => setConfirmBlock(false)}
                yes={t('Yes')}
                no={t('No')}
                content={
                  messages[0]?.IsBlocked
                    ? `${t('Block.unblock')} ${selectedUserName}`
                    : `${t('Block.block')} ${selectedUserName}`
                }
              />
            )}
            {reportDialogOpen && (
              <ReportDialog
                open={reportDialogOpen}
                onClose={() => setReportDialogOpen(false)}
                type="User"
                id={selectedUserId}
                ReportType="User"
              />
            )}
            {isMobile && <ListingsFooter />}
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          md={8}
          lg={8}
          style={isMobile || userChatOpen ? null : webStyles}
        >
          {!isMobile &&
            (userChatOpen ? (
              <Box>
                <ChatPage />
              </Box>
            ) : (
              <Typography
                color="#5C5C5C"
                variant="subtitle2"
                fontWeight={600}
                sx={{ mb: isMobile ? 0 : 10 }}
              >
                {t('PleaseSelectAConversation')}
              </Typography>
            ))}
        </Grid>
      </Grid>
    </>
  );
}

export default CategoryList;
