import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer'; // Your combined reducers
// Import your API slices
import { userApi } from './middlewares/userApi';
import { listingsApi } from './middlewares/listingsApi';
import { categoriesApi } from './middlewares/categoriesApi';
import { errorLogApi } from './middlewares/errorLogApi';
import { listingImageApi } from './middlewares/listingImageApi';
import { reportsApi } from './middlewares/report';
import { notificationsApi } from './middlewares/notifications';
import { messagesApi } from './middlewares/messages';
// ... other imports

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist or blacklist if needed
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      userApi.middleware,
      listingsApi.middleware,
      categoriesApi.middleware,
      errorLogApi.middleware,
      listingImageApi.middleware,
      reportsApi.middleware,
      notificationsApi.middleware,
      messagesApi.middleware
    ),
});

export const persistor = persistStore(store);
