import React from 'react';
import { Box, Grid, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ListingRemovedImage from '../assets/images/ListingRemoved.png';
import WebHeader from './WebHeader';
import TitleHeader from './TitleHeader';

function ListingRemoved() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation();

  return (
    <Box sx={{ px: 3, my: isMobile ? 8 : 0 }}>
      {isMobile ? (
        <TitleHeader title={`Listing Removed`} color="#696969" />
      ) : (
        <WebHeader />
      )}
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'center', gap: '30px' }}
      >
        <Grid item xs={12}>
          <Box
            component="img"
            sx={{
              width: isMobile ? '100%' : 350,
            }}
            src={ListingRemovedImage}
            alt="Listing Removed"
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{ textAlign: 'center' }}>{t('ThisListingIsRemoved')}</h3>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ListingRemoved;
