import { Box, Button, FormControl, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/LanguageSelect';
import useStyles from './PrivacyAndTerms/style';
import LogoSmall from '../assets/images/Logo (small).png';

function NotFound() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box className={classes.rootContainer}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={4}
      >
        <Box
          component="img"
          sx={{
            width: 100,
          }}
          src={LogoSmall}
          onClick={() => navigate('/')}
          alt="Waste to Wealth Logo"
        />

        <LanguageSelect />
      </Box>
      <Typography
        variant="h5"
        className={classes.title}
        color="#707070"
        fontFamily="Inter,Bold"
        fontWeight="650"
        gutterBottom
      >
        {t('NotFound.Oops')}
      </Typography>
      <Typography
        variant="h4"
        className={classes.title}
        color="#E27614"
        fontFamily="Inter,Bold"
        fontWeight="650"
        gutterBottom
      >
        404
      </Typography>
      <Typography
        variant="h4"
        className={classes.title}
        color="#EB9D28"
        fontFamily="Inter,Bold"
        fontWeight="650"
      >
        ERROR
      </Typography>

      <Typography
        color="#5BA15B"
        fontFamily="Inter,Bold"
        fontWeight="600"
        gutterBottom
        sx={{ mt: 3, mb: 2 }}
        variant="subtitle2"
      >
        {t('NotFound.ThePage')}
      </Typography>
      <FormControl>
        <Button
          variant="outlined"
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            mb: 5,
            mx: 2,
            fontFamily: 'Inter,Bold',
            borderColor: 'rgba(0, 0, 0, 0.12)', // Border color for the disabled button
            color: 'white',
            minWidth: '270px',
            backgroundColor: '#5BA15B',
            '&&:hover': {
              backgroundColor: '#5BA15B',
            },
          }}
          onClick={() => navigate(-1)}
        >
          {t('NotFound.TakeMeBack')}
        </Button>
      </FormControl>
    </Box>
  );
}

export default NotFound;
