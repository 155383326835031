/* eslint-disable react/prop-types */
import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useSelector } from 'react-redux';

function ChatTitleHeader({ title, color }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { selectedUserBusinessname, selectedListingLink } = useSelector(
    (state) => state.chat
  );
  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        height: 50,
      }}
    >
      <Toolbar>
        <Typography
          fontWeight={700}
          color={color}
          fontSize={18}
          sx={{
            display: 'flex',
            alignItems: 'center',

            gap: 2,
          }}
        >
          <ArrowBackIosIcon
            onClick={() => navigate(-1)}
            sx={{
              fontSize: 20,
              color: '#4A4A4A',
              fontWeight: 700,
              cursor: 'pointer',
            }}
          />
          {title}
        </Typography>
        {pathname?.includes('chat') && title !== 'Admin' && (
          <Typography
            align="right"
            fontWeight={600}
            color="#0059B9"
            fontSize={14}
            sx={{ marginLeft: 'auto', mb: 0.25, textDecoration: 'underline' }}
          >
            <Link
              style={{ color: '#0059B9', textTransform: 'capitalize' }}
              to={selectedListingLink}
            >
              View Listing Details
            </Link>
          </Typography>
        )}
        {!pathname?.includes('chat') && title !== 'Admin' && (
          <Typography
            align="right"
            fontWeight={600}
            color="#4A4A4A"
            fontSize={14}
            sx={{ marginLeft: 'auto', mb: 0.25 }}
          >
            {selectedUserBusinessname}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default ChatTitleHeader;
