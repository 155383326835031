/* eslint-disable react/prop-types */
import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import useStyles from '../pages/CreateAccount/style';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function MultipleSelectPlaceholder({
  label,
  data,
  value,
  name,
  setValue,
  error,
  nameType,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <FormControl sx={{ mt: 0.5, width: '100%' }}>
        <FormHelperText
          id={label}
          sx={{
            ml: 1,
            mb: 0.3,
            color: '#66492E',
            fontWeight: '600',
            fontFamily: 'Inter,Medium',
            fontSize: '14px',
          }}
        >
          {label}*
        </FormHelperText>
        <Select
          className={classes.paperCustom}
          size="small"
          name={name}
          displayEmpty
          value={value ?? ''}
          onChange={setValue}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
          IconComponent={KeyboardArrowDownIcon} // Custom dropdown icon
          error={error}
          renderValue={(selected) => {
            if (selected === '') {
              // Placeholder text
              return <span>{`${t('AddListing.Select')} ${label}`}</span>;
            }
            // Display the selected item's content
            const selectedItem = data.find(
              (item) => item.CategoryID === selected
            );
            return selectedItem
              ? selectedItem[nameType]
              : `${t('AddListing.Select')} ${label}`;
          }}
        >
          {/* Conditionally render the placeholder MenuItem based on whether a value is selected
          {value === '' && (
            <MenuItem disabled value="">
              {t('AddListing.Select')} {label}
            </MenuItem>
          )} */}
          {data?.map((item) => (
            <MenuItem
              key={item.CategoryID}
              value={item.CategoryID}
              sx={{
                color: '#4A4A4A',
                fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                fontWeight: 600,
                fontSize: '16px', // Adjust the font size as needed
                mx: 2,
                pb: 0,
                pl: 1,
                mt: 0,
                borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                '&:last-child': {
                  borderBottom: 'none', // Remove border for the last item
                },
              }}
            >
              {/* {data[nameType] ?? item[nameType]} */}
              {item[nameType]}
            </MenuItem>
          ))}
          <MenuItem
            sx={{
              color: '#4A4A4A',
              fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
              fontWeight: 600,
              fontSize: '16px', // Adjust the font size as needed
              mx: 2,
              pb: 0,
              pl: 1,
              mt: 0,
              borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
              '&:last-child': {
                borderBottom: 'none', // Remove border for the last item
              },
            }}
            name="custom"
            value="Other" // Add a value for the custom option
          >
            {t('AddListing.Other')}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
