import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,
    padding: '0px 20px',
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    [theme.breakpoints.up('lg')]: {
      padding: '1em 25em 5em',
    },
    [theme.breakpoints.up('md')]: {
      padding: '1em 15em 3em',
    },
  },
  description: {
    color: '#5C5C5C',
    fontFamily: 'Inter,Regular',
  },
  paperCustom: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.2)', // Adjust the values as needed
    backgroundColor: 'white',
    borderColor: '#A7A7A770',
    borderRadius: '8px',
  },
}));

export default useStyles;
