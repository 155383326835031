import React, { useEffect } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
// import LogoSmall from '../../assets/images/Logo (small).png';
import useStyles from '../PrivacyAndTerms/style';
import TitleHeader from '../../components/TitleHeader';

function ContactUs() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box className={classes.rootContainer} sx={{ my: 8 }}>
      <TitleHeader title="Contact Us" color="#4A4A4A" />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? '20px' : '100px',
        }}
      >
        <Box>
          <Typography
            color="#4A4A4A"
            fontWeight={700}
            fontSize={14}
            gutterBottom
          >
            NAW JULIE AUNG
          </Typography>
          <Typography
            color="#4A4A4A"
            fontWeight={600}
            fontSize={12}
            gutterBottom
          >
            Technical Expert
          </Typography>
          <Typography
            color="#4A4A4A"
            fontWeight={600}
            fontSize={12}
            gutterBottom
          >
            Email:
          </Typography>
          <Typography
            color="#4A4A4A"
            fontWeight={600}
            fontSize={12}
            gutterBottom
            textDecoration="underline"
          >
            julie@preventplastics.org
          </Typography>
        </Box>
        <Box>
          <Typography
            color="#4A4A4A"
            fontWeight={700}
            fontSize={14}
            gutterBottom
          >
            NAING YE AUNG
          </Typography>
          <Typography
            color="#4A4A4A"
            fontWeight={600}
            fontSize={12}
            gutterBottom
          >
            Technical Expert
          </Typography>
          <Typography
            color="#4A4A4A"
            fontWeight={600}
            fontSize={12}
            gutterBottom
          >
            Email:
          </Typography>
          <Typography
            color="#4A4A4A"
            fontWeight={600}
            fontSize={12}
            gutterBottom
            textDecoration="underline"
          >
            naing@preventplastics.org
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default ContactUs;
