/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterCategoryArray: [],
  deliveryStatus: false,
  siteInspectionStatus: false,
  searchSellerListings: '',
  sortType: '',
};

export const sellerListingSlice = createSlice({
  name: 'sellerListing',
  initialState,
  reducers: {
    addItemToFilterArray: (state, action) => {
      state.filterCategoryArray = [];
      state.filterCategoryArray = [...action.payload];
    },
    removeItemFromFilterArray: (state, action) => {
      const filterArrayCopy = state.filterCategoryArray.filter(
        (item) => item !== action.payload
      );
      state.filterCategoryArray = filterArrayCopy;
    },
    clearFilterArray: (state) => {
      state.filterCategoryArray = [];
      state.sortType = '';
    },
    setDeliverStatus: (state, action) => {
      state.deliveryStatus = action.payload;
    },
    setSiteInspectionStatus: (state, action) => {
      state.siteInspectionStatus = action.payload;
    },
    setSearchSellerListings: (state, action) => {
      state.searchSellerListings = action.payload;
    },
    setSortType: (state, action) => {
      state.sortType = action.payload;
    },
    setToInitialState: (state) => {
      state.filterCategoryArray = [];
      state.deliveryStatus = false;
      state.siteInspectionStatus = false;
      state.searchSellerListings = '';
      state.sortType = '';
    },

    // Optionally add other reducers like 'updateProfile', 'register', etc.
  },
});

export const {
  addItemToFilterArray,
  removeItemFromFilterArray,
  clearFilterArray,
  setDeliverStatus,
  setSiteInspectionStatus,
  setSearchSellerListings,
  setSortType,
  setToInitialState,
} = sellerListingSlice.actions;
export default sellerListingSlice.reducer;
