// logErrorFunction.js
// import axios from 'axios';

const logErrorToServer = async () => {
  // try {
  //   // Replace with your actual API endpoint
  //   await axios.post(
  //     `${process.env.REACT_APP_API_URL}/error/error-log`,
  //     errorData
  //   );
  // } catch (error) {
  //   console.error('Error logging to server:', error);
  // }
};

export default logErrorToServer;
