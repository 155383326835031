const groupByOtherUserId = (data) => {
  const groupedData = {};
  const orderOfUsers = []; // Array to keep track of the order of user IDs

  data?.forEach((item) => {
    if (!groupedData[item.OtherUserID]) {
      groupedData[item.OtherUserID] = [];
      orderOfUsers.push(item.OtherUserID); // Record the order of this userID's first occurrence
    }
    groupedData[item.OtherUserID].push(item);
  });

  // Convert groupedData back to an array, preserving the order based on first message occurrence
  const orderedGroupedData = orderOfUsers.map((userId) => ({
    userId,
    messages: groupedData[userId],
  }));

  return orderedGroupedData;
};
export default groupByOtherUserId;
