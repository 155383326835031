/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';

import Sidebar from '../../components/Sidebar';
import MessageCard from '../../components/MessageCard';
import LogoSmall from '../../assets/images/Logo (small).png';
import {
  useGetMessagesQuery,
  useGetMessagesWithAdminQuery,
  useGetUserChatListingsQuery,
} from '../../store/middlewares/messages';
import Loader from '../../components/Loader';
import formatCreatedAt from '../../utils/formatCreatedDate';
import ListingsFooter from '../../components/ListingsFooter';
import groupByOtherUserId from '../../utils/groupByOtherUserId';
import { setCurrentPageFooter } from '../../store/reducers/listing';
import WebHeader from '../../components/WebHeader';
import AdminChatPage from './AdminChatPage';

function Messages() {
  const [groupedMessagesState, setGroupedMessagesState] = useState([]);
  const [adminMessages, setAdminMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [adminChatOpen, setAdminChatOpen] = useState(false);
  const dispatch = useDispatch;
  const {
    data: messages,
    isLoading,
    isError,
    refetch: adminRefetch,
    error: messagesError,
  } = useGetMessagesWithAdminQuery({
    refetchOnMountOrArgChange: true,
  });
  const {
    data: groupedMessages,
    isLoading: groupedLoding,
    error,
    refetch,
  } = useGetMessagesQuery();
  const auth = useSelector((state) => state.auth);
  const { data: userChatListings, refetch: userChatListingsRefetch } =
    useGetUserChatListingsQuery();

  useEffect(() => {
    refetch();
    adminRefetch();
    userChatListingsRefetch();
    if (!error) {
      setGroupedMessagesState(groupedMessages);
    }
  }, [refetch, adminRefetch, groupedMessages, userChatListingsRefetch]);

  useEffect(() => {
    if (!isLoading && !isError) {
      setAdminMessages(messages);
    }
  }, [messages]);

  const refetchMessages = () => {
    refetch();
    adminRefetch();
  };

  if (isError && error) {
    return (
      <Typography>
        There was as error fetching messages.{' '}
        <Button onClick={refetchMessages}>Refresh</Button>
      </Typography>
    );
  }

  if (isLoading || groupedLoding) {
    return <Loader />;
  }

  if (isError && error) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  const webStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  };

  const handleSearch = (e) => {
    // Assuming adminMessages and groupedMessages are your state variables holding the respective arrays
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setAdminMessages(messages);
      setGroupedMessagesState(groupedMessages);

      return;
    }
    const adminMessagesCopy = [...adminMessages]; // Your admin messages array
    const groupedMessagesCopy = [...groupedMessagesState]; // Your messages between users array

    const lowerCaseSearchTerm = e.target.value?.toLowerCase();

    // Filter admin messages
    const filteredAdminMessages = adminMessagesCopy.filter((message) =>
      message?.ChattingWith?.toLowerCase()?.includes(lowerCaseSearchTerm)
    );

    // Filter grouped messages
    const filteredGroupedMessages = groupedMessagesCopy?.filter(
      (message) =>
        message?.CategoryName?.toLowerCase()?.includes(lowerCaseSearchTerm) ||
        message?.SubCategoryName?.toLowerCase()?.includes(
          lowerCaseSearchTerm
        ) ||
        message?.ReceiverName?.toLowerCase()?.includes(lowerCaseSearchTerm)
    );

    setAdminMessages(filteredAdminMessages);
    setGroupedMessagesState(filteredGroupedMessages);
  };

  return (
    <Box sx={{ pb: isMobile ? 8 : 'auto' }}>
      {!isMobile && <WebHeader />}
      <Grid container sx={{ mt: isMobile ? 0 : 0 }}>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          minHeight={isMobile ? '90vh' : '76vh'}
          // overflow={'scroll'}
          width={'100%'}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={'center'}
            sx={{ px: 2, pb: 3, pt: isMobile ? 1 : 2 }}
          >
            <TextField
              fullWidth
              size="small"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search for user, category"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              sx={{
                flex: 1,

                pl: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px', // Apply border-radius here
                  backgroundColor: 'white',
                  pl: 1,
                  '&:hover fieldset': {
                    borderColor: 'gray', // Maintain border color on hover
                  },
                  '&.Mui-focused fieldset': {
                    // Maintain border-radius and style on focus
                    borderRadius: '10px',
                    borderColor: 'gray', // Adjust to your theme's focus color if necessary
                  },
                },
                '& .MuiOutlinedInput-input': {
                  borderRadius: '10px', // Apply border-radius to the input as well
                  pl: '0 !important',
                  pt: 0.7,

                  pb: 0.9,
                  fontFamily: 'Inter,Regular',
                  fontWeight: '600',
                  // pb: 1.2,
                },
              }} // Adjust spacing as needed
            />
            {isMobile && <Sidebar />}
          </Box>
          <Box
            sx={{
              height: isMobile ? '90vh' : '70vh',
              overflowY: 'scroll',
              overflowX: 'hidden',
              px: isMobile ? 0 : 2,
              pt: 1,
              '&::-webkit-scrollbar': {
                width: '5px', // Adjust scrollbar width
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#F8F8F8', // Scrollbar track color
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#A8A8A8', // Scrollbar handle color
                borderRadius: '10px', // Scrollbar handle border radius
                border: '1px solid #A8A8A8', // Optional: Scrollbar handle border
              },
            }}
          >
            {adminMessages?.length > 0 && (
              <MessageCard
                image={LogoSmall}
                imagePresent={false}
                message={
                  adminMessages[adminMessages.length - 1 ?? 0]?.MessageText ??
                  ''
                }
                lastMessageBy={
                  adminMessages[adminMessages.length - 1 ?? 0]?.SenderID === -1
                    ? 'Admin'
                    : 'You'
                }
                time={formatCreatedAt(
                  adminMessages[adminMessages.length - 1 ?? 0]?.CreatedAt
                )}
                userName="Admin"
                setAdminChatOpen={setAdminChatOpen}
                isSeen={
                  !(
                    adminMessages[adminMessages.length - 1]?.SenderID === -1 &&
                    adminMessages[adminMessages.length - 1]?.SeenByReceiver ===
                      0
                  )
                }
              />
            )}
            {groupByOtherUserId(groupedMessagesState)?.map(
              ({ userId, messages }) => {
                // Destructure to get userId and messages from each grouped item
                // Extract categories from the messages in the group
                const categories = messages?.map((message) => ({
                  categoryName: message.CategoryName,
                  listingType: message.ListingType,
                }));
                // Assume the receiver's name is the same for all messages in a group
                const userName = messages[0].ReceiverName;
                const { BusinessName } = messages[0];

                return (
                  <MessageCard
                    key={userId} // It's better to use a unique identifier than an index if available
                    image={messages[0]?.UserImage}
                    imagePresent={!!messages[0]?.UserImage}
                    message={messages[0]?.LastMessageText ?? ''}
                    lastMessageBy={
                      messages[0]?.SenderID !== auth.id ? userName : 'You'
                    }
                    time={null} // You might want to format messages[0]?.LastMessageTime
                    userName={userName}
                    categories={categories}
                    userId={messages[0]?.OtherUserID}
                    BusinessName={BusinessName}
                    listingType={messages[0]?.ListingType}
                    listingId={messages[0]?.ListingID}
                    isBlocked={messages[0]?.isBlocked}
                    setAdminChatOpen={setAdminChatOpen}
                    isSeen={
                      messages[0]?.SenderID === auth.id ||
                      (messages[0]?.SenderID !== auth.id &&
                        messages[0]?.SeenByReceiver === 1)
                    }
                  />
                );
              }
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={0}
          md={8}
          lg={8}
          style={isMobile || adminChatOpen ? null : webStyles}
        >
          {!isMobile &&
            (adminChatOpen ? (
              <AdminChatPage />
            ) : (
              <Typography
                color="#5C5C5C"
                variant="subtitle2"
                fontWeight={600}
                sx={{ mb: isMobile ? 0 : 10 }}
              >
                Please select a conversation from the left side.
              </Typography>
            ))}
        </Grid>
      </Grid>
      {isMobile && <ListingsFooter />}
    </Box>
  );
}

export default Messages;
