/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  CardContent,
  CardMedia,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../components/TitleHeader';
import Loader from '../../components/Loader';
import { useGetUserFavoriteListingsQuery } from '../../store/middlewares/listingsApi';
import Location from '../../assets/images/Location icon (seller listings).png';
import LocationBuyer from '../../assets/images/Location icon (buyer listings).png';
import {
  setCurrentListing,
  setCurrentListingId,
  setListingType,
} from '../../store/reducers/listing';
import ListingSubFooter from '../../components/ListingSubFooter';
import NoImageUploaded from '../../assets/images/NoImageUploaded.png';

function FavoriteListings() {
  const { t } = useTranslation();
  const { listingType, color } = useSelector((state) => state.listing);
  const { id } = useSelector((state) => state.auth);
  const [currentListings, setCurrentListings] = useState([]);
  const {
    data: listings,
    error,
    isLoading,
  } = useGetUserFavoriteListingsQuery(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterListings = (filterType) => {
    dispatch(setListingType(filterType));
    const filteredListings = listings?.filter(
      (listing) => listing.ListingType === filterType
    );
    setCurrentListings(filteredListings);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (listings) {
      filterListings(listingType); // Call the function without expecting a return value
    }
  }, [listings, listingType]); // Add listingType as a dependency

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleListingDetails = (listing) => {
    dispatch(setCurrentListing(listing));
    dispatch(setListingType(listing.ListingType));
    dispatch(setCurrentListingId(listing.ListingID));
    navigate(
      listing.ListingType === 'Seller'
        ? `/listings/seller-listing/${listing.ListingID}`
        : `/listings/buyer-listing/${listing.ListingID}`
    );
  };
  if (currentListings?.length === 0) {
    return (
      <Box
        sx={{
          p: 2,
          mt: 6,
          backgroundColor: color,
          minHeight: '100vh',
        }}
      >
        <TitleHeader title="Favorite Listings" color="#4A4A4A" />
        <Typography
          variant="subtitle2"
          fontFamily="Inter"
          fontSize={16}
          sx={{ mt: 2, mb: 1 }}
        >
          {t('MyListing.YouHaveNot')} {listingType} {t('MyListing.listing')}
        </Typography>
        <ListingSubFooter
          listings={listings}
          setCurrentListings={setCurrentListings}
        />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ p: 0 }}>
      <TitleHeader title="Favorite Listings" color="#4A4A4A" />
      <Box
        sx={{
          p: 2,
          mt: 6,
          backgroundColor: '#E0EECE',
          minHeight: '100vh',
        }}
      >
        {currentListings?.map((listing, index) => (
          <Box
            key={listing.ListingID}
            position="relative"
            onClick={() => handleListingDetails(listing)}
            sx={{ cursor: 'pointer' }}
          >
            <Paper
              elevation={2}
              sx={{
                mb: 3,
                mt: index === 0 ? 1 : 0,
                borderRadius: 3,
                pt: 1,
                px: 1.2,

                pb: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  gap: '10px',
                  position: 'relative',
                  zIndex: 10,
                  backgroundColor: 'white',
                }}
              >
                <Paper
                  sx={{
                    position: 'absolute',
                    top: -3,
                    right: -3,
                    backgroundColor: 'white',
                    height: '22px',
                    width: '22px',
                    textAlign: 'center',
                    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.15)',
                  }}
                >
                  <StarIcon
                    sx={{
                      color:
                        listing.ListingType === 'Seller'
                          ? '#368C36'
                          : '#E27614',
                      mt: 0.3,
                      fontSize: 18,
                    }}
                  />
                </Paper>

                <CardMedia
                  component="img"
                  height="70"
                  image={(
                    (listing?.Images?.length > 0 &&
                      listing?.Images?.split(',')[0]) ||
                    NoImageUploaded
                  )?.toString()}
                  alt={listing.Subcategory}
                  sx={{
                    borderRadius: 2,
                    minWidth: '100px',
                    maxWidth: '100px',
                  }}
                />
                <CardContent
                  sx={{
                    p: 0,
                    '&:last-child': {
                      paddingBottom: 0,
                    },
                    flex: 1,
                  }}
                >
                  <Typography
                    gutterBottom
                    fontWeight={700}
                    fontSize={13}
                    lineHeight={1.2}
                    color="#4A4A4A"
                  >
                    {listing.Subcategory}
                  </Typography>
                  <Typography fontWeight={600} fontSize={12} color="#4A4A4A">
                    {listing.Category}
                  </Typography>
                  {/* ... other content ... */}
                </CardContent>
                <CardContent
                  sx={{
                    p: 0,
                    flex: 1,
                    '&:last-child': {
                      paddingBottom: 0,
                    },
                  }}
                >
                  <Typography
                    gutterBottom
                    fontWeight={700}
                    fontSize={12}
                    lineHeight={1.2}
                    color={
                      listing.ListingType === 'Seller' ? '#368C36' : '#E27614'
                    }
                  >
                    Ks.{listing.PriceMax} / {listing.Unit}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    fontSize={11}
                    lineHeight={1.2}
                    color={
                      listing.ListingType === 'Seller' ? '#368C36' : '#E27614'
                    }
                  >
                    {listing.Quantity?.split('.')[0]} {listing.Unit}{' '}
                    {t('ListingDetails.available')}
                  </Typography>
                  {/* ... other content ... */}
                </CardContent>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
              >
                <Typography
                  fontWeight={600}
                  fontSize={11}
                  lineHeight={1.2}
                  color="#4A4A4A"
                  width="50%"
                  sx={{ mt: 0.2 }}
                >
                  {listing.ListingType === 'Seller' ? 'Sold ' : 'Requested '}{' '}
                  by:&nbsp;
                  <b style={{ color: '#4A4A4A' }}>{listing.FullName}</b>
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  justifyContent="flex-start"
                  flexDirection="row"
                  width="50%"
                >
                  <Box
                    component="img"
                    sx={{
                      width: 18,
                    }}
                    src={
                      listing.ListingType === 'Seller'
                        ? Location
                        : LocationBuyer
                    }
                    alt="Location Icon"
                  />{' '}
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle2"
                      fontSize={11}
                      fontWeight={600}
                      color="#4A4A4A"
                      fontFamily="Inter,Regular"
                    >
                      {capitalizeFirstLetter(listing?.Township)},&nbsp;
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      fontWeight={700}
                      color="#4A4A4A"
                      fontSize={12}
                    >
                      {capitalizeFirstLetter(listing?.City)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        ))}
      </Box>
      <ListingSubFooter
        listings={listings}
        setCurrentListings={setCurrentListings}
      />
    </Container>
  );
}

export default FavoriteListings;
