/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar, Box, Paper, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setSelectedUserData } from '../store/reducers/chat';
import ListingsFooter from './ListingsFooter';
import { useGetUserChatListingsQuery } from '../store/middlewares/messages';

export default function MessageCard({
  image,
  imagePresent,
  lastMessageBy,
  message,
  time,
  userName,
  categories = [],
  userId,
  BusinessName,
  listingType,
  listingId,
  isBlocked,
  setAdminChatOpen,
  isSeen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const { data: userChatListings } = useGetUserChatListingsQuery();

  function doesListingExist(listingIdToCheck) {
    return userChatListings?.some(
      (chatListing) => chatListing.ListingID === listingIdToCheck
    );
  }

  const handleCardClick = () => {
    if (userName !== 'Admin') {
      const lowerCaseListing = listingType?.toLowerCase();

      const userOwnsListing = doesListingExist(listingId);
      const path = userOwnsListing
        ? 'my-listings'
        : `${lowerCaseListing}-listing`;

      dispatch(
        setSelectedUserData({
          name: userName,
          id: userId,
          businessName: BusinessName,
          selectedListingLink: `${process.env.REACT_APP_URL}/listings/${path}/${listingId}`,
          selectedListingId: listingId,
        })
      );
      navigate(`/conversations/category-list/${userId}`);
    } else if (isMobile) {
      navigate('/conversations/admin-chat');
    } else {
      setAdminChatOpen(true);
    }
  };

  const getInitials = (name) => {
    if (!name || typeof name !== 'string') return '';

    const words = name.split(' ').filter(Boolean); // Split name and remove empty strings
    if (words.length === 1) {
      // If there's only one word, return the first two characters
      return words[0].substring(0, 2).toUpperCase();
    }
    // If there are two or more words, return the first letter of the first two words
    return words
      .map((word) => word[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();
  };

  return (
    <>
      <Card
        onClick={handleCardClick}
        sx={{
          display: 'flex',
          alignItems: 'start',
          borderRadius: '13px',

          padding: '10px',
          backgroundColor: '#FDFDFD',
          ml: 2,
          mr: 1.5,
          pl: 2,
          pr: 1,
          mb: 2,
          pb: 0,
          opacity: isBlocked ? 0.5 : 1,
          cursor: 'pointer',
          boxShadow: isSeen
            ? '0px 0px 6px rgba(0, 0, 0, 0.1)'
            : '0px 0px 12px rgba(242, 189, 56, 0.4)',
        }}
      >
        {userName === 'Admin' ? (
          <Box
            component="img"
            sx={{
              width: 60, // You can adjust as needed
              borderRadius: '50%',
              height: 60,
            }}
            src={image} // Replace with your image path
            alt="Admin"
          />
        ) : (
          <Avatar
            sx={{
              width: 50,
              height: 50,
              backgroundColor: 'white',
              fontSize: '20px',
              color: '#5C5C5C',
              fontWeight: '600',
              borderColor: '#5C5C5C', // Border color
              borderWidth: '1px', // Border width
              borderStyle: 'solid', // Border style
            }}
          >
            {imagePresent ? (
              <img
                src={image}
                style={{
                  objectFit: 'cover',
                  borderRadius: '50%',
                  width: '100%',
                  height: '100%',
                }}
                alt="User Image"
              />
            ) : (
              getInitials(userName)
            )}
          </Avatar>
        )}

        <CardContent
          sx={{ flex: '1 0', pt: 0, pb: 0, mb: userName === 'Admin' ? 3 : 0 }}
          style={{ paddingBottom: userName === 'Admin' ? '4px' : '16px' }}
        >
          <Typography
            fontSize={15}
            fontWeight={700}
            color="#4A4A4A"
            sx={{ mb: 1, mt: 0.5 }}
          >
            {userName}
          </Typography>
          {userName === 'Admin' ? (
            <Typography
              fontSize={13}
              lineHeight={1.2}
              color="#5C5C5C"
              fontWeight={500}
            >
              <b>{lastMessageBy}:</b> {message}
            </Typography>
          ) : (
            categories?.map((category, index) => (
              <Paper
                key={index}
                elevation={1}
                sx={{
                  border:
                    category.listingType === 'Buyer'
                      ? '1px solid #F2BD389C'
                      : '1px solid #368C369C',
                  py: 0.4,
                  px: 1.2,
                  mr: 1,
                  my: 0.5,
                  borderRadius: 2,
                  display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
                  alignItems: 'center',
                  gap: '10px',
                  backgroundColor:
                    category.listingType === 'Buyer' ? '#FDF3DB' : '#E0EECE',
                }}
              >
                <Typography
                  fontSize={10}
                  fontWeight={600}
                  color={
                    category.listingType === 'Buyer' ? '#E27614' : '#368C36'
                  }
                >
                  {category?.categoryName}
                </Typography>
              </Paper>
            ))
          )}
        </CardContent>
        {!!time && (
          <Box>
            <Typography
              lineHeight={1.1}
              fontSize={9}
              fontWeight={600}
              color="#707070"
              sx={{ mt: 1, display: 'flex' }}
              align="right"
            >
              {time?.split(' ')?.[0]}
            </Typography>
            <Typography
              lineHeight={1.1}
              fontSize={9}
              fontWeight={600}
              color="#707070"
              sx={{ display: 'flex' }}
              align="right"
            >
              {time?.split(' ')?.[1]}
            </Typography>
          </Box>
        )}
      </Card>

      {isMobile && <ListingsFooter />}
    </>
  );
}
