/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
// import Autocomplete from '@mui/material/Autocomplete';
import {
  useLoadScript,
  Autocomplete as GoogleAutocomplete,
} from '@react-google-maps/api';
import { FormControl, OutlinedInput } from '@mui/material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import useStyles from '../pages/CreateAccount/style';

const libraries = ['places'];

function LocationAutocomplete({
  label,
  onPlaceSelected,
  locationType,
  formValues,
  name,
  error,
  setErrors,
  errors,
  placeholder,
}) {
  const { t } = useTranslation();
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  const { listingType } = useSelector((state) => state.listing);
  const classes = useStyles();

  useEffect(() => {}, [formValues]);

  const handleLoad = (autocomp) => {
    setAutocomplete(autocomp);
  };

  const handlePlaceChanged = () => {
    if (!autocomplete) {
      return;
    }

    const selectedPlace = autocomplete?.getPlace();

    if (!selectedPlace || !selectedPlace.address_components) {
      toast.info(`${t('Notifications.UnableToDetermine')}`);
      return;
    }

    const countryComponent = selectedPlace.address_components.find(
      (component) => component.types.includes('country')
    );

    if (!countryComponent) {
      toast.warning(`${t('Notifications.UnableToDetermine')}`);
      return;
    }

    const countryName = countryComponent.long_name;

    // Attempt to extract City and Township from address components
    // const localityComponent = selectedPlace.address_components.find(
    //   (component) => component.types.includes('locality')
    // );
    // const sublocalityComponent = selectedPlace.address_components.find(
    //   (component) =>
    //     component.types.includes('sublocality') ||
    //     component.types.includes('sublocality_level_1')
    // );
    const city = selectedPlace?.address_components[1]?.long_name;
    const township = selectedPlace?.address_components[0]?.long_name;

    // const city = localityComponent ? localityComponent[1]?.long_name : '';
    // const township = sublocalityComponent ? sublocalityComponent.long_name : '';

    // Define allowed countries based on listingType
    const allowedCountriesForBuyer = [
      'India',
      'China',
      'Myanmar (Burma)',
      'Thailand',
    ];
    const allowedCountriesForSeller = ['India', 'Myanmar (Burma)'];

    const latitude = selectedPlace.geometry.location.lat();
    const longitude = selectedPlace.geometry.location.lng();

    if (listingType === 'Buyer') {
      if (allowedCountriesForBuyer.includes(countryName)) {
        onPlaceSelected({
          ...formValues,
          City: city || township, // Use township if city is not found
          Township: township || city, // Use city if township is not found
          Latitude: latitude,
          Longitude: longitude,
        });
      } else {
        toast.warning(`${t('Notifications.SelectedLocationNotAllowed')}`);
        onPlaceSelected({ ...formValues, City: '', Township: '' });
      }
    } else if (listingType === 'Seller') {
      if (allowedCountriesForSeller.includes(countryName)) {
        onPlaceSelected({
          ...formValues,
          City: city || township, // Use township if city is not found
          Township: township || city, // Use city if township is not found
          Latitude: latitude,
          Longitude: longitude,
        });
      } else {
        toast.warning(`${t('Notifications.SelectedLocationNotAllowed')}`);
        onPlaceSelected({ ...formValues, City: '', Township: '' });
      }
    } else {
      // Handle any other listing types or if listingType is undefined
      toast.warning(`${t('Notifications.ListingTypeNotRecognized')}`);
    }

    setErrors({ ...errors, City: '', Township: '' }); // Assuming you want to clear any errors for these fields
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <GoogleAutocomplete
      onLoad={handleLoad}
      onPlaceChanged={handlePlaceChanged}
      types={[`(${locationType})`]}
    >
      <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
        <OutlinedInput
          className={classes.paperCustom}
          fullWidth
          size="small"
          id={label}
          aria-describedby={label}
          inputProps={{
            'aria-label': label,
          }}
          name={name}
          value={formValues && formValues[name] ? formValues[name] : ''}
          onChange={(e) =>
            onPlaceSelected({ ...formValues, [name]: e.target.value })
          }
          placeholder={placeholder}
          error={error}
        />
      </FormControl>
    </GoogleAutocomplete>
  );
}

export default LocationAutocomplete;
