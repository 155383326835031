/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Suspense, useEffect } from 'react';
import { CssBaseline, Box } from '@mui/material';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import './utils/transition.css'; // Import your CSS for transitions
import { useSelector, useDispatch } from 'react-redux';
import Loader from './components/Loader';
import ErrorBoundary from './components/ErrorBoundary';
import logErrorToServer from './utils/logErrorToServer';
import { store } from './store/store';
import { setError } from './store/reducers/error';
import ListingDetails from './pages/ListingDetails/ListingDetails';
import ContactUs from './pages/ContactUs/ContactUs';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import EditListing from './pages/EditListing/EditListing';
import FavoriteListings from './pages/FavoriteListings/FavoriteListings';
import UserProfile from './pages/UserProfile/UserProfile';
import Notifications from './pages/Notifications/Notifications';
import Help from './pages/Help/Help';
import Messages from './pages/Messages/Messages';
import CategoryList from './pages/Messages/CategoryList';
import ChatPage from './pages/Messages/ChatPage';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import AdminChatPage from './pages/Messages/AdminChatPage';
import i18n from './i18n';

import Network from './pages/Network';
import UserListingDetails from './pages/ListingDetails/UserListingDeatils';
import { setLanguage } from './store/reducers/auth';
import NotFound from './pages/NotFound';
import ProtectedLayout from './components/ProtectedLayout';
import ListingRemoved from './components/ListingRemoved';

// Lazy load components
const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const PrivacyAndTerms = React.lazy(
  () => import('./pages/PrivacyAndTerms/PrivacyAndTerms')
);
const CreateAccount = React.lazy(
  () => import('./pages/CreateAccount/CreateAccount')
);
const SetPassword = React.lazy(() => import('./pages/SetPassword/SetPassword'));
const Faq = React.lazy(() => import('./pages/Faq/Faq'));
const AboutUs = React.lazy(() => import('./pages/AboutUs/AboutUs'));
const VerifyEmail = React.lazy(() => import('./pages/VerifyEmail/VerifyEmail'));
const SellerListings = React.lazy(
  () => import('./pages/SellerListings/SellerListings')
);
const ForgotPassword = React.lazy(
  () => import('./pages/ForgotPassword/ForgotPassword')
);
const MyListings = React.lazy(() => import('./pages/MyListings/MyListings'));
const AddSellerListings = React.lazy(
  () => import('./pages/AddSellerListing/AddSellerListings')
);
const AddBuyerListing = React.lazy(
  () => import('./pages/AddBuyerListing/AddBuyerListing')
);
const BuyerListings = React.lazy(
  () => import('./pages/BuyerListings/BuyerListings')
);

function App() {
  // getting current user
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const language = localStorage.getItem('language') || 'MM';

  React.useEffect(() => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
  }, []);

  useEffect(() => {
    const handleUnauthorized = () => {
      navigate('/login');
    };

    window.addEventListener('unauthorized', handleUnauthorized);

    return () => {
      window.removeEventListener('unauthorized', handleUnauthorized);
    };
  }, [navigate]);

  // // GLobal error handling
  // window.onerror = (message, source, lineno, colno, error) => {
  //   store.dispatch(setError(message));

  //   logErrorToServer({
  //     errorMessage: `${message}Source:${source}lineno:${lineno}${colno}`,
  //     errorStack: error ? error.stack : 'Stack not available',
  //     userInfo: `${(auth.name, auth.id)}`,
  //     pageUrl: window.location.href,
  //     browserInfo: navigator.userAgent,
  //     referrer_url: document.referrer || 'No referrer',
  //   });
  // };

  // window.onunhandledrejection = (event) => {
  //   store.dispatch(setError(event.reason.message || 'Unhandled rejection'));

  //   logErrorToServer({
  //     errorMessage: event.reason.message || 'Unhandled rejection',
  //     errorStack: event.reason.stack || 'Stack not available',
  //     userInfo: `${(auth.name, auth.id)}`,
  //     pageUrl: window.location.href,
  //     browserInfo: navigator.userAgent,
  //     referrer_url: document.referrer || 'No referrer',
  //   });
  // };

  const location = useLocation();
  const nodeRef = React.useRef(null);

  return (
    <Box className="App">
      <CssBaseline />
      <main>
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={300} // Adjust the timeout to match your CSS transition
            classNames="fade" // Replace 'fade' with your transition class prefix
            nodeRef={nodeRef}
          >
            <ErrorBoundary>
              <Suspense fallback={<Loader />} ref={nodeRef}>
                <Routes location={location}>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<LandingPage />} />
                  <Route path="/terms-of-use" element={<PrivacyAndTerms />} />
                  <Route path="/faq" element={<Faq />} />
                  <Route path="/about-us" element={<AboutUs />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/privacy-policy" element={<TermsOfUse />} />
                  <Route path="/create-account" element={<CreateAccount />} />
                  <Route path="/set-password" element={<SetPassword />} />
                  <Route path="/verify-email" element={<VerifyEmail />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />

                  <Route element={<ProtectedLayout auth={auth} />}>
                    <Route path="/help" element={<Help />} />
                    <Route
                      path="/change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="/edit-account-details"
                      element={<UserProfile />}
                    />
                    <Route
                      path="listings/seller-listings"
                      element={<SellerListings />}
                    />

                    <Route
                      path="/listings/seller-listing/:ListingID"
                      element={<ListingDetails />}
                    />

                    <Route
                      path="/listings/my-listings/:ListingID"
                      element={<UserListingDetails />}
                    />

                    <Route
                      path="/listings/buyer-listing/:ListingID"
                      element={<ListingDetails />}
                    />

                    <Route
                      path="/listings/buyer-listings"
                      element={<BuyerListings />}
                    />
                    <Route
                      path="/listings/my-listings"
                      element={<MyListings />}
                    />
                    <Route
                      path="/listings/add-seller-listing"
                      element={<AddSellerListings />}
                    />
                    <Route
                      path="/listings/add-buyer-listing"
                      element={<AddBuyerListing />}
                    />
                    <Route
                      path="/listings/edit-listing"
                      element={<EditListing />}
                    />
                    <Route
                      path="/listings/favorited-listings"
                      element={<FavoriteListings />}
                    />
                    <Route
                      path="/listings/removed"
                      element={<ListingRemoved />}
                    />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/conversations" element={<Messages />} />
                    <Route
                      path="/conversations/category-list/:userId"
                      element={<CategoryList />}
                    />
                    <Route
                      path="/conversations/chat/:chatListingId"
                      element={<ChatPage />}
                    />
                    <Route
                      path="/conversations/admin-chat"
                      element={<AdminChatPage />}
                    />
                  </Route>
                  <Route path="/network" element={<Network />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </CSSTransition>
        </TransitionGroup>
      </main>
    </Box>
  );
}

export default App;
