import { createTheme } from '@mui/material/styles';

// Define a custom theme
const theme = createTheme({
  palette: {
    // Define the color palette
    primary: {
      main: '#5BA15B', // Example primary color
    },
    secondary: {
      main: '#94C754', // Example secondary color
    },
    error: {
      main: '#ff1744',
    },
    background: {
      default: '#FAFAFA',
    },
  },
  typography: {
    // Define typography settings
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    h1: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiTypography: {
      // Target the MuiTypography component
      styleOverrides: {
        root: {
          // Target the root element of the component
          '&.MuiTypography-colorPrimary': {
            // Target the Typography component with the colorPrimary class
            '&.MuiTypography-root': {
              // Target the root element of the Typography component with colorPrimary class
              outline: '1px solid #368C367A', // Change the outline color
            },
          },
        },
      },
    },
    // Component-specific overrides
    MuiPaper: {
      // Target the MuiPaper component
      styleOverrides: {
        root: {
          // Target the root element of the component
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)', // Adjusted boxShadow style
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Styling for buttons
          borderRadius: 8,
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)', // Adjusted boxShadow style
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // Styling for outlined inputs
          '& .MuiInputBase-input': {
            fontSize: '16px', // Adjust the font size as needed
            color: '#5C5C5C',
            fontWeight: 600,
            fontFamily: 'Inter,Regular',
            paddingLeft: '10px',
            borderRadius: 10,
          },
          '& .MuiInputBase-inputMultiline': {
            fontSize: '13px', // Adjust the font size for multiline specifically, if needed
            lineHeight: 1.4,
            padding: '0px',
          },
          '& .MuiButtonBase-root-MuiMenuItem-root': {
            fontSize: '16px', // Adjust the font size as needed
            color: '#5C5C5C',
            fontWeight: 600,
            fontFamily: 'Inter,Regular',
            paddingLeft: '10px',
            borderRadius: 10,
          },
        },
      },
    },
  },
});

export default theme;
