import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  useMediaQuery,
} from '@mui/material';
import { setSuccessMessage } from '../store/reducers/auth';

function SuccessMessage() {
  const { successMessage } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setSuccessMessage(''));
    }, 3000);
    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [dispatch, successMessage]);

  // Decide whether to show the dialog based on whether there is a success message
  const open = successMessage !== '';

  return (
    <Dialog
      open={open}
      onClose={() => dispatch(setSuccessMessage(''))} // Dispatch action to clear message when dialog is closed
      aria-labelledby="success-message-dialog-title"
      sx={{
        borderRadius: 2,
        '& .MuiDialog-container': {
          justifyContent: 'flex-start', // Aligns the dialog at the top
          alignItems: 'flex-start',
          marginTop: isMobile ? 8 : 10, // Adjust top margin on mobile view
          '& .MuiPaper-root': {
            mx: 'auto', // Margin
            minWidth: isMobile ? 'auto' : 400, // Minimum width of the dialog
            borderRadius: 2, // Border radius
          },
        },
      }}
    >
      <DialogContent
        sx={{
          backgroundColor: '#94C754', // Success color
          color: 'white', // Text color
          borderRadius: 2, // Border radius
          px: 2, // Horizontal padding
          py: 1,
        }}
      >
        <DialogContentText
          sx={{ color: 'white', fontWeight: '600' }}
          id="success-message-dialog-content"
        >
          {successMessage}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default SuccessMessage;
