/* eslint-disable react/prop-types */
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DefaultListingIcon from '../assets/images/Basic listing icon (gray).png';
import SellerListingIcon from '../assets/images/Seller lisitng icon.png';
import BuyerListingIcon from '../assets/images/Buyer listing icon.png';
import { setListingType } from '../store/reducers/listing';

function ListingSubFooter({ listings, setCurrentListings }) {
  const { listingType, color } = useSelector((state) => state.listing);
  const dispatch = useDispatch();
  const filterListings = (filterType) => {
    dispatch(setListingType(filterType));
    const filteredListings = listings?.filter(
      (listing) => listing.ListingType === filterType
    );
    setCurrentListings(filteredListings);
  };
  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        boxShadow: 'none',
        backgroundColor: 'white',
        p: 0.7,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <Button
          color="inherit"
          onClick={() => filterListings('Seller')}
          sx={{
            backgroundColor: 'white',
            borderColor: 'white',
            color: listingType === 'Seller' ? '#368C36' : '#4A4A4A',
            fontSize: 13,
            wordWrap: 'normal',
            whiteSpace: 'nowrap',
            boxShadow:
              listingType === 'Seller'
                ? '0px 2px 4px rgba(0, 0, 0, 0.2)'
                : 'none',
            borderRadius: 5,
            fontWeight: 600,
            py: 1.2,
            px: 3,
          }}
        >
          <Box
            component="img"
            width={18}
            src={
              listingType === 'Seller' ? SellerListingIcon : DefaultListingIcon
            }
            alt="Listing Icon"
            sx={{ mr: 1 }}
          />
          Seller Listings
        </Button>
        <Button
          onClick={() => filterListings('Buyer')}
          sx={{
            backgroundColor: 'white',
            borderColor: 'white',
            color: listingType === 'Buyer' ? '#E27614' : '#4A4A4A',
            fontSize: 13,

            wordWrap: 'normal',
            whiteSpace: 'nowrap',
            boxShadow:
              listingType === 'Buyer'
                ? '0px 2px 4px rgba(0, 0, 0, 0.2)'
                : 'none',
            borderRadius: 5,
            fontWeight: 600,
            py: 1.2,
            px: 3,
          }}
        >
          {' '}
          <Box
            component="img"
            width={18}
            src={
              listingType === 'Buyer' ? BuyerListingIcon : DefaultListingIcon
            }
            alt="Listing Icon"
            sx={{ mr: 1, color }}
          />
          Buyer Listings
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default ListingSubFooter;
