/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */

import React, { useRef } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  useMediaQuery,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MultipleSelectPlaceholder from './SelectCopy';
import useStyles from '../pages/CreateAccount/style';
import Loader from './Loader';

function Categories({
  formValues,
  errors,
  handleInputChange,
  isLoading,
  isError,
  categories,
}) {
  const classes = useStyles();
  const counter = useRef(0);
  const { t } = useTranslation();
  const { language } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery('(max-width:600px)');

  if (isLoading) {
    return <Loader />;
  }

  // Handle error state
  if (isError) {
    if (counter.current === 0) {
      // Access current value of the ref
      toast.error(`${t('Notifications.ErrorFetchingCategories')}`);
      counter.current += 1; // Increment the current value of the ref
    }
    return <div>Error: </div>;
  }

  return (
    <Grid container sx={{ mb: 2 }}>
      <Grid item xs={12} md={5}>
        <MultipleSelectPlaceholder
          label={`${t('AddListing.Category')}`}
          data={categories?.filter(
            (categoryItem) =>
              categoryItem.ParentCategoryID === null &&
              categoryItem[
                language === 'EN'
                  ? 'CategoryName_EN'
                  : 'CategoryName_MM' ?? language !== 'EN'
                    ? 'CategoryName_EN'
                    : 'CategoryName_MM'
              ]?.length > 0
          )}
          value={formValues?.CategoryID}
          name="CategoryID"
          setValue={handleInputChange}
          error={!!errors.CategoryID}
          nameType={language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'}
        />
        {formValues?.customCategory && (
          <FormControl fullWidth>
            <FormHelperText
              sx={{
                ml: 1,
                color: '#66492E',
                fontWeight: '600',
                fontFamily: 'Inter,Medium',
                fontSize: '14px',
              }}
            >
              {t('AddListing.TypeCustomCategory')}
            </FormHelperText>
            <OutlinedInput
              className={classes.paperCustom}
              size="small"
              name="CustomCategoryValue"
              value={formValues?.CustomCategoryValue}
              onChange={handleInputChange}
              error={!!errors?.CustomCategoryValue}
            />
          </FormControl>
        )}
      </Grid>
      {!isMobile && <Grid item xs={12} md={2} />}
      <Grid item xs={12} md={5}>
        <MultipleSelectPlaceholder
          label={`${t('AddListing.Subcategory')}`}
          data={categories?.filter(
            (categoryItem) =>
              categoryItem.ParentCategoryID === formValues?.CategoryID &&
              categoryItem[
                language === 'EN'
                  ? 'CategoryName_EN'
                  : 'CategoryName_MM' ?? language !== 'EN'
                    ? 'CategoryName_EN'
                    : 'CategoryName_MM'
              ]?.length > 0
          )}
          value={formValues?.SubCategoryId}
          setValue={handleInputChange}
          name="SubCategoryId"
          error={!!errors.SubCategoryId}
          nameType={language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'}
        />
        {formValues?.customSubCategory && (
          <FormControl fullWidth>
            <FormHelperText
              sx={{
                ml: 1,
                color: '#66492E',
                fontWeight: '600',
                fontFamily: 'Inter,Medium',
                fontSize: '14px',
              }}
            >
              {t('AddListing.TypeCustomSubcategory')}
            </FormHelperText>
            <OutlinedInput
              className={classes.paperCustom}
              size="small"
              name="CustomSubCategoryValue"
              value={formValues?.CustomSubCategoryValue}
              onChange={handleInputChange}
              error={!!errors.CustomSubCategoryValue}
            />
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}

export default Categories;
