/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterCategoryArrayBuyer: [],
  deliveryStatusBuyer: false,
  siteInspectionStatusBuyer: false,
  searchBuyerListings: '',
  sortTypeBuyer: '',
};

export const buyerListingSlice = createSlice({
  name: 'buyerListing',
  initialState,
  reducers: {
    addItemToFilterArrayBuyer: (state, action) => {
      state.filterCategoryArrayBuyer = [];
      state.filterCategoryArrayBuyer = [...action.payload];
    },
    removeItemFromFilterArrayBuyer: (state, action) => {
      const filterArrayCopy = state?.filterCategoryArrayBuyer.filter(
        (item) => item !== action.payload
      );
      state.filterCategoryArrayBuyer = filterArrayCopy;
    },
    clearFilterArrayBuyer: (state) => {
      state.filterCategoryArrayBuyer = [];
      state.sortTypeBuyer = '';
    },
    setDeliverStatusBuyer: (state, action) => {
      state.deliveryStatusBuyer = action.payload;
    },
    setSiteInspectionStatusBuyer: (state, action) => {
      state.siteInspectionStatusBuyer = action.payload;
    },
    setSearchBuyerListings: (state, action) => {
      state.searchBuyerListings = action.payload;
    },
    setSortTypeBuyer: (state, action) => {
      state.sortTypeBuyer = action.payload;
    },

    setToInitialStateBuyer: (state) => {
      state.filterCategoryArrayBuyer = [];
      state.deliveryStatusBuyer = false;
      state.siteInspectionStatusBuyer = false;
      state.searchBuyerListings = '';
      state.sortTypeBuyer = '';
    },

    // Optionally add other reducers like 'updateProfile', 'register', etc.
  },
});

export const {
  addItemToFilterArrayBuyer,
  removeItemFromFilterArrayBuyer,
  setDeliverStatusBuyer,
  setSiteInspectionStatusBuyer,
  setSearchBuyerListings,
  clearFilterArrayBuyer,
  setSortTypeBuyer,
  setToInitialStateBuyer,
} = buyerListingSlice.actions;
export default buyerListingSlice.reducer;
