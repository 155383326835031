/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import {
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Box,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import LanguageSelect from './LanguageSelect';
import GreenSwitch from './Switch';
import LogoutImage from '../assets/images/Log out icon.png';
import {
  setAuthToIntitialState,
  setLastVisitedPath,
  setSuccessMessage,
} from '../store/reducers/auth';
import { persistor } from '../store/store'; // Import persistStore
import LogoutDialog from './LogoutDialog';
import { setToInitialState } from '../store/reducers/sellerListings';
import { setToInitialStateBuyer } from '../store/reducers/buyerListings';
import {
  useGetUserProfileDetailsQuery,
  useUpdateEmailOptInMutation,
} from '../store/middlewares/userApi';
import { setChatToInitialState } from '../store/reducers/chat';
import {
  setListingToInitialState,
  setListingType,
} from '../store/reducers/listing';
import SucessMessage from './SucessMessage';
import apiServices from '../utils/apiServicesList';
// apis for resetting data

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '400px', // Adjust this to your preferred width
    flexShrink: 0,
    minHeight: '100vh',
    overflow: 'auto',

    '& .MuiDrawer-paper': {
      width: '400px', // Adjust this to your preferred width
      boxSizing: 'border-box',
      paddingLeft: '30px',
    },
  },
  '@media (max-width: 600px)': {
    drawer: {
      width: '280px', // Adjust width for larger screens
      '& .MuiDrawer-paper': {
        width: '280px', // Adjust width for larger screens
        paddingLeft: '0',
        boxSizing: 'border-box',
      },
    },
  },
}));

function Sidebar() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const { id, name } = useSelector((state) => state.auth);
  const { data: userDetails } = useGetUserProfileDetailsQuery({ userId: id });
  const [logoutDialog, setLogoutDialog] = useState(false);
  const [emailOptIn, setEmailOptIn] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    setEmailOptIn(userDetails?.EmailNotificationsOptIn !== 0);
  }, [userDetails]);

  const [updateEmailOptIn] = useUpdateEmailOptInMutation();

  const handleNavigate = (path) => {
    toggleDrawer(false);
    if (path === '/listings/my-listings') {
      dispatch(setListingType('Seller'));
    }
    navigate(path);
  };

  const handleLogout = async () => {
    await persistor.purge();
    // added these two below since the persistor.purge is not working properly
    apiServices.forEach((api) => {
      dispatch(api.util.resetApiState());
    });
    // Batch dispatch calls to optimize performance
    batch(() => {
      dispatch(setToInitialState());
      dispatch(setToInitialStateBuyer());
      dispatch(setAuthToIntitialState());
      dispatch(setChatToInitialState());
      dispatch(setListingToInitialState());
      dispatch(setLastVisitedPath(pathname));
    });

    toggleDrawer(false);
    navigate('/login');
  };

  const handleUpdateEmailOptIn = async (event) => {
    try {
      setEmailOptIn(event.target.checked);
      const result = await updateEmailOptIn(event.target.checked);
      if (result.error) {
        toast.error(`${'Help.ThereWasAnError'}`);
      }

      dispatch(setSuccessMessage(`${t('Sidebar.UpdatedEmail')}`));
    } catch (error) {
      setEmailOptIn(emailOptIn);
      toast.error('Error updating email opt in status:', error);
    }
  };

  const getInitials = () => {
    if (!name || typeof name !== 'string') return '';

    const words = name.split(' ').filter(Boolean); // Split name and remove empty strings
    if (words.length === 1) {
      // If there's only one word, return the first two characters
      return words[0].substring(0, 2).toUpperCase();
    }
    // If there are two or more words, return the first letter of the first two words
    return words
      .map((word) => word[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();
  };

  const sideList = () => (
    <div role="presentation">
      <List sx={{ padding: '10px 10px' }}>
        <Box display="flex">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="15px 0px 15px 15px"
          >
            <Avatar
              sx={{
                width: 50,
                height: 50,
                backgroundColor: 'white',
                fontSize: '20px',
                color: '#5C5C5C',
                fontWeight: '600',
                borderColor: '#5C5C5C', // Border color
                borderWidth: '1px', // Border width
                borderStyle: 'solid', // Border style
              }}
            >
              {userDetails?.UserImage ? (
                <img
                  src={userDetails?.UserImage}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '50%',
                    width: '100%',
                    height: '100%',
                  }}
                  alt="User Image"
                />
              ) : (
                getInitials()
              )}
            </Avatar>
          </Box>

          <Box padding="15px 0px 15px 15px">
            <Typography
              sx={{ fontWeight: '700', color: '#4A4A4A', fontSize: '18px' }}
            >
              {userDetails?.FullName}
            </Typography>
            <Typography
              sx={{
                color: '#0059B9',
                textDecoration: 'none',
                fontWeight: '600',
                fontSize: '14px',
                cursor: 'pointer',
              }}
              onClick={() => handleNavigate('/edit-account-details')}
            >
              {t('Sidebar.ViewProfile')}
            </Typography>
          </Box>
        </Box>
        <SucessMessage />

        <Box sx={{ py: 2, px: 3 }}>
          <LanguageSelect />
        </Box>

        {[
          { name: `${t('Sidebar.MyListings')}`, path: '/listings/my-listings' },
          {
            name: `${t('Sidebar.FavoriteListings')}`,
            path: '/listings/favorited-listings',
          },
          { name: `${t('Sidebar.ChangePassword')}`, path: '/change-password' },
          { name: `${t('Sidebar.ChatWithAdmin')}`, path: '/help' },
          { name: `${t('Sidebar.FAQ')}`, path: '/faq' },
        ].map((text) => (
          <React.Fragment key={text.name}>
            <ListItem
              button
              onClick={() => handleNavigate(text.path)}
              sx={{ py: 0.5, my: 0 }}
            >
              <ListItemText
                primary={text.name}
                primaryTypographyProps={{
                  style: { fontWeight: '600', color: '#0059B9' },
                }}
              />
            </ListItem>
            <Divider
              sx={{ backgroundColor: '#A5A5A5', ml: 1, maxWidth: 270 }}
            />
          </React.Fragment>
        ))}
      </List>
      <Box sx={{ mt: 2, mx: 3 }}>
        <Typography fontWeight={700} fontSize={16} color="#4A4A4A" gutterBottom>
          {t('Sidebar.NotificationSettings')}
        </Typography>
        <Typography color="#4A4A4A" fontSize={14} fontWeight={600}>
          {t('Sidebar.Email')}
          <GreenSwitch checked={emailOptIn} onChange={handleUpdateEmailOptIn} />
        </Typography>
      </Box>
      <Box sx={{ mt: 2, mb: 2, mx: 3 }} onClick={() => toggleDrawer(false)}>
        <Typography
          color="#707070"
          fontWeight={600}
          fontSize={15}
          sx={{ mb: 1, cursor: 'pointer' }}
          onClick={() => handleNavigate('/about-us')}
        >
          {t('Sidebar.AboutUs')}
        </Typography>{' '}
        <Typography
          color="#707070"
          fontWeight={600}
          fontSize={15}
          sx={{ mb: 1, cursor: 'pointer' }}
          onClick={() => handleNavigate('/contact-us')}
        >
          {t('Sidebar.ContactUs')}
        </Typography>
        <Typography
          color="#707070"
          fontWeight={600}
          fontSize={15}
          sx={{ mb: 1, cursor: 'pointer' }}
          onClick={() => handleNavigate('/privacy-policy')}
        >
          {t('Sidebar.PrivacyTerms')}
        </Typography>
        <Typography
          color="#707070"
          fontWeight={600}
          fontSize={15}
          sx={{ mb: 1, cursor: 'pointer' }}
          onClick={() => handleNavigate('/terms-of-use')}
        >
          {t('Sidebar.TermsOfUse')}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        sx={{
          color: '#368C36',
          fontWeight: 'bold',
          fontSize: '15px',
          mx: 3,
          mt: '1vh',
          mb: 4,
        }}
        onClick={() => setLogoutDialog(true)}
      >
        {' '}
        <Box
          component="img"
          sx={{
            width: 18,
            mr: '2.5vw',
          }}
          src={LogoutImage}
          alt="Waste to Wealth Logo"
        />
        {t('Sidebar.LogOut')}
      </Button>
      {logoutDialog && (
        <LogoutDialog
          handleLogout={handleLogout}
          open={logoutDialog}
          handleClose={() => setLogoutDialog(false)}
          yes={t('Yes')}
          no={t('No')}
          content={t('Sidebar.Are you sure you want to log out?')}
        />
      )}
    </div>
  );

  return (
    <div>
      <Toolbar sx={{ pr: 0 }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => toggleDrawer(true)}
          sx={{ pr: 0 }}
        >
          <MenuIcon sx={{ fontSize: '40px', color: '#4A4A4A', pr: 0 }} />
        </IconButton>
      </Toolbar>

      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: isMobile ? '70%' : '340px', // Set the width to 70% of the viewport

            borderTopLeftRadius: '25px',
            borderBottomLeftRadius: '25px',
            display: 'flex', // Use flex layout
            flexDirection: 'column', // Stack children vertically
            justifyContent: 'space-between', // Distribute space between items
          },
        }}
        className={classes.drawer}
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        style={{ paddingLeft: isMobile ? '0px' : '25px' }}
      >
        {sideList()}
      </Drawer>
    </div>
  );
}

export default Sidebar;
