// ErrorBanner.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux'; // If using Redux
import { clearError } from '../store/reducers/error';
// import { useError } from './ErrorContext'; // If using Context

function ErrorBanner() {
  const error = useSelector((state) => state?.error?.message); // If using Redux
  // const { error, setError } = useError(); // If using Context
  const dispatch = useDispatch(); // If using Redux

  if (!error) return null;

  const handleClose = () => {
    dispatch(clearError()); // If using Redux
    // setError(null); // If using Context
  };

  return (
    <div className="error-banner">
      <p>{error}</p>
      <button type="button" onClick={handleClose}>
        Close
      </button>
    </div>
  );
}

export default ErrorBanner;
