const checkSubCategoryExists = (
  parentCategoryId,
  subCategoryName,
  categories,
  language
) => {
  const nameKey = language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM';
  const trimmedSubCategoryNameToCheck = subCategoryName.trim().toLowerCase();

  return categories.some(
    (category) =>
      category.ParentCategoryID === parentCategoryId &&
      category[nameKey]?.trim().toLowerCase() === trimmedSubCategoryNameToCheck
  );
};

export default checkSubCategoryExists;
