// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/utils/transition.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,qCAAqC;AACvC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,qCAAqC;AACvC","sourcesContent":[".fade-enter {\r\n  opacity: 0;\r\n  z-index: 1;\r\n}\r\n\r\n.fade-enter-active {\r\n  opacity: 1;\r\n  transition: opacity 400ms ease-in-out;\r\n}\r\n\r\n.fade-exit {\r\n  opacity: 1;\r\n}\r\n\r\n.fade-exit-active {\r\n  opacity: 0;\r\n  transition: opacity 400ms ease-in-out;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
