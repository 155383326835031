/* eslint-disable react/prop-types */
import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';

function ProtectedLayout({ auth }) {
  return (
    <AuthWrapper auth={auth}>
      <Outlet /> {/* Renders child routes */}
    </AuthWrapper>
  );
}

export default ProtectedLayout;
