/* eslint-disable react/prop-types */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, FormControl, FormHelperText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationAutocomplete from './LocationAutocomplete';
import locationIcon from '../assets/images/Detect my location icon.png';
import { setDetectLocation } from '../store/reducers/auth';
// import { setDetectLocation } from '../store/reducers/auth';

function DetectLocation({ setFormValues, formValues, errors, setErrors }) {
  const { t } = useTranslation();
  const { listingType } = useSelector((state) => state.listing);
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  const fetchLocationDetails = async (lat, lng) => {
    try {
      // Replace 'YOUR_API_KEY' with your actual Google API key
      const geolocationResponse = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      // This response contains various location details
      const addressComponents =
        geolocationResponse.data.results[0].address_components;

      const country = addressComponents.find((component) =>
        component.types.includes('country')
      );
      const locality = addressComponents.find((component) =>
        component.types.includes('locality')
      );
      const sublocality = addressComponents.find((component) =>
        component.types.includes('sublocality')
      );

      if (
        country.long_name !== 'Myanmar (Burma)' &&
        country.long_name !== 'India' &&
        listingType === 'Seller'
      ) {
        toast.error(`${t('Notifications.SellerListingsMustBeFromMyanmar')}`);
        return;
      }

      if (
        listingType === 'Buyer' &&
        !['India', 'China', 'Myanmar (Burma)', 'Thailad'].includes(
          country.long_name
        )
      ) {
        toast.error(`${t('Notifications.BuyerListingMustBe')}`);
        return;
      }

      setFormValues({
        ...formValues,
        City: locality ? locality.long_name : '',
        Township: sublocality ? sublocality.long_name : '',
        Latitude: lat,
        Longitude: lng,
      });
    } catch (error) {
      toast.error(`${t('Notifications.ErrorFetchingLocation')}`);
    }
  };

  const detectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchLocationDetails(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        () => dispatch(setDetectLocation(true))
      );
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth variant="outlined" sx={{ mb: 1 }} size="small">
        <FormHelperText
          id="location"
          sx={{
            ml: 0.4,
            color: '#66492E',
            fontWeight: '600',
            fontFamily: 'Inter,Medium',
            fontSize: '14px',
          }}
        >
          {t('AddListing.Location')}*
        </FormHelperText>
        <LocationAutocomplete
          label="Township"
          onPlaceSelected={setFormValues}
          locationType="regions"
          formValues={formValues}
          name="Township"
          error={!!errors.Township}
          setErrors={setErrors}
          errors={errors}
          placeholder={t('CreateAccount.EnterTownship')}
        />
      </FormControl>

      <LocationAutocomplete
        label="City"
        onPlaceSelected={setFormValues}
        locationType="cities"
        formValues={formValues}
        name="City"
        error={!!errors.City}
        setErrors={setErrors}
        errors={errors}
        placeholder={t('CreateAccount.EnterCity')}
      />

      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        gap="15px"
        sx={{ mb: 4, cursor: 'pointer' }}
        onClick={detectLocation}
      >
        <Box
          component="img"
          sx={{
            width: 25,
            display: 'block', // Makes it a block-level element
          }}
          src={locationIcon}
          alt="Detect My Location Icon"
        />
        <Typography
          sx={{
            color: '#0059B9',
            fontWeight: 'bold',
            fontSize: '14px',
            display: 'inline',
            borderBottom: '1px solid',
            lineHeight: 'normal',
            paddingBottom: '0px', // Adjust the padding value as needed
          }}
        >
          {t('AddListing.Detect')}
        </Typography>
      </Box>
    </Box>
  );
}

export default DetectLocation;
