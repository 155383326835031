/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import { Dialog, Button, DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import ListingCard from './Card';
import { setIsPreview } from '../store/reducers/listing';
import ListingCardPreview from './CardCopyPreview';
// import ListingCard from './Card';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    display: 'flex',
    minHeight: '555h',
    maxHeight: '55vh',
    top: -80, // Align the dialog to the top

    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },

  actions: {
    justifyContent: 'center',
  },
}));

function MyDialog({ listing, images, categories }) {
  const dispatch = useDispatch();
  const { isPreview } = useSelector((state) => state.listing);
  const { name, language } = useSelector((state) => state.auth);
  const classes = useStyles();

  let categoryName = '';
  let subCategoryName = '';
  if (!listing?.customCategory) {
    const category = categories.find(
      (categoryItem) => categoryItem.CategoryID === listing.CategoryID
    );

    categoryName =
      category?.[language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'];
  } else {
    categoryName = listing?.CustomCategoryValue;
  }

  if (!listing?.customSubCategory) {
    const category = categories.find(
      (categoryItem) => categoryItem.CategoryID === listing.SubCategoryId
    );
    subCategoryName =
      category?.[language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'];
  } else {
    subCategoryName = listing?.CustomSubCategoryValue;
  }
  const newListing = {
    ...listing,
    PostedBy: name,
    Images:
      images?.length > 0
        ? typeof images[0] === 'string'
          ? images[0]
          : images[0]?.preview
        : undefined,
    SubcategoryName_EN: subCategoryName,
    SubcategoryName_MM: subCategoryName,
    CategoryName_EN: categoryName,
    CategoryName_MM: categoryName,
  };

  const handleClose = async () => {
    await dispatch(setIsPreview(false));
  };

  return (
    <Dialog
      open={isPreview}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
      sx={{ backgroundColor: 'transparent', p: 0, m: 0, borderRadius: 3 }}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent sx={{ p: 0, backgroundColor: 'white', borderRadius: 3 }}>
        <ListingCardPreview listing={newListing} isPreview={isPreview} />
      </DialogContent>
      <Button
        fullWidth
        sx={{
          mt: 2,
          color: '#0059B9',
          textDecoration: 'underline',
          borderColor: '#0059B9',
          backgroundColor: 'white',
          fontWeight: 600,
          '&:hover': {
            borderColor: '#0059B9',
            backgroundColor: 'white',
            color: '#0059B9',
            textDecoration: 'underline',
            fontWeight: 700,
            borderWidth: '1.25px',
          },
        }}
        variant="outlined"
        onClick={handleClose}
      >
        Exit Preview
      </Button>
    </Dialog>
  );
}

export default MyDialog;
