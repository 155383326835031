const formatNumber = (number) => {
  if (number === null || number === undefined) {
    return '--'; // Display '--' if the number is null or undefined
  }
  // Format number with commas and convert it to a string
  let formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0, // By default, avoid .00
    maximumFractionDigits: 2, // Allow up to two decimal places if necessary
  }).format(number);

  // Remove trailing .00 if present
  if (formattedNumber.endsWith('.00')) {
    formattedNumber = formattedNumber.slice(0, -3);
  }

  return formattedNumber;
};

export default formatNumber;
