function formatDateTime(datetimeString) {
  const date = new Date(datetimeString);
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Set to true for AM/PM format
  }).format(date);
}

export default formatDateTime;
