/* eslint-disable react/prop-types */
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box } from '@mui/material';
// import Thailad from '../assets/images/Thailand flag.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Location from '../assets/images/Location icon (seller listings).png';
import LocationBuyer from '../assets/images/Location icon (buyer listings).png';
import {
  setCurrentListing,
  setCurrentListingId,
} from '../store/reducers/listing';
// import waste from '../assets/images/waste.webp';
import NoImageUploaded from '../assets/images/NoImageUploaded.png';

function ListingCardPreview({ listing, isPreview }) {
  const { listingType } = useSelector((state) => state.listing);
  const { language } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleListingDetails = () => {
    dispatch(setCurrentListing(listing));
    dispatch(setCurrentListingId(listing.ListingID));
    navigate(
      listingType === 'Seller'
        ? `/listings/seller-listing/${listing.ListingID}`
        : `/listings/buyer-listing/${listing.ListingID}`
    );
  };

  return (
    <Card
      onClick={handleListingDetails}
      sx={{
        borderRadius: '16px',
        m: isPreview ? 0 : 2,
        p: 1.5,
        cursor: 'pointer',
        backgroundColor: 'transparent',
      }}
    >
      {listingType === 'Buyer' && (
        <Box sx={{ mb: 2 }}>
          <Typography
            fontFamily="Inter,Regular"
            fontWeight={600}
            fontSize={13}
            color="#4A4A4A"
            gutterBottom
            sx={{ display: 'flex', gap: '10px', pl: 2 }}
          >
            Requested by :{' '}
            <b
              style={{
                color: '#4A4A4A',
                marginTop: '-2px',
                fontSize: '14px',
              }}
            >
              {listing?.PostedBy}{' '}
            </b>
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="8%"
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              paddingTop={1}
              paddingLeft={2}
              width="40%"
            >
              <Typography
                variant="subtitle2"
                fontWeight={700}
                fontSize={14}
                color="#E27614"
                lineHeight={1}
                sx={{ mb: 1.5 }}
              >
                Ks.{listing?.PriceMin} / {listing?.Unit}
              </Typography>
              <Typography
                variant="h6"
                fontWeight={600}
                fontSize={13}
                color="#E27614"
                gutterBottom
                sx={{ pt: -0.5, mt: -0.3 }}
                lineHeight={1}
              >
                {listing?.Quantity} {listing?.Unit} needed
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              width="52%"
            >
              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                justifyContent="flex-start"
              >
                <Box
                  component="img"
                  sx={{
                    width: 20,
                  }}
                  src={LocationBuyer}
                  alt="Location Icon"
                />{' '}
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={12}
                    fontWeight={600}
                    color="#4A4A4A"
                    fontFamily="Inter,Regular"
                    textTransform="camelcase"
                    lineHeight={1.2}
                    gutterBottom
                  >
                    {capitalizeFirstLetter(listing?.Township)}
                  </Typography>
                  <Typography
                    sx={{ mt: -0.4 }}
                    variant="subtitle2"
                    fontWeight={700}
                    lineHeight={1.2}
                    color="#4A4A4A"
                  >
                    {capitalizeFirstLetter(listing?.City)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection={listingType === 'Buyer' ? 'column-reverse' : 'column'}
      >
        <CardMedia
          component="img"
          image={(listing?.Images || NoImageUploaded)?.toString()}
          alt="Listing Image"
          sx={{
            borderTopLeftRadius: listingType === 'Seller' ? '8px' : '0px',
            borderTopRightRadius: listingType === 'Seller' ? '8px' : 'opx',
            borderBottomLeftRadius: listingType === 'Seller' ? '1px' : '8px',
            borderBottomRightRadius: listingType === 'Seller' ? '1px' : '8px',
            maxHeight: '143px',
            minHeight: '140px',
          }}
        />

        <CardContent
          sx={{
            p: 0,
            '&:last-child': {
              paddingBottom: 0,
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap="10px"
            sx={{
              backgroundColor: listingType === 'Seller' ? '#94C754' : '#F2BD38',
              py: 0.7,
              px: 2,
              borderTopLeftRadius: listingType === 'Seller' ? '1px' : '8px',
              borderTopRightRadius: listingType === 'Seller' ? '1px' : '8px',
              borderBottomLeftRadius: listingType === 'Seller' ? '8px' : '1px',
              borderBottomRightRadius: listingType === 'Seller' ? '8px' : '1px',
            }}
          >
            {' '}
            <Typography
              gutterBottom
              fontWeight={600}
              fontSize={15}
              sx={{ color: '#FFFFFF', mb: 0, width: '54%' }}
              lineHeight={1.2}
            >
              {listing?.[
                language === 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
              ] ??
                listing?.[
                  language !== 'EN'
                    ? 'SubcategoryName_EN'
                    : 'SubcategoryName_MM'
                ]}
            </Typography>
            <Typography
              gutterBottom
              fontSize={13}
              fontWeight={500}
              sx={{
                // backgroundColor: '#94C754',
                color: '#FFFFFF',
                mb: 0,
                mt: 0.3,
                width: '42%',
              }}
            >
              | &nbsp;
              {listing?.[
                language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
              ] ??
                listing?.[
                  language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                ]}
            </Typography>
          </Box>
          {listingType === 'Seller' && (
            <Box display="flex">
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingTop={1}
                paddingLeft={2}
                width="50%"
              >
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  fontSize={15}
                  color="#368C36"
                  gutterBottom
                  lineHeight={1.2}
                >
                  Ks.{listing?.PriceMin} / {listing?.Unit}
                </Typography>
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={14}
                  color="#368C36"
                  gutterBottom
                  sx={{ pt: -0.5, mt: -0.3 }}
                  lineHeight={1}
                >
                  {listing?.Quantity} {listing?.Unit} available
                </Typography>
                <Typography
                  fontFamily="Inter,Regular"
                  fontWeight={600}
                  fontSize={13}
                  color="#4A4A4A"
                  lineHeight={1.2}
                  sx={{ mt: 0.5 }}
                >
                  Sold by:{' '}
                  <b style={{ color: '#4A4A4A', fontSize: '15px' }}>
                    {listing?.PostedBy}{' '}
                  </b>
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="end"
                justifyContent="flex-start"
                mt={2}
                ml={2}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  justifyContent="flex-start"
                >
                  <Box
                    component="img"
                    sx={{
                      width: 20,
                    }}
                    src={Location}
                    alt="Location Icon"
                  />{' '}
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontSize={13}
                      fontWeight={600}
                      color="#4A4A4A"
                      lineHeight={1.2}
                      fontFamily="Inter,Regular"
                    >
                      {capitalizeFirstLetter(listing?.Township)}
                    </Typography>
                    <Typography
                      sx={{ mt: -0.4 }}
                      variant="subtitle2"
                      fontWeight={700}
                      color="#4A4A4A"
                      lineHeight={1.2}
                    >
                      {capitalizeFirstLetter(listing?.City)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}

export default ListingCardPreview;
