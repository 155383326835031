function formatCreatedAt(dateTime) {
  const date = new Date(dateTime);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const formattedDateTime = `${hours}:${minutes} ${day}/${month}/${year}`;
  return formattedDateTime;
}

export default formatCreatedAt;
