// categoriesApi.js
import { baseApi } from '../baseApi';

export const messagesApi = baseApi.injectEndpoints({
  reducerPath: 'messagesApi',
  endpoints: (builder) => ({
    getMessagesWithAdmin: builder.query({
      query: () => '/messages/admin',
      providesTags: ['AdminMessages'], // Endpoint for GET /api/messages
    }),
    getMessages: builder.query({
      query: () => '/messages',
      providesTags: ['MessagesGroupedByListing'], // Endpoint for GET /api/messages
    }),
    getMessagesBetweenTwoUsers: builder.query({
      query: (otherUserId) => `/messages/${otherUserId}`,
      providesTags: ['MessagesBetweenTwoUsers'], // Endpoint for GET /api/messages
    }),
    getMessagesForListing: builder.query({
      query: (listingId) => `/messages/listing/${listingId}`,
      providesTags: ['MessagesForListing'], // Endpoint for GET /api/messages
    }),
    getUserChatListings: builder.query({
      query: () => `/messages/listings`,
      providesTags: ['UserChatListings'], // Endpoint for GET /api/messages
    }),
    getUnreadMessageCount: builder.query({
      query: () => '/messages/unread',
    }),

    sendMessageToAdmin: builder.mutation({
      query: (message) => ({
        url: '/messages/admin',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: ['AdminMessages'],
    }),
    sendMessageToUser: builder.mutation({
      query: (message) => ({
        url: '/messages',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [
        'MessagesBetweenTwoUsers',
        'MessagesForListing',
        'UserChatListings',
      ],
    }),
    updateSeenStatus: builder.mutation({
      query: (listingId) => ({
        url: `/messages/update-seen-status/${listingId}`,
        method: 'PATCH',
      }),
    }),
    deleteMessageThread: builder.mutation({
      query: (listingIds) => ({
        url: `/messages`,
        method: 'DELETE',
        body: { listingIds },
      }),
      invalidatesTags: ['MessagesGroupedByListing'],
    }),

    // You can add more endpoints for categories here if needed
  }),
});

export const {
  useSendMessageToAdminMutation,
  useGetMessagesWithAdminQuery,
  useGetMessagesBetweenTwoUsersQuery,
  useGetUnreadMessageCountQuery,
  useGetMessagesForListingQuery,
  useUpdateSeenStatusMutation,
  useGetMessagesQuery,
  useDeleteMessageThreadMutation,
  useGetUserChatListingsQuery,
  useSendMessageToUserMutation,
} = messagesApi;
