/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import {
  FormControl,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  useGetReportReasonsQuery,
  useReportListingMutation,
  useReportUserMutation,
} from '../store/middlewares/report';
import cancelIcon from '../assets/images/Cross (gray background).png';
import { setSuccessMessage } from '../store/reducers/auth';

function ReportDialog({ open, onClose, type, id, ReportType }) {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(''); // State to keep track of the selected reason
  const [optionalReportReason, setOptionalReportReason] = useState('');
  // const { fontColor } = useSelector((state) => state.listing);
  const dispatch = useDispatch();

  const [reportListing, { isLoading: isListingLoading }] =
    useReportListingMutation();
  const [reportUser, { isLoading: isUserLoading }] = useReportUserMutation();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleReportListing = async () => {
    try {
      await reportListing({
        listingID: id,
        reportReason: `${value} ${optionalReportReason}`,
      }).unwrap();
      dispatch(setSuccessMessage(`${t('Report.ListingReported')}`));
      toast.success(`${t('Notifications.ListingReported')}`);
    } catch (error) {
      toast.error(`${t('Notifications.ErrorReportingListing')}`);
    }
  };

  const handleReportUser = async () => {
    try {
      await reportUser({
        reportedUser: id,
        reportReason: `${value} ${optionalReportReason}`,
      }).unwrap();
      dispatch(setSuccessMessage(`${t('Report.UserReported')}`));
      // Handle success
    } catch (error) {
      // Handle error
      toast.error(
        'There was an error reporting the user. Please contact the admin for help for try again later.'
      );
    }
  };

  const handleReport = () => {
    if (value === '') {
      toast.info(`${t('Notifications.PleaseSelectReasonForReporting')}`);
      return;
    }
    if (type === 'Seller' || type === 'Buyer') {
      handleReportListing();
    } else {
      handleReportUser();
    }

    onClose(); // Close the dialog after reporting
  };

  const { data, error, isLoading } = useGetReportReasonsQuery();

  if (isLoading || isListingLoading || isUserLoading)
    return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle
        sx={{ color: '#4A4A4A', fontSize: 15, fontWeight: 600, mt: 3, mb: 0 }}
      >
        {t('Report.PleaseSelect')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src={cancelIcon} width={20} alt="close" />
        </IconButton>
      </DialogTitle>
      <RadioGroup
        aria-label="reporting-reason"
        name="reporting-reason"
        value={value}
        onChange={handleChange}
        sx={{ padding: '0 24px 20px 24px' }} // Add padding to match your UI
      >
        {data
          ?.filter((report) => report.ReportType === type)
          ?.map((filteredReport) => (
            <FormControlLabel
              key={filteredReport.ReasonText}
              value="selling_virgin_resource"
              control={
                <Radio
                  value={filteredReport.ReasonText}
                  sx={{ color: 'gray' }}
                />
              }
              label=<Typography fontSize={13} fontWeight={600} color="#4A4A4A">
                {t(`ReportReasons.${filteredReport.ReasonText}`)}
              </Typography>
            />
          ))}
      </RadioGroup>
      {value !== '' && (
        <FormControl sx={{ mb: 4, mx: 2 }}>
          <FormHelperText
            sx={{
              ml: 1,
              color: '#707070',
              fontWeight: '700',
              fontFamily: 'Inter,Medium',
              fontSize: '14px',
              mb: 2,
            }}
          >
            {t('Report.TellUsMore')}
          </FormHelperText>
          <OutlinedInput
            size="small"
            multiline
            rows={4}
            name="Tell us more"
            value={optionalReportReason}
            onChange={(e) => setOptionalReportReason(e.target.value)}
          />
        </FormControl>
      )}
      <Button
        variant="contained"
        onClick={handleReport}
        sx={{
          margin: '10px',
          marginLeft: 'auto',
          width: '150px',
          backgroundColor: '#E0EECE',
          color: '#368C36',
          borderColor: '#368C367A',
          fontWeight: 600,
          mb: 2,
          mr: 2,
          mt: -1,
        }} // Add margin to match your UI
      >
        {t(`Report.Report ${ReportType}`)}
      </Button>
    </Dialog>
  );
}

export default ReportDialog;
