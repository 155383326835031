/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { WhatsApp, Facebook, Email } from '@mui/icons-material';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, Input, InputAdornment } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import { useTranslation } from 'react-i18next';
import cancelIcon from '../assets/images/Cross (gray background).png';
import viber from '../assets/images/viber.png';

function ShareDialog({ open, onClose, url }) {
  const { t } = useTranslation();
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        // Success feedback
        toast.info(`${t('Notifications.UrlCopied')}`);
      },
      () => {
        // Error feedback
        toast.error(`${t('Notifications.FailedToCopy')}`);
      }
    );
  };

  const openShareDialog = (shareUrl) => {
    window.open(shareUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <Dialog
      fullWidth
      onClose={onClose}
      open={open}
      aria-labelledby="share-dialog-title"
    >
      <DialogTitle
        id="logout-dialog-title"
        sx={{
          fontSize: '16px',
          fontFamily: 'Inter,Regular',
          fontWeight: 600,
          color: '#707070',
          mt: 1,

          pb: 1,
        }}
      >
        Share this listing
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 4,
            top: 4,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <img src={cancelIcon} width={20} alt="close" />
        </IconButton>
      </DialogTitle>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Input
            value={url}
            readOnly
            startAdornment={
              <InputAdornment sx={{ mr: 0, pr: 0.5 }} position="start">
                <IconButton
                  sx={{ mr: 0, pr: 0 }}
                  onClick={handleCopyToClipboard}
                >
                  <ContentCopyIcon />
                </IconButton>
              </InputAdornment>
            }
            sx={{
              ml: 0,
              pl: 0,
              border: '1px solid #3D3D3D70',
              borderRadius: '5px',
              flex: 1,
              width: '100%',
              color: '#5C5C5C',
              fontWeight: 600,
            }}
          />
        </Box>

        <Box>
          <IconButton
            onClick={() =>
              openShareDialog(`viber://forward?text=${encodeURIComponent(url)}`)
            }
          >
            <img
              src={viber}
              alt="viber"
              width={20}
              style={{ marginTop: '2px' }}
            />{' '}
            {/* Style this color as needed */}
          </IconButton>
          <IconButton
            onClick={() =>
              openShareDialog(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  url
                )}`
              )
            }
          >
            <Facebook sx={{ color: '#316FF6' }} />
          </IconButton>
          <IconButton
            onClick={() =>
              openShareDialog(`https://wa.me/?text=${encodeURIComponent(url)}`)
            }
          >
            <WhatsApp sx={{ color: '#1C9224' }} />
          </IconButton>
          <IconButton
            onClick={() =>
              openShareDialog(
                `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  url
                )}`
              )
            }
          >
            <XIcon sx={{ color: '#000000', fontSize: '18px' }} />
          </IconButton>
          <IconButton
            onClick={() =>
              openShareDialog(
                `mailto:?subject=Check out this link&body=I wanted to share this with you: ${encodeURIComponent(
                  url
                )}`
              )
            }
          >
            <Email />
          </IconButton>
        </Box>
      </div>
    </Dialog>
  );
}

export default ShareDialog;
