/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useStyles from '../PrivacyAndTerms/style';
import {
  useChangePasswordMutation,
  useForgotPasswordMutation,
} from '../../store/middlewares/userApi';
import TitleHeader from '../../components/TitleHeader';
import { setSuccessMessage } from '../../store/reducers/auth';
import SuccessMessage from '../../components/SucessMessage';

function ChangePassword() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPassword, setCurrentPassword] = useState('');
  const [validEmail, setValidEmail] = useState(false);

  const [newPassword, SetNewPassword] = React.useState('');
  const [confirmPassword, SetConfirmPassword] = React.useState('');
  const [isEightCharacters, setIsEightCharacters] = useState(false);
  const [isNumeric, setIsNumeric] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCurrrentPassword, setShowCurrentPassword] = useState(false);

  const [changePassword, { isError, error }] = useChangePasswordMutation();

  const handleCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleSubmit = async () => {
    if (!newPassword) {
      toast.error(`${t('Notifications.PleaseEnterPassword')}`);
      return;
    }

    if (newPassword.length < 8) {
      toast.error(`${t('Notifications.PasswordMustBeEight')}`);
      return;
    }
    if (newPassword.length > 20) {
      toast.error(`${t('Notifications.PasswordMustBeLessThan')}`);
      return;
    }
    if (!newPassword.match(/^(?=.*\d).{8,}$/)) {
      toast.error(`${t('Notifications.PasswordMustContain')}`);
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error(`${t('Notifications.PasswordDoNotmatch')}`);
      return;
    }
    await changePassword({ currentPassword, newPassword, confirmPassword })
      .unwrap()
      .then(() => {
        if (isError && error) {
          toast.error(error.data.message);
          return;
        }
        // navigate('/');
        dispatch(
          setSuccessMessage(`${t('Notifications.PasswordChangedSuccessfully')}`)
        );

        // Handle successful response here
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  const handlePassword = (e) => {
    if (e.target.value.length > 7) {
      setIsEightCharacters(true);
    } else {
      setIsEightCharacters(false);
    }
    if (e.target.value.match(/\d/)) {
      setIsNumeric(true);
    } else {
      setIsNumeric(false);
    }

    SetNewPassword(e.target.value);
  };

  return (
    <Box className={classes.rootContainer} sx={{ my: 8 }}>
      <TitleHeader title="Change Password" color="#4A4A4A" />
      <SuccessMessage />
      <FormControl fullWidth sx={{ mb: 5 }} variant="outlined">
        <FormHelperText
          id="Email"
          sx={{
            ml: 0.4,
            color: '#66492E',
            fontWeight: '600',
            fontFamily: 'Inter,Medium',
            fontSize: '14px',
          }}
        >
          Enter Current Password
        </FormHelperText>
        <OutlinedInput
          className={classes.paperCustom}
          size="small"
          id="adornment-email"
          type={showCurrrentPassword ? 'text' : 'password'} // Toggle between text and password type
          aria-describedby="Email"
          value={currentPassword}
          onChange={handleCurrentPassword}
          endAdornment={
            // Add an endAdornment with the eye icon
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowCurrentPassword(!showCurrrentPassword)}
                edge="end"
              >
                {showCurrrentPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <FormControl sx={{ mb: 2, width: '100%' }} variant="outlined">
        <FormHelperText
          id="password"
          sx={{
            ml: 0.4,
            color: '#66492E',
            fontWeight: '600',
            fontFamily: 'Inter,Medium',
            fontSize: '14px',
          }}
        >
          Enter New Password
        </FormHelperText>

        <OutlinedInput
          className={classes.paperCustom}
          size="small"
          id="password"
          type={showPassword ? 'text' : 'password'} // Toggle between text and password type
          aria-describedby="password"
          inputProps={{
            'aria-label': 'password',
          }}
          name="password"
          value={newPassword}
          onChange={handlePassword}
          endAdornment={
            // Add an endAdornment with the eye icon
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap="10px"
      >
        <CheckIcon
          sx={{
            color: isEightCharacters ? '#368C36' : 'lightgray',
            fontSize: '20px',
            fontWeight: 800,
          }}
        />
        <Typography
          variant="body1"
          color="#5C5C5C"
          fontFamily="Inter,Regular"
          fontWeight="600"
          fontSize="12px"
        >
          Minimum 8 characters required.
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap="10px"
      >
        <CheckIcon
          sx={{
            color: isNumeric ? '#368C36' : 'lightgray',
            fontSize: '20px',
            fontWeight: 800,
          }}
        />
        <Typography
          variant="body1"
          color="#5C5C5C"
          fontFamily="Inter,Regular"
          fontWeight="600"
          fontSize="12px"
        >
          Include at least one numeric character.
        </Typography>
      </Box>
      <FormControl sx={{ mb: 7, mt: 3, width: '100%' }} variant="outlined">
        <FormHelperText
          id="Email"
          sx={{
            ml: 0.4,
            color: '#66492E',
            fontWeight: '600',
            fontFamily: 'Inter,Medium',
            fontSize: '14px',
          }}
        >
          Re-enter New Password
        </FormHelperText>

        <OutlinedInput
          className={classes.paperCustom}
          size="small"
          type={showConfirmPassword ? 'text' : 'password'} // Toggle between text and password type
          id="confirmPassword"
          aria-describedby="confirmPassword"
          inputProps={{
            'aria-label': 'confirmPassword',
          }}
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => SetConfirmPassword(e.target.value)}
          endAdornment={
            // Add an endAdornment with the eye icon
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge="end"
              >
                {showConfirmPassword ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

      <Button
        variant="outlined"
        disabled={!isEightCharacters && !isNumeric}
        sx={{
          fontWeight: 600,
          width: '100%',
          fontSize: '16px',
          fontFamily: 'Inter,Bold',
          borderColor: 'rgba(0, 0, 0, 0.12)', // Border color for the disabled button
          color: 'white',
          py: 1,
          backgroundColor: '#5BA15B',
          '&.Mui-disabled': {
            borderColor: 'lightgrey', // Change border color when disabled
            color: 'white',
            backgroundColor: 'grey', // Change background color when disabled
          },
          '&:active': {
            borderColor: 'rgba(0, 0, 0, 0.12)', // Border color for the disabled button
            color: 'white',
            py: 1,
            backgroundColor: '#5BA15B', // Keep the original background color on click
          },
          '&&:hover': {
            backgroundColor: '#5BA15B',
          },
        }}
        onClick={handleSubmit}
      >
        Confirm
      </Button>
    </Box>
  );
}

export default ChangePassword;
