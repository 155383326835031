/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  user: null,
  role: null,
  name: null,
  id: null,
  isLoggedIn: false,
  status: 'idle', // can be 'idle', 'loading', 'succeeded', 'failed'
  listintType: 'seller',
  language: 'MM',
  businessName: '',
  termsRead: false,
  detectLocation: false,
  successMessage: '',
  lastVisitedPath: '/listings/seller-listings',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token, role, id, name, businessName } = action.payload;
      // eslint-disable-next-line no-param-reassign
      state.id = id;
      state.role = role;
      state.user = user;
      state.token = token;
      state.name = name;
      state.isLoggedIn = true;
      state.businessName = businessName;
    },
    setListType: (state, action) => {
      state.listintType = action.payload;
    },

    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setTermsRead: (state, action) => {
      state.termsRead = action.payload;
    },
    setDetectLocation: (state, action) => {
      state.detectLocation = action.payload;
    },
    setAuthToIntitialState: (state) => {
      state.token = null;
      state.user = null;
      state.role = null;
      state.name = null;
      state.id = null;
      state.isLoggedIn = false;
      state.status = 'idle'; // can be 'idle', 'loading', 'succeeded', 'failed'
      state.listintType = 'seller';

      state.businessName = '';
      state.termsRead = false;
      state.detectLocation = false;
    },

    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },

    setLastVisitedPath: (state, action) => {
      state.lastVisitedPath = action.payload;
    },

    // Optionally add other reducers like 'updateProfile', 'register', etc.
  },
});

export const {
  setCredentials,
  setListType,

  setLanguage,
  setTermsRead,
  setDetectLocation,
  setAuthToIntitialState,
  setSuccessMessage,
  setLastVisitedPath,
} = authSlice.actions;
export default authSlice.reducer;
