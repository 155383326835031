import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Helper function to add timeout functionality to fetch
const fetchWithTimeout = (url, options, timeout = 10000) => {
  const controller = new AbortController();
  const { signal } = controller;

  return new Promise((resolve, reject) => {
    // Set timeout timer
    const timer = setTimeout(() => {
      controller.abort();
      reject(new Error('Request timed out'));
    }, timeout);

    fetch(url, { ...options, signal })
      .then(resolve, reject)
      .finally(() => clearTimeout(timer));
  });
};

const customBaseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
  credentials: 'include',
  // Modify the fetchFn to use the fetchWithTimeout function
  fetchFn: (url, options, extraOptions) =>
    fetchWithTimeout(url, options, extraOptions?.timeout),
});

const baseQueryWithRejectionHandling = async (args, api, extraOptions) => {
  try {
    const result = await customBaseQuery(args, api, {
      ...extraOptions,
      timeout: 10000,
    }); // Specify the timeout duration here

    if (result.error && result.error.status === 401) {
      // Dispatch a custom event or handle unauthorized access globally
      const event = new CustomEvent('unauthorized');
      window.dispatchEvent(event);
    }
    return result;
  } catch (error) {
    console.log(error);
    // Specific handling for timeout or other fetch errors
    if (error.name === 'AbortError') {
      console.error('Fetch aborted due to timeout');
    }
    throw error; // Rethrow the error
  }
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithRejectionHandling,
  endpoints: () => ({}),
});

export default baseApi;
