/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import translationEn from './locales/EN/translation.json';
import translationMm from './locales/MM/translation.json';

const availableLanguages = ['EN', 'MM'];
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const option = {
  order: [
    'localStorage',
    'cookie',
    'navigator',
    'htmlTag',
    'path',
    'subdomain',
  ],
  caches: ['localStorage'], // Specify where to store the detected language

  checkWhitelist: true,
};

const resources = {
  EN: {
    translation: translationEn,
  },
  MM: {
    translation: translationMm,
  },
};

const storedLanguage = localStorage.getItem('language');

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng: 'MM',
    resources,
    lng: storedLanguage || 'MM', // Use stored language if available

    debug: false,
    whitelist: availableLanguages,
    detection: option,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: './locales/{{lng}}/translation.json',
    },
  });

export default i18n;
