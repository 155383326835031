/* eslint-disable react/prop-types */
import { AppBar, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function TitleHeader({ title, color, listingId }) {
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      sx={{
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        height: 50,
      }}
    >
      <Toolbar>
        <Typography
          fontWeight={700}
          color={color}
          fontSize={18}
          sx={{
            display: 'flex',
            alignItems: 'center',

            gap: 1,
          }}
        >
          <ArrowBackIosIcon
            onClick={() => navigate(-1)}
            sx={{
              fontSize: 20,
              color: '#4A4A4A',
              fontWeight: 700,
              cursor: 'pointer',
            }}
          />
          {title}
        </Typography>
        {listingId && (
          <Typography
            align="right"
            fontWeight={600}
            color="#5C5C5C"
            fontSize={13}
            sx={{ marginLeft: 'auto', mt: 0.2 }}
          >
            Listing id : {listingId}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default TitleHeader;
