/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import axios from 'axios';

function Network() {
  const [ipAddress, setIpAddress] = useState('');

  const checkIpMalicious = async () => {
    const apiKey =
      'd8de94dcaa9e8be45f54d2f1d56fc1c6c0d9ad574457c902fd64825365c9dc2e32c298b83d864d88';
    const url = `https://api.abuseipdb.com/api/v2/check?ipAddress=${ipAddress}`;
    try {
      await axios.get(url, {
        headers: { Key: apiKey },
      });
      // Handle response data

      // You might set some state here to display the results
    } catch (error) {
      console.error('Error occurred while checking IP status:', error);
      // Handle error
    }
  };

  const handleInputChange = (e) => {
    setIpAddress(e.target.value);
  };

  return (
    <div>
      <input
        type="text"
        value={ipAddress}
        onChange={handleInputChange}
        placeholder="Enter IP Address"
      />
      <button onClick={checkIpMalicious}>Check IP</button>
      {/* Display results here */}
    </div>
  );
}

export default Network;
