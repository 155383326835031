import { baseApi } from '../baseApi';

export const errorLogApi = baseApi.injectEndpoints({
  reducerPath: 'errorLogApi',
  endpoints: (builder) => ({
    postErrorLog: builder.mutation({
      query: (errorData) => ({
        url: '/error/error-log',
        method: 'POST',
        body: errorData,
      }),
    }),
  }),
});

export const { usePostErrorLogMutation } = errorLogApi;
