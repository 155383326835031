/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listingType: 'Seller',
  color: '#E0EECE',
  fontColor: '#368C36',
  isPreview: false,
  currentListing: {},
  currentPageFooter: 'Seller',
  currentListingId: null,
  currentSavedListingId: null,
  marginLeft: -2,
};

export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setListingType: (state, action) => {
      state.listingType = action.payload;
      if (action.payload === 'Seller') {
        state.color = '#E0EECE';
        state.fontColor = '#368C36';
      } else {
        state.color = '#FDF3DB';
        state.fontColor = '#E27614';
      }
    },
    setIsPreview: (state, action) => {
      state.isPreview = action.payload;
    },
    setCurrentListing: (state, action) => {
      state.currentListing = action.payload;
    },
    setCurrentPageFooter: (state, action) => {
      state.currentPageFooter = action.payload;
    },
    setCurrentListingId: (state, action) => {
      state.currentListingId = action.payload;
    },
    setListingToInitialState: (state) => {
      state.listingType = 'Seller';
      state.color = '#E0EECE';
      state.fontColor = '#368C36';
      state.isPreview = false;
      state.currentListing = {};
      state.currentPageFooter = 'Seller';
      state.currentListingId = null;
    },
    setCurrentSavedListingId: (state, action) => {
      state.currentSavedListingId = action.payload;
    },
    setMarginLeft: (state, action) => {
      state.marginLeft = action.payload;
    },

    // Optionally add other reducers like 'updateProfile', 'register', etc.
  },
});

export const {
  setListingType,
  setIsPreview,
  setCurrentListing,
  setCurrentPageFooter,
  setCurrentListingId,
  setListingToInitialState,
  setCurrentSavedListingId,
  setMarginLeft,
} = listingSlice.actions;
export default listingSlice.reducer;
