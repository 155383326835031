// userApi.js
import { baseApi } from '../baseApi';

export const userApi = baseApi.injectEndpoints({
  reducerPath: 'userApi',
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (userDetails) => ({
        url: '/user/register',
        method: 'POST',
        body: userDetails,
      }),
    }),
    getUserProfileDetails: builder.query({
      query: ({ userId }) => `user/${userId}`,
      providesTags: ['UserProfile'],
    }),
    getCategoryIdsOfBuyerListings: builder.query({
      query: () => `user/get-categoryids-of-buyer-listings`,
      providesTags: ['GetCategoryIds'],
    }),
    setPassword: builder.mutation({
      query: (passwordDetails) => ({
        url: '/user/set-password',
        method: 'POST',
        body: passwordDetails,
      }),
    }),
    loginUser: builder.mutation({
      query: (loginCredentials) => ({
        url: '/user/login',
        method: 'POST',
        body: loginCredentials,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: '/user/forgot-password',
        method: 'POST',
        body: email,
      }),
    }),
    changePassword: builder.mutation({
      query: (passwordDetails) => ({
        url: '/user/change-password',
        method: 'PATCH',
        body: passwordDetails,
      }),
    }),
    updateUserProfile: builder.mutation({
      query: (profileDetails) => ({
        url: `user/${profileDetails.userId}`,
        method: 'PATCH',
        body: profileDetails,
      }),
      invalidatesTags: ['UserProfile'], // Invalidate the cache of listings after a listing is deleted
    }),
    blockUser: builder.mutation({
      query: (blockedUserId) => ({
        url: `user/block-user`,
        method: 'POST',
        body: { blockedUserId },
      }),
      invalidatesTags: ['UserProfile', 'MessagesBetweenTwoUsers'], // Invalidate the cache of listings after a listing is deleted
    }),
    unblockUser: builder.mutation({
      query: (blockedUserId) => ({
        url: `user/unblock-user`,
        method: 'DELETE',
        body: { blockedUserId },
      }),
      invalidatesTags: ['UserProfile', 'MessagesBetweenTwoUsers'], // Invalidate the cache of listings after a listing is deleted
    }),
    updateEmailOptIn: builder.mutation({
      query: (emailOptIn) => ({
        url: `user/update-email-opt-in`,
        method: 'POST',
        body: { emailOptIn },
      }),
    }),
    updateUserImage: builder.mutation({
      query: (formData) => ({
        url: `user/update-user-image`,
        method: 'PATCH',
        body: formData, // Assuming you're sending the image as form-data
      }),
      invalidatesTags: ['UserProfile'], // Invalidate the cache of UserProfile if needed
    }),

    // ... other endpoints ...
  }),
});

export const {
  useRegisterUserMutation,
  useSetPasswordMutation,
  useGetUserProfileDetailsQuery,
  useGetCategoryIdsOfBuyerListingsQuery,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useUpdateUserProfileMutation,
  useBlockUserMutation,
  useUnblockUserMutation,
  useUpdateEmailOptInMutation,
  useUpdateUserImageMutation,
  // ... other hooks ...
} = userApi;
