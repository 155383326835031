/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  IconButton,
  TextField,
  Box,
  Typography,
  Paper,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Button,
  Container,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Flag from 'react-world-flags';
import { toast } from 'react-toastify';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../components/TitleHeader';
import editIcon from '../../assets/images/Edit information.png';
import DetectLocation from '../../components/DetectLocation';
import locationData from '../CreateAccount/locationData';
import {
  useGetUserProfileDetailsQuery,
  useUpdateUserImageMutation,
  useUpdateUserProfileMutation,
} from '../../store/middlewares/userApi';
import { setSuccessMessage } from '../../store/reducers/auth';
import SuccessMessage from '../../components/SucessMessage';

const useStyles = makeStyles(() => ({
  input: {
    fontWeight: '700', // Again, the numeric value for font weight
  },
  // other styles...
}));

function UserProfile() {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { name, id } = useSelector((state) => state.auth);

  const [editMode, setEditMode] = useState(false);

  const { data: userDetails } = useGetUserProfileDetailsQuery({ userId: id });
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const [updateUserImage] = useUpdateUserImageMutation();

  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues({
      userId: id,
      FullName: userDetails?.FullName,
      BusinessName: userDetails?.BusinessName,
      Township: userDetails?.Township,
      City: userDetails?.City,
      Country: userDetails?.Country,
    });
  }, [userDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e) => {
    // eslint-disable-next-line no-shadow
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Optional: Handle validation
    if (value.trim() === '') {
      setErrors({
        ...errors,
        [name]: 'This field is required',
      });
    } else {
      // Clear errors
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  const getInitials = () => {
    if (!name || typeof name !== 'string') return '';

    const words = name.split(' ').filter(Boolean); // Split name and remove empty strings
    if (words.length === 1) {
      // If there's only one word, return the first two characters
      return words[0].substring(0, 2).toUpperCase();
    }
    // If there are two or more words, return the first letter of the first two words
    return words
      .map((word) => word[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();
  };

  const countryCodes = [
    { name: 'Thailand', code: '+66', flagCode: 'TH' },
    { name: 'China', code: '+86', flagCode: 'CN' },
    { name: 'Myanmar', code: '+95', flagCode: 'MM' },
    { name: 'India', code: '+91', flagCode: 'IN' },
  ];

  const removeEditMode = () => {
    setEditMode(false);
  };

  const handleSubmit = async () => {
    if (editMode) {
      if (!formValues.BusinessName) {
        // Validate BusinessName
        toast.error(`${t('Notifications.NameOfBusinessRequired')}`);
      }

      // Validate City
      if (!formValues.City) {
        toast.error(`${t('Notifications.CityRequired')}`);
      }

      // Validate Country
      if (!formValues.Country) {
        toast.error(`${t('Notifications.CountryRequired')}`);
      }

      // Validate FullName
      if (!formValues.FullName) {
        toast.error(`${t('Notifications.FullNameRequired')}`);
      }

      // Validate Township
      if (!formValues.Township) {
        toast.error(`${t('Notifications.TownShipRequired')}`);
      }

      try {
        await updateUserProfile(formValues);
        dispatch(setSuccessMessage(`${t('Notifications.ProfileUpdated')}`));
      } catch (error) {
        toast.error(`${t('Notifications.FailedToUpdateProfile')}`);
      }
    } else {
      setEditMode(true);
    }
  };

  const handleSubmitImage = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
      toast.error(`${t('Notifications.NoFileSelected')}`);
      return;
    }

    const formData = new FormData();
    formData.append('image', file);

    try {
      await updateUserImage(formData).unwrap();
      // Handle success, e.g., show a success message or refresh user profile data
      toast.success(`${t('Notifications.ImageUpdatedSuccessfully')}`);
    } catch (error) {
      console.log(error);
      toast.error(`${t('Notifications.FailedToUpdateImage')}`);
      // Handle error, e.g., show an error message
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, px: isMobile ? 0 : 2, pb: 8 }}>
        <TitleHeader title={t('Sidebar.ViewProfile')} color="#4A4A4A" />
        <SuccessMessage />
        {editMode && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              variant="h6"
              fontSize="16px"
              fontWeight="700"
              color="#6964E6"
            >
              {t('Sidebar.EditProfileMode')}
            </Typography>
            <Paper
              elevation={1}
              sx={{
                border: '1px solid #6964E6',
                py: 0.4,
                px: 1,
                borderRadius: 2,
              }}
              onClick={removeEditMode}
            >
              <Typography fontSize={12} fontWeight={600} color="#6964E6">
                {t('Sidebar.ExitMode')}
              </Typography>
            </Paper>
          </Box>
        )}

        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{
              backgroundColor: 'transparent', // Set to transparent to allow the gradient to show

              width: 66,
              height: 66,
              mb: 2,
              color: '#5C5C5C',
              fontWeight: 600,
              display: 'flex',
              flexDirection: 'column',
              background: 'linear-gradient(0deg, #929292 50%, white 50%)', // Replace #colorBottom and #colorTop with actual color values
              border: '1px solid #929292',
              position: 'relative',
            }}
          >
            {userDetails?.UserImage ? (
              <img
                src={userDetails?.UserImage}
                style={{
                  objectFit: 'cover',
                  borderRadius: '50%',
                  width: '100%',
                  height: '100%',
                }}
                alt="User Image"
              />
            ) : (
              getInitials()
            )}

            <Typography
              fontSize={userDetails?.UserImage ? 6 : 8}
              color="white"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '3px',
                cursor: 'pointer',
                position: 'absolute',
                bottom: 10,
              }}
              onClick={() => document.getElementById('file-input').click()} // Trigger file input on avatar click
            >
              <input
                type="file"
                id="file-input"
                style={{ display: 'none' }} // Hide the file input
                onChange={handleSubmitImage}
                accept="image/*" // Accept only image files
              />
              {userDetails?.UserImage ? (
                editMode ? (
                  <CameraAltIcon sx={{ color: 'white', fontSize: 6 }} />
                ) : null
              ) : (
                <CameraAltIcon sx={{ color: 'white', fontSize: 6 }} />
              )}
              {userDetails?.UserImage
                ? editMode
                  ? t('Sidebar.ChangeImage')
                  : ''
                : t('Sidebar.AddImage')}
            </Typography>
          </Avatar>
          <Box>
            {editMode ? (
              <>
                <TextField
                  value={formValues.FullName}
                  onChange={handleInputChange}
                  autoFocus
                  variant="standard"
                  name="FullName"
                  sx={{
                    color: '#4A4A4A',

                    '& .MuiInput-underline:before': {
                      // underline color when textfield is inactive
                      borderBottom: '1px solid #000', // default bottom border color
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      // underline color when hovered
                      borderBottom: '2px solid #4A4A4A', // hover bottom border color
                    },
                    '& .MuiInput-underline:after': {
                      // underline color when textfield is active (focused)
                      borderBottom: '1px solid #4A4A4A', // focused bottom border color
                    },
                    '& input': {
                      caretColor: '#4A4A4A', // cursor color

                      '&::selection': {
                        backgroundColor: '#4A4A4A', // highlight color upon selection
                      },
                      // Increase cursor thickness by using text shadow
                      textShadow: '0px 0px 0px #000',
                      WebkitTextFillColor: 'transparent',
                      WebkitTextStrokeWidth: '0.5px',
                      WebkitTextStrokeColor: '#000',
                    },
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
                <IconButton size="small">
                  <img src={editIcon} alt="edit" width={20} height={20} />
                </IconButton>
              </>
            ) : (
              <Box display="flex" alignItems="center" gap={2} marginBottom={1}>
                <span
                  style={{ fontWeight: 600, color: '#4A4A4A', fontSize: 18 }}
                >
                  {formValues.FullName}
                </span>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center" marginBottom={3}>
            {editMode ? (
              <>
                <TextField
                  value={formValues.BusinessName}
                  onChange={handleInputChange}
                  variant="standard"
                  name="BusinessName"
                  sx={{
                    color: '#4A4A4A',

                    '& .MuiInput-underline:before': {
                      // underline color when textfield is inactive
                      borderBottom: '1px solid #000', // default bottom border color
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      // underline color when hovered
                      borderBottom: '2px solid #4A4A4A', // hover bottom border color
                    },
                    '& .MuiInput-underline:after': {
                      // underline color when textfield is active (focused)
                      borderBottom: '1px solid #4A4A4A', // focused bottom border color
                    },
                    '& input': {
                      caretColor: '#4A4A4A', // cursor color
                      fontWeight: 500,
                      '&::selection': {
                        backgroundColor: '#4A4A4A', // highlight color upon selection
                      },
                      // Increase cursor thickness by using text shadow
                      textShadow: '0px 0px 0px #000',
                      WebkitTextFillColor: 'transparent',
                      WebkitTextStrokeWidth: '0.5px',
                      WebkitTextStrokeColor: '#000',
                    },
                  }}
                />
                <IconButton size="small">
                  <img src={editIcon} alt="edit" width={20} height={20} />
                </IconButton>
              </>
            ) : (
              <Box display="flex" alignItems="center" gap={2}>
                <span style={{ fontWeight: 500, color: '#4A4A4A' }}>
                  {formValues?.BusinessName}
                </span>
              </Box>
            )}
          </Box>
        </Box>
        <FormControl
          sx={{ mb: 1, width: '100%', opacity: 0.4 }}
          variant="outlined"
        >
          <FormHelperText
            id="Email"
            sx={{
              ml: 0.4,
              color: '#66492E',
              fontWeight: '600',
              fontFamily: 'Inter,Medium',
              fontSize: '14px',
            }}
          >
            {t('CreateAccount.Email')}
          </FormHelperText>

          <Typography fontWeight={700} sx={{ color: '#4A4A4A' }}>
            {userDetails?.Email}
          </Typography>
        </FormControl>
        <FormControl
          sx={{ mb: 1, width: '100%', opacity: 0.4 }}
          variant="outlined"
        >
          <FormHelperText
            id="Email"
            sx={{
              ml: 0.4,
              color: '#66492E',
              fontWeight: '600',
              fontFamily: 'Inter,Medium',
              fontSize: '14px',
            }}
          >
            {t('CreateAccount.PhoneNumber')}
          </FormHelperText>
          <Box display="flex" alignItems="center" gap="10px">
            <span style={{ fontWeight: 600 }}>
              {userDetails?.PhoneNumber?.substring(0, 3)}
            </span>
            <Typography fontWeight={700} sx={{ color: '#4A4A4A' }}>
              {userDetails?.PhoneNumber?.substring(3)}
            </Typography>
          </Box>
        </FormControl>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ mb: 2, mt: 2 }}
          size="small"
        >
          <FormHelperText
            id="country"
            sx={{
              ml: 0.4,
              color: '#66492E',
              fontWeight: '600',
              fontFamily: 'Inter,Medium',
              fontSize: '14px',
            }}
          >
            {t('CreateAccount.Country')}*
          </FormHelperText>
          <Select
            className={classes.paperCustom}
            labelId="country"
            id="country"
            name="Country"
            value={formValues?.Country ?? ''}
            onChange={handleInputChange}
            error={!!errors.country}
            inputProps={{ 'aria-label': 'Without label', fontWeight: 'bold' }}
            IconComponent={KeyboardArrowDownIcon} // Custom dropdown icon
            input={
              <Input
                startAdornment={
                  <InputAdornment position="start" sx={{ pb: 1 }}>
                    <Flag
                      code={
                        countryCodes.find(
                          (country) => formValues.Country === country.name
                        )?.flagCode
                      }
                      height="23"
                    />
                  </InputAdornment>
                }
              />
            }
            sx={{
              pt: 0.9,
              pl: 0.7,
              fontWeight: 600,
              color: '#4A4A4A',
            }}
          >
            {/* You'll need to replace these with actual country codes and flags */}
            {locationData.map((country) => (
              <MenuItem
                key={country}
                value={country}
                selected={country === formValues.Country}
                sx={{
                  color: '#4A4A4A',
                  fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                  fontWeight: 600,
                  fontSize: '16px', // Adjust the font size as needed
                  padding: '10px 16px', // Adjust padding as needed
                  borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                  '&:last-child': {
                    borderBottom: 'none', // Remove border for the last item
                  },
                }}
              >
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DetectLocation
          setFormValues={setFormValues}
          formValues={formValues}
          errors={errors}
          setErrors={setErrors}
        />

        <FormControl fullWidth>
          <Button
            variant="outlined"
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              mb: 5,
              fontFamily: 'Inter,Bold',
              borderColor: 'rgba(0, 0, 0, 0.12)', // Border color for the disabled button
              color: 'white',
              py: 1,
              backgroundColor: '#5BA15B',
              '&.Mui-disabled': {
                borderColor: 'lightgrey', // Change border color when disabled
                color: 'white',
                backgroundColor: 'grey', // Change background color when disabled
              },

              '&:hover': {
                // Hover state styles, if any
                backgroundColor: '#5BA15B', // Example hover color
              },
              '&&:active': {
                backgroundColor: '#5BA15B', // Example hover color
              },
            }}
            onClick={handleSubmit}
          >
            {editMode ? t('Sidebar.SaveChanges') : t('Sidebar.EditProfile')}
          </Button>
        </FormControl>
      </Box>
    </Container>
  );
}

export default UserProfile;
