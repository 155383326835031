/* eslint-disable no-unused-vars */
import {
  Box,
  FormControl,
  OutlinedInput,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Link,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ChatTitleHeader from '../../components/ChatTitleHeader';
import SendIcon from '../../assets/images/Send message icon.png';
import {
  useGetMessagesForListingQuery,
  useGetMessagesWithAdminQuery,
  useSendMessageToAdminMutation,
  useSendMessageToUserMutation,
  useUpdateSeenStatusMutation,
} from '../../store/middlewares/messages';
import Loader from '../../components/Loader';
import { setChatStatus } from '../../store/reducers/chat';
import ChatComponent from '../../components/ChatComponent';
import AdminChatComponent from '../../components/AdminChatComponent';

function AdminChatPage() {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const isMobile = useMediaQuery('(max-width:600px)');

  const { selectedUserName, selectedListingId, selectedUserId, newChat } =
    useSelector((state) => state.chat);

  const { id } = useSelector((state) => state.auth);

  const [eventSource, setEventSource] = useState(null);

  const {
    data: messages,
    isLoading,
    isError,
    refetch,
    error,
  } = useGetMessagesWithAdminQuery({
    refetchOnMountOrArgChange: true,
  });
  const [updateSeenStatus] = useUpdateSeenStatusMutation();

  useEffect(() => {
    // Establish SSE connection when component mounts
    const newEventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/messages/notify-reciever/${id}`
    );
    setEventSource(newEventSource);

    newEventSource.onmessage = function (event) {
      // Handle incoming messages
      // Assuming the server sends the updated message list or a signal to fetch new messages

      refetch(); // Assuming refetch() fetches the latest messages and updates the state
      window.scrollTo(0, document.body.scrollHeight + 10);
    };

    // Clean up the SSE connection when the component unmounts
    return () => {
      newEventSource.close();
    };
  }, [id]);

  useEffect(
    () =>
      // This function will be called when the component unmounts
      () => {
        // Call the `updateSeenStatus` mutation when the component unmounts
        updateSeenStatus(-1);
      },
    [updateSeenStatus]
  );

  const [sendMessageToAdmin, { error: messageSendError }] =
    useSendMessageToAdminMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  if (error) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  const handleSendMessage = async () => {
    if (text.trim() === '') {
      toast.error(`${t('Notifications.PleaseEnterSomeText')}`);
      return;
    }

    try {
      sendMessageToAdmin({
        messageText: text,
      }).unwrap();

      setText('');

      refetch();
      // eslint-disable-next-line no-shadow
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <Box
      style={{
        '::-webkit-scrollbar': {
          width: '5px', // Width of the scrollbar
          backgroundColor: 'rgba(0,0,0,0.2)', // Color of the scrollbar track
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,0.5)', // Color of the scrollbar thumb
          borderRadius: '4px', // Radius of the scrollbar thumb
          border: '1px solid rgba(0,0,0,0)', // Creates padding around the thumb
        },
        '::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0,0,0,0.7)', // Color of the scrollbar thumb on hover
        },
      }}
      sx={{
        mt: isMobile ? 8 : 0,

        display: 'flex',
        flexDirection: 'column',
        height: isMobile ? '90vh' : '84vh',

        // Firefox scrollbar styles
        scrollbarWidth: '3px', // Scrollbar width for Firefox
        scrollbarColor: 'gray', // Scrollbar thumb and track color for Firefox
      }}
    >
      {isMobile && <ChatTitleHeader title="Admin" color="#4A4A4A" />}

      <AdminChatComponent messages={messages} />

      <FormControl
        sx={{
          mb: isMobile ? 5 : 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '15px',
          marginTop: 'auto',
          px: 2,
        }}
      >
        <OutlinedInput
          sx={{
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adjust the values as needed
            backgroundColor: 'white',
            flex: '1 0',
          }}
          size="small"
          multiline
          rows={2}
          placeholder="Enter message here"
          name="message"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <Box
          sx={{
            border: '1px solid #6964E6',
            px: 1.25,
            pt: 0.5,
            borderRadius: 1,
          }}
          onClick={handleSendMessage}
        >
          <Box
            component="img"
            sx={{
              width: 18,
            }}
            src={SendIcon}
            alt="Send Icon"
          />
        </Box>
      </FormControl>
    </Box>
  );
}

export default AdminChatPage;
