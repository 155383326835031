// listingsApi.js
import { baseApi } from '../baseApi';

export const listingsApi = baseApi.injectEndpoints({
  reducerPath: 'listingsApi',
  endpoints: (builder) => ({
    getSellerListings: builder.query({
      query: () => 'listings/seller',
      providesTags: ['SellerListings'],
    }),
    getBuyerListings: builder.query({
      query: () => 'listings/buyer',
      providesTags: ['BuyerListings'],
    }),
    getUserListings: builder.query({
      query: () => 'listings/user',
      providesTags: ['UserListings'],
    }),
    findListingById: builder.query({
      query: (listingId) => `listings/${listingId}`,
      providesTags: ['IndividualListing'],
    }),
    addListing: builder.mutation({
      query: (newListing) => ({
        url: 'listings',
        method: 'POST',
        body: newListing,
      }),
      invalidatesTags: [
        'SellerListings',
        'BuyerListings',
        'Categories',
        'UserListings',
        'GetCategoryIds',
      ], // Invalidate the cache of listings after a new listing is added
    }),
    updateListing: builder.mutation({
      query: ({ listingId, updatedListing }) => ({
        url: `listings/edit-listing/${listingId}`,
        method: 'PATCH',
        body: updatedListing,
      }),
      invalidatesTags: [
        'SellerListings',
        'BuyerListings',
        'Categories',
        'IndividualListing',
        'UserListings',
        'GetCategoryIds,',
      ], // Invalidate the cache of listings after a new listing is added
    }),
    updateListingStatus: builder.mutation({
      query: (listingData) => ({
        url: `listings/${listingData.listingId}`,
        method: 'PATCH',
        body: { listingStatus: listingData.status },
      }),
      invalidatesTags: ['UserListings', 'IndividualListing'], // Invalidate the cache of listings after a new listing is added
    }),
    deleteListing: builder.mutation({
      query: (listingId) => ({
        url: `listings/${listingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserListings', 'IndividualListing', 'GetCategoryIds,'], // Invalidate the cache of listings after a listing is deleted
    }),
    deleteListingImage: builder.mutation({
      query: ({ listingId, imageUrl }) => ({
        url: `/listing-images/${listingId}/${imageUrl}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserListings'], // Invalidate the cache of listings after a listing is deleted
    }),

    addFavorite: builder.mutation({
      query: ({ listingId }) => ({
        url: 'listings/add-favorite',
        method: 'POST',
        body: { listingId },
      }),
      invalidatesTags: ['IndividualListing', 'FavoriteListings'],
    }),

    removeFavorite: builder.mutation({
      query: ({ listingId }) => ({
        url: 'listings/remove-favorite',
        method: 'DELETE',
        body: { listingId },
      }),
      invalidatesTags: ['IndividualListing', 'FavoriteListings'],
    }),
    getUserFavoriteListings: builder.query({
      query: () => `listings/get-user-favorite-listings`,
      providesTags: ['FavoriteListings'],
    }),

    // more endpoints...
  }),

  overrideExisting: false, // Optional: Set to true to override endpoints in the same API slice
});

export const {
  useGetSellerListingsQuery,
  useGetBuyerListingsQuery,
  useGetUserListingsQuery,
  useFindListingByIdQuery,
  useAddListingMutation, // Exporting the new mutation hook
  useUpdateListingMutation,
  useUpdateListingStatusMutation,
  useDeleteListingMutation,
  useDeleteListingImageMutation,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
  useGetUserFavoriteListingsQuery,
  // other hooks...
} = listingsApi;
