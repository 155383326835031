import { userApi } from '../store/middlewares/userApi';
import { listingsApi } from '../store/middlewares/listingsApi';
import { categoriesApi } from '../store/middlewares/categoriesApi';
import { listingImageApi } from '../store/middlewares/listingImageApi';
import { reportsApi } from '../store/middlewares/report';
import { notificationsApi } from '../store/middlewares/notifications';
import { messagesApi } from '../store/middlewares/messages';

const apiServices = [
  userApi,
  listingsApi,
  categoriesApi,
  listingImageApi,
  reportsApi,
  notificationsApi,
  messagesApi,
];

export default apiServices;
