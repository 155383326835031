/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { Card, CardMedia, IconButton, Box, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NoImageUploaded from '../assets/images/NoImageUploaded.png';

function ImageCarousel({ images }) {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images?.length;
  const touchStart = useRef(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep + maxSteps - 1) % maxSteps
    );
  };

  const onTouchStart = (e) => {
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    if (!touchStart.current) {
      return;
    }

    const currentPosition = e.targetTouches[0].clientX;
    const direction = touchStart.current - currentPosition;

    if (direction > 10) {
      handleNext();
    } else if (direction < -10) {
      handleBack();
    }

    touchStart.current = null; // Reset touchStart to prevent multiple swipes from a single touch
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: 'auto',
      }}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
    >
      {!isMobile && images.length > 1 && activeStep !== 0 && (
        <IconButton
          onClick={handleBack}
          sx={{
            position: 'absolute',
            left: -25,
            top: '40%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>
      )}
      <Card sx={{ position: 'relative', mb: 1, minHeight: '200px' }}>
        <CardMedia
          component="img"
          sx={{ height: 'auto', maxHeight: '200px', borderRadius: 2 }}
          image={images[activeStep] ? images[activeStep] : NoImageUploaded}
          alt={images[activeStep]}
        />
      </Card>
      {!isMobile && images?.length > 1 && activeStep + 1 !== images?.length && (
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: -32,
            top: '40%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          }}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      )}
      {images?.length > 1 && (
        <Box display="flex" justifyContent="center" gap="10px">
          {images?.map((image, index) => (
            <Box
              key={image}
              sx={{
                height: '6px',
                width: '6px',
                borderRadius: '50%',
                backgroundColor: activeStep === index ? '#0059B9' : '#929292',
              }}
            />
          ))}
        </Box>
      )}
      <Box sx={{ mb: 3 }} />
    </Box>
  );
}

export default ImageCarousel;
