/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */

import {
  Box,
  Button,
  FormControl,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ShareIcon from '@mui/icons-material/Share';
import { toast } from 'react-toastify';
import StarIcon from '@mui/icons-material/Star';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import TitleHeader from '../../components/TitleHeader';
import ImageCarousel from '../../components/ImageCarousel';
import LocationSeller from '../../assets/images/Location icon (seller listings).png';
import LocationBuyer from '../../assets/images/Location icon (buyer listings).png';
import delilveryTruck from '../../assets/images/Set delivery status icon.png';
import siteInspectionStatus from '../../assets/images/Set site inspection status icon.png';
import ListingsFooter from '../../components/ListingsFooter';
import {
  useAddFavoriteMutation,
  useFindListingByIdQuery,
  useRemoveFavoriteMutation,
} from '../../store/middlewares/listingsApi';
import LogoutDialog from '../../components/LogoutDialog';
import ReportDialog from '../../components/ReportDialog';
import ShareDialog from '../../components/ShareDialog';
import { useGetUserChatListingsQuery } from '../../store/middlewares/messages';
import { setChatStatus, setSelectedUserData } from '../../store/reducers/chat';
import Loader from '../../components/Loader';
import WebHeader from '../../components/WebHeader';
import { setSuccessMessage } from '../../store/reducers/auth';
import { useGetCategoriesQuery } from '../../store/middlewares/categoriesApi';
import { useGetCategoryIdsOfBuyerListingsQuery } from '../../store/middlewares/userApi';
import ListingRemoved from '../../components/ListingRemoved';
import SucessMessage from '../../components/SucessMessage';
import ChatWithBuyerPopUp from '../../components/ChatWithBuyerPopUp';
import { setListingType } from '../../store/reducers/listing';
import formatNumber from '../../utils/formatNumber';

function ListingDetails() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [chatWithBuyerPopUp, setChatWithBuyerPopUp] = useState(false);
  const { fontColor, listingType, currentListing, currentListingId, color } =
    useSelector((state) => state.listing);
  const dispatch = useDispatch();
  const [currentListingType, setCurrentListingType] =
    useState('Seller Listing');
  const { name, businessName, id, language } = useSelector(
    (state) => state.auth
  );

  const { ListingID } = useParams(); // "chatId" should match the parameter name defined in your route

  const { data: categories } = useGetCategoriesQuery();
  // this is for getting category ids of seller, by mistake the naming is all done as buyer
  const { data: buyerListingsCategoryIdsByUser } =
    useGetCategoryIdsOfBuyerListingsQuery();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [listingFavorited, setListingFavorited] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [addFavorite, { isLoading: isAdding }] = useAddFavoriteMutation();
  // eslint-disable-next-line no-unused-vars
  const [removeFavorite, { isLoading: isRemoving }] =
    useRemoveFavoriteMutation();

  const {
    data: listing,
    isLoading,
    isError,
    error,
    refetch,
  } = useFindListingByIdQuery(ListingID);

  useEffect(() => {
    refetch();
  }, [ListingID]);

  const { data: userChatListings } = useGetUserChatListingsQuery();

  // report
  const [reportConfirm, setReportConfirm] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  // sharing
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  // const [currentUrl, setCurrentUrl] = useState(
  //   'https://waste-to-wealth.com/seller/cotton/fabrics/0543'
  // );

  useEffect(() => {
    if (listing?.ListingType === 'Buyer') {
      setCurrentListingType('Buyer Listing');
      dispatch(setListingType(listing?.ListingType));
    } else {
      setCurrentListingType('Seller Listing');
      dispatch(setListingType(listing?.ListingType));
    }
    window.scrollTo(0, 0);
  }, [ListingID, listing]);

  function formatDate(dateString) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${months[monthIndex]} ${year}`;
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  function getCategoryTypeByCategoryId(categoryId) {
    // Find the category object by categoryId
    const category = categories?.find(
      (category) => category?.CategoryID === categoryId
    );

    // Return the CategoryType if the category is found, else return null
    return category ? category?.CategoryType : null;
  }
  function categoryIdExists(categoryId) {
    return buyerListingsCategoryIdsByUser?.categoryIds.some(
      (item) => item.CategoryID === categoryId
    );
  }

  const handleAddFavorite = async () => {
    try {
      await addFavorite({
        listingId: listing?.ListingID,
      }).unwrap();
      setListingFavorited(true);
      setTimeout(() => {
        setListingFavorited(false);
      }, 3000);
      refetch();
      // dispatch(setSuccessMessage(`${t('MyListing.ListingFavorited')}`));
      // Handle successful favoriting
    } catch (error) {
      // Handle error
      toast.error(`${t('Notifications.ErrorFavoritingListing')}`);
    }
  };

  const handleRemoveFavorite = async () => {
    try {
      await removeFavorite({
        listingId: listing?.ListingID,
      }).unwrap();
      refetch();
      dispatch(setSuccessMessage(`${t('MyListing.ListingUnfavorited')}`));
      // Handle successful unfavoriting
    } catch (error) {
      // Handle error
      toast.error(`${t('Notifications.ErrorFavoritingListing')}`);
    }
  };

  const handleReportConfirmClick = () => {
    setReportConfirm(false);
    setReportDialogOpen(true);
  };

  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  function doesListingExist(listingId) {
    return userChatListings?.some(
      (chatListing) => chatListing.ListingID === listingId
    );
  }

  const handleChatClick = () => {
    if (listing?.UserID === id) {
      toast.info(`${t('Notifications.YouAreTheOwner')}`);
      return;
    }
    if (
      getCategoryTypeByCategoryId(listing?.CategoryID) === 'Master' &&
      !categoryIdExists(listing?.CategoryID) &&
      listing?.ListingType === 'Buyer' &&
      !doesListingExist(listing?.ListingID)
    ) {
      setChatWithBuyerPopUp(true);
      return;
    }
    if (doesListingExist(listing?.ListingID)) {
      dispatch(setChatStatus(false));
    } else {
      dispatch(setChatStatus(true));
    }
    const lowerCaseListing = listing?.ListingType?.toLowerCase();

    dispatch(
      setSelectedUserData({
        name: listing?.FullName,
        id: listing?.UserID,
        businessName: listing?.BusinessName,
        selectedListingLink: `${process.env.REACT_APP_URL}/listings/${lowerCaseListing}-listing/${listing?.ListingID}`,
        selectedListingId: listing?.ListingID,
      })
    );

    navigate(`/conversations/chat/${listing?.ListingID}`);
  };

  if (!ListingID) {
    return (
      <>
        {!isMobile && <WebHeader />}
        <Box sx={{ px: 3, my: isMobile ? 8 : 0 }}>
          {' '}
          {isMobile && (
            <TitleHeader title={`Listing Removed`} color={fontColor} />
          )}
          <ListingRemoved />
        </Box>
      </>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isError && error) {
    return (
      <>
        {!isMobile && <WebHeader />}
        <Box sx={{ px: 3, my: isMobile ? 8 : 0 }}>
          {' '}
          {isMobile && (
            <TitleHeader title={`Listing Removed`} color={fontColor} />
          )}
          <ListingRemoved />
        </Box>
      </>
    );
  }

  return (
    <>
      {!isMobile && <WebHeader />}
      <Box sx={{ px: 3, my: isMobile ? 8 : 0, pt: isMobile ? 0 : 2 }}>
        {isMobile && (
          <TitleHeader
            title={`${t(`ListingDetails.${listing?.ListingType} Listing`)} `}
            color={fontColor}
            listingId={`${listing?.ListingID}`}
          />
        )}
        {!isMobile && (
          <Typography
            variant="subtitle2"
            sx={{
              color: '#5C5C5C',
              fontSize: 12,
              fontWeight: 600,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              ml: '6%',
              fontFamily: 'Inter,Regular',
            }}
          >
            {currentListingType}s <KeyboardArrowRightIcon />{' '}
            {listing?.[
              language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
            ] ??
              listing?.[
                language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
              ]}
            <KeyboardArrowRightIcon />{' '}
            {listing?.[
              language === 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
            ] ??
              listing?.[
                language !== 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
              ]}{' '}
            <KeyboardArrowRightIcon />
            {currentListingType} &nbsp;
            {listing?.ListingID}
          </Typography>
        )}

        <Box
          display={isMobile ? 'block' : 'flex'}
          justifyContent="space-evenly"
          gap="10%"
          sx={{ ml: isMobile ? 0 : '6%', mr: isMobile ? 0 : '10%' }}
        >
          <SucessMessage />
          <Box flex={1}>
            <Typography
              variant="subtitle2"
              sx={{ color: '#5C5C5C', fontSize: 12, fontWeight: 600, mb: 1 }}
            >
              {`${t('ListingDetails.PostedOn')} ${formatDate(
                listing?.CreatedAt
              )}`}
            </Typography>
            <ImageCarousel images={listing?.Images?.split(',') ?? []} />
            {!isMobile && (
              <>
                <FormControl fullWidth sx={{ mt: 4, mb: 3 }}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontWeight: '600',
                      fontSize: 15,
                      fontFamily: 'Inter,SemiBold',
                      display: 'flex',
                      gap: '10px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',

                      backgroundColor:
                        listing?.ListingType === 'Seller'
                          ? '#5BA15B'
                          : '#EB9D28',
                      '&&:hover': {
                        backgroundColor:
                          listing?.ListingType === 'Seller'
                            ? '#5BA15B'
                            : '#EB9D28',
                      },
                      '&&:active': {
                        backgroundColor:
                          listing?.ListingType === 'Seller'
                            ? '#5BA15B !important'
                            : '#EB9D28 !important',
                      },
                    }}
                    onClick={handleChatClick}
                  >
                    {t(`ListingDetails.Chat with ${listing?.ListingType}`)}
                  </Button>
                </FormControl>
                <Box>
                  <Paper
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '6px',
                      mt: 2,
                      cursor: 'pointer',
                      userSelect: 'none',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={
                      listing?.isFavorited === 1
                        ? handleRemoveFavorite
                        : handleAddFavorite
                    }
                  >
                    {listing?.isFavorited === 1 ? (
                      <StarIcon
                        sx={{
                          color: '#EB9D28',
                          fontSize: 18,
                          mb: 0.3,
                        }}
                      />
                    ) : (
                      <StarOutlineIcon
                        sx={{ color: '#707070', fontSize: 18 }}
                      />
                    )}

                    <Typography
                      fontSize={12}
                      fontWeight={600}
                      color={listing?.isFavorited === 1 ? '#EB9D28' : '#707070'}
                    >
                      {listing?.isFavorited === 1
                        ? t(`ListingDetails.UnfavoriteListing`)
                        : t(`ListingDetails.FavoriteListing`)}
                    </Typography>
                  </Paper>
                </Box>
                <Box display="flex" gap="10px" sx={{ mb: 12 }}>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={() => setReportConfirm(true)}
                  >
                    <ReportGmailerrorredIcon
                      sx={{ color: '#707070', fontSize: 18 }}
                    />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t(`ListingDetails.ReportListing`)}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={handleOpenShareDialog}
                  >
                    <ShareIcon sx={{ color: '#707070', fontSize: 18 }} />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t(`ListingDetails.ShareListing`)}
                    </Typography>
                  </Paper>
                </Box>
              </>
            )}
          </Box>
          <Box flex={1.3} paddingTop={isMobile ? 0 : 4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="8%"
            >
              <Box width="46%">
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: fontColor,
                    fontSize: isMobile ? 14 : 17,
                    lineHeight: 1.2,
                  }}
                  gutterBottom
                >
                  {listing?.[
                    language === 'EN'
                      ? 'SubcategoryName_EN'
                      : 'SubcategoryName_MM'
                  ] ??
                    listing?.[
                      language !== 'EN'
                        ? 'SubcategoryName_EN'
                        : 'SubcategoryName_MM'
                    ]}
                </Typography>
                <Typography
                  sx={{
                    color: '#5C5C5C',
                    fontSize: isMobile ? 10 : 13,
                    fontWeight: 600,
                  }}
                >
                  {t('ListingDetails.Subcategory')}
                </Typography>
              </Box>

              <Box width="46%">
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: fontColor,
                    fontSize: isMobile ? 14 : 17,
                  }}
                >
                  {listing?.[
                    language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                  ] ??
                    listing?.[
                      language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                    ]}
                </Typography>
                <Typography
                  sx={{
                    color: '#5C5C5C',
                    fontSize: isMobile ? 10 : 13,
                    fontWeight: 600,
                  }}
                >
                  {t('ListingDetails.Category')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: isMobile ? 3 : 5, mb: isMobile ? 2 : 3 }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  color: fontColor,
                  fontSize: isMobile ? 14 : 17,
                }}
              >
                {`Ks.${formatNumber(listing?.PriceMin)}`}
                {listing?.PriceMax
                  ? ` - Ks.${formatNumber(listing?.PriceMax)}`
                  : ''}
                {` / ${listing?.Unit}`}
              </Typography>
              <Typography
                sx={{
                  color: fontColor,
                  fontSize: isMobile ? 11 : 13,
                  fontWeight: 600,
                }}
              >
                {`${formatNumber(listing?.Quantity)} ${listing?.Unit} ${
                  listing?.ListingType === 'Seller'
                    ? t('ListingDetails.available')
                    : t('ListingDetails.needed')
                }`}
              </Typography>
            </Box>
            <Typography
              variant="body"
              sx={{
                fontSize: isMobile ? 11 : 13,
                color: '#5C5C5C',
                fontWeight: 600,
                lineHeight: 1.2,
              }}
            >
              {listing?.Description}{' '}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="8%"
              mt={isMobile ? 2 : 3}
            >
              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                justifyContent="flex-start"
                width="46%"
              >
                <Box
                  component="img"
                  sx={{
                    width: 20,
                  }}
                  src={
                    listing?.ListingType === 'Seller'
                      ? LocationSeller
                      : LocationBuyer
                  }
                  alt="Location Icon"
                />
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={11}
                    fontWeight={600}
                    color="#4A4A4A"
                    fontFamily="Inter,Regular"
                    lineHeight={1.2}
                    gutterBottom
                  >
                    {capitalizeFirstLetter(listing?.Township)}
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5 }}
                    variant="subtitle2"
                    fontWeight={700}
                    fontSize={13}
                    color="#4A4A4A"
                    lineHeight={1.2}
                  >
                    {capitalizeFirstLetter(listing?.City)}
                  </Typography>
                </Box>
              </Box>
              <Box width="46%">
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: '#4A4A4A',
                    fontSize: isMobile ? 11 : 13,
                  }}
                >
                  {listing?.ListingType === 'Seller'
                    ? t('AddListing.Sold by')
                    : t('AddListing.Requested by')}
                </Typography>
                <Typography
                  sx={{ fontWeight: 700, color: '#4A4A4A', fontSize: 12 }}
                >
                  {listing?.FullName}
                </Typography>
                <Typography
                  sx={{
                    color: '#4A4A4A',
                    fontSize: isMobile ? 11 : 13,
                    fontWeight: 600,
                  }}
                >
                  {listing?.BusinessName}
                </Typography>
              </Box>
            </Box>
            <Box
              display={isMobile ? 'block' : 'flex'}
              gap="30px"
              marginTop={isMobile ? 2 : 4}
            >
              <Box>
                <Box
                  sx={{
                    border:
                      listing?.ListingType === 'Seller'
                        ? `1px solid #368C367A`
                        : `1px solid #F2BD38`,

                    py: 0.6,
                    px: 1,
                    display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
                    alignItems: 'center',
                    gap: '10px',
                    borderRadius: 2,
                    boxShadow: 'none',
                  }}
                >
                  <img src={delilveryTruck} width="20" alt="Delivery Status" />
                  <Typography fontSize={12} fontWeight={600} color={fontColor}>
                    {t(`${listing?.DeliveryStatus}`)}
                  </Typography>
                </Box>
              </Box>
              <Box marginTop={isMobile ? 2 : 0}>
                <Box
                  sx={{
                    border:
                      listing?.ListingType === 'Seller'
                        ? `1px solid #368C367A`
                        : `1px solid #F2BD38`,
                    py: 0.6,
                    px: 1,
                    borderRadius: 2,
                    display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
                    alignItems: 'center',
                    gap: '10px',
                    boxShadow: 'none',
                  }}
                >
                  <img
                    src={siteInspectionStatus}
                    width="18"
                    alt="Site Inspection Status"
                  />
                  <Typography fontSize={12} fontWeight={600} color={fontColor}>
                    {t(`${listing?.SiteInspectionStatus}`)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            {isMobile && (
              <>
                <FormControl fullWidth sx={{ mt: 4, mb: 3 }}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontWeight: '600',
                      fontSize: 15,
                      fontFamily: 'Inter,SemiBold',
                      display: 'flex',
                      gap: '10px',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                      backgroundColor:
                        listing?.ListingType === 'Seller'
                          ? '#5BA15B'
                          : '#EB9D28',
                      '&&:hover': {
                        backgroundColor:
                          listing?.ListingType === 'Seller'
                            ? '#5BA15B'
                            : '#EB9D28',
                      },
                      '&&:active': {
                        backgroundColor:
                          listing?.ListingType === 'Seller'
                            ? '#5BA15B !important'
                            : '#EB9D28 !important',
                      },
                    }}
                    onClick={handleChatClick}
                  >
                    {t(`ListingDetails.Chat with ${listing?.ListingType}`)}
                  </Button>
                </FormControl>
                <Box>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '6px',
                      mt: 2,
                      cursor: 'pointer',
                      userSelect: 'none',
                      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                    onClick={
                      listing?.isFavorited === 1
                        ? handleRemoveFavorite
                        : handleAddFavorite
                    }
                  >
                    {listing?.isFavorited === 1 ? (
                      <StarIcon
                        sx={{
                          color:
                            listing?.ListingType === 'Seller'
                              ? '#368C367A'
                              : '#F2BD38',
                          fontSize: 18,
                          mb: 0.3,
                        }}
                      />
                    ) : (
                      <StarOutlineIcon
                        sx={{ color: '#707070', fontSize: 18 }}
                      />
                    )}
                    {listingFavorited ? (
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        color={fontColor}
                      >
                        {t(`ListingDetails.ListingAddedToFavorites`)}
                      </Typography>
                    ) : (
                      <Typography
                        fontSize={12}
                        fontWeight={600}
                        color="#707070"
                      >
                        {listing?.isFavorited === 1
                          ? t(`ListingDetails.UnfavoriteListing`)
                          : listingFavorited
                            ? t(`ListingDetails.ListingAddedToFavorites`)
                            : t(`ListingDetails.FavoriteListing`)}
                      </Typography>
                    )}
                  </Paper>
                </Box>
                <Box display="flex" gap="10px" sx={{ mb: 12 }}>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={() => setReportConfirm(true)}
                  >
                    <ReportGmailerrorredIcon
                      sx={{ color: '#707070', fontSize: 18 }}
                    />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t(`ListingDetails.ReportListing`)}
                    </Typography>
                  </Paper>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={handleOpenShareDialog}
                  >
                    <ShareIcon sx={{ color: '#707070', fontSize: 18 }} />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t(`ListingDetails.ShareListing`)}
                    </Typography>
                  </Paper>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {reportConfirm && (
          <LogoutDialog
            handleLogout={handleReportConfirmClick}
            open={reportConfirm}
            handleClose={() => setReportConfirm(false)}
            yes={t('Yes')}
            no={t('No')}
            content={`${t('ListingDetails.AreYou')} ${listing?.ListingID}  ( ${
              listing?.[
                language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
              ] ??
              listing?.[
                language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
              ]
            },${
              listing?.[
                language === 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
              ] ??
              listing?.[
                language !== 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
              ]
            })`}
          />
        )}
        {reportDialogOpen && (
          <ReportDialog
            open={reportDialogOpen}
            onClose={() => setReportDialogOpen(false)}
            type={listing?.ListingType}
            id={listing?.ListingID}
            ReportType="Listing"
          />
        )}
        <ChatWithBuyerPopUp
          open={chatWithBuyerPopUp}
          setOpen={setChatWithBuyerPopUp}
        />
        <ShareDialog
          open={isShareDialogOpen}
          onClose={handleCloseShareDialog}
          url={window.location.href}
        />
        {isMobile && <ListingsFooter />}
      </Box>
    </>
  );
}

export default ListingDetails;
