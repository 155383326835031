/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  CardContent,
  Typography,
  List,
  ListItem,
  CardMedia,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import formatDateTime from '../utils/formatDateTimeForChat';
import Loader from './Loader';
import {
  setCurrentListing,
  setCurrentListingId,
} from '../store/reducers/listing';

function AdminChatComponent({ messages = [] }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');

  const { currentListing } = useSelector((state) => state.listing);
  const { id: currentUserId } = useSelector((state) => state.auth);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column-reverse', // Newest messages at the bottom
        height: isMobile ? '100vh' : '90vh', // Adjust the height as needed
        overflowY: 'auto', // Scroll for overflow
        px: 2,
      }}
    >
      <List sx={{ mx: 0, px: 0 }}>
        {messages?.map((message, index) => (
          <React.Fragment key={message.MessageID}>
            <ListItem
              sx={{
                my: 0,
                py: 0,
                mx: 0,
                px: 0,
                flexDirection: 'column',
                alignItems:
                  message.ReceiverID === currentUserId
                    ? 'flex-end'
                    : 'flex-start', // Align based on the sender
              }}
            >
              <Box
                sx={{
                  borderRadius: '10px', // Rounded corners for the message bubble
                  padding: '10px 10px 10px 10px', // Padding inside the message bubble
                  mb:
                    message.ReceiverID === messages[index + 1]?.ReceiverID
                      ? 0.2
                      : 1,

                  overflowWrap: 'break-word', // Prevent horizontal overflow
                  marginLeft: message.ReceiverID === currentUserId ? 0 : 'auto',
                  marginRight:
                    message.ReceiverID === currentUserId ? 'auto' : '0',
                  backgroundColor:
                    message.ReceiverID === currentUserId ? 'white' : '#9D99FF',
                  maxWidth: '80%',
                  minWidth: '70%',
                  border: '2px solid #CECCFF',
                }}
                style={{ paddingBottom: '10px' }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    color:
                      message.ReceiverID === currentUserId
                        ? '#707070'
                        : 'white',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {message.MessageText}
                </Typography>
                <Typography
                  align="right"
                  sx={{
                    color:
                      message.ReceiverID === currentUserId
                        ? '#707070'
                        : 'white',
                    fontSize: '9px',
                    fontWeight: 600,
                  }}
                >
                  {formatDateTime(message.CreatedAt)}
                </Typography>
              </Box>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

export default AdminChatComponent;
