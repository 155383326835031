/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import closeIcon from '../assets/images/Cross (gray background).png';
import { setDetectLocation } from '../store/reducers/auth';

function LocationAccessDialog() {
  const { detectLocation } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      open={detectLocation}
      onClose={() => dispatch(setDetectLocation(false))}
    >
      <Box
        marginLeft="auto"
        component="img"
        sx={{
          width: 40,
          pr: 2,
          pt: 2,
          cursor: 'pointer',
        }}
        src={closeIcon}
        alt="Waste to Wealth Logo"
        onClick={() => dispatch(setDetectLocation(false))}
      />
      <DialogTitle
        sx={{ fontWeight: 700, fontSize: '18px', mb: 0, color: '#707070' }}
      >
        {t('EnablerLocation.EnableLocation')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {t('EnablerLocation.ToUseThis')}
        </Typography>
        <Typography
          variant="h6"
          fontSize={16}
          fontWeight={600}
          sx={{ marginTop: 2 }}
          color="#707070"
        >
          - {t('EnablerLocation.OnaMobile')}
          <ol style={{ fontSize: '16px', fontWeight: 500 }}>
            <li>{t('EnablerLocation.GoToYour')}</li>
            <li>{t('EnablerLocation.ScrollDown')}</li>
            <li>{t('EnablerLocation.OpenPermissions')}</li>
            <li>{t('EnablerLocation.EnableLocation')}</li>
          </ol>
        </Typography>
        <Typography
          variant="h6"
          fontSize={16}
          fontWeight={600}
          sx={{ marginTop: 2 }}
          color="#707070"
        >
          - {t('EnablerLocation.OnADesktop')},
          <ol style={{ fontSize: '16px', fontWeight: 500 }}>
            <li>{t('EnablerLocation.OpenYourWeb')}</li>
            <li>{t('EnablerLocation.GoToYourAPpUrl')}</li>
            <li>{t('EnablerLocation.ClickOnThePadlock')}</li>
            <li>{t('EnablerLocation.AdjustSite')}</li>
          </ol>
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={() => dispatch(setDetectLocation(false))}
            sx={{
              mx: 'auto',
              backgroundColor: '#E0EECE',
              border: '1px solid #368C367A',
              color: '#368C36',
              borderRadius: 2,
              fontWeight: 600,
            }}
          >
            {t('EnablerLocation.Close')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default LocationAccessDialog;
