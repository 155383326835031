// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Inter-Black.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Inter-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Inter-ExtraBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./Inter-ExtraLight.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./Inter-SemiBold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./Inter-Thin.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/fonts/fonts.css */

@font-face {
  font-family: 'Inter';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');

  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff2');

  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2');

  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');

  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2');

  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff2');

  font-weight: 100;
  font-style: normal;
}
/* You can continue to add more styles as needed */
`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;EACE,oBAAoB;EACpB,4DAA+C;;EAE/C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,4DAA8C;;EAE9C,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,4DAAmD;;EAEnD,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,4DAAoD;;EAEpD,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,4DAAkD;;EAElD,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,oBAAoB;EACpB,4DAA8C;;EAE9C,gBAAgB;EAChB,kBAAkB;AACpB;AACA,kDAAkD","sourcesContent":["/* src/fonts/fonts.css */\r\n\r\n@font-face {\r\n  font-family: 'Inter';\r\n  src: url('./Inter-Black.woff2') format('woff2');\r\n\r\n  font-weight: 900;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Inter';\r\n  src: url('./Inter-Bold.woff2') format('woff2');\r\n\r\n  font-weight: 700;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Inter';\r\n  src: url('./Inter-ExtraBold.woff2') format('woff2');\r\n\r\n  font-weight: 800;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Inter';\r\n  src: url('./Inter-ExtraLight.woff2') format('woff2');\r\n\r\n  font-weight: 200;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Inter';\r\n  src: url('./Inter-SemiBold.woff2') format('woff2');\r\n\r\n  font-weight: 600;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Inter';\r\n  src: url('./Inter-Thin.woff2') format('woff2');\r\n\r\n  font-weight: 100;\r\n  font-style: normal;\r\n}\r\n/* You can continue to add more styles as needed */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
