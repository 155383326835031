import { CircularProgress } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function Loader() {
  const { fontColor } = useSelector((state) => state.listing);
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress sx={{ color: fontColor }} size="4rem" />
    </div>
  );
}

export default Loader;
