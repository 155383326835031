/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Box,
  FormControl,
  OutlinedInput,
  Typography,
  Card,
  CardMedia,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import ChatTitleHeader from '../../components/ChatTitleHeader';
import SendIcon from '../../assets/images/Send message icon.png';
import {
  useGetMessagesForListingQuery,
  useGetUserChatListingsQuery,
  useSendMessageToUserMutation,
  useUpdateSeenStatusMutation,
} from '../../store/middlewares/messages';
import Loader from '../../components/Loader';
import {
  setChatStatus,
  setSelectedListingLink,
  setSelectedUserData,
} from '../../store/reducers/chat';
import ChatComponent from '../../components/ChatComponent';
import ListingRemovedImage from '../../assets/images/ListingRemoved.png';
import { useFindListingByIdQuery } from '../../store/middlewares/listingsApi';

function ChatPage() {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const { chatListingId } = useParams(); // "chatId" should match the parameter name defined in your route

  const isMobile = useMediaQuery('(max-width:600px)');

  const {
    selectedUserName,
    selectedUserId,
    selectedListingId,
    selectedListingLink,
    selectedUserBusinessName,
    newChat,
  } = useSelector((state) => state.chat);

  const { data: userChatListings } = useGetUserChatListingsQuery();

  const { name, id, language } = useSelector((state) => state.auth);
  const { currentListing } = useSelector((state) => state.listing);
  const dispatch = useDispatch();

  const [eventSource, setEventSource] = useState(null);

  const urlListingId = chatListingId ?? selectedListingId;

  const {
    data: messages,
    error,
    isLoading,
    isError,
    refetch,
  } = useGetMessagesForListingQuery(urlListingId);

  const { data: listing } = useFindListingByIdQuery(urlListingId);

  const [updateSeenStatus] = useUpdateSeenStatusMutation();

  function doesListingExist(listingId) {
    return userChatListings?.some(
      (chatListing) => chatListing.ListingID === Number(listingId)
    );
  }
  // dispatching the user informatkon from the listing we got
  useEffect(() => {
    const lowerCaseListing = listing?.ListingType?.toLowerCase();
    if (lowerCaseListing === undefined) {
      dispatch(
        setSelectedListingLink(`${process.env.REACT_APP_URL}/listings/removed`)
      );
    } else {
      dispatch(
        setSelectedListingLink(
          `${process.env.REACT_APP_URL}/listings/${lowerCaseListing}-listing/${urlListingId}`
        )
      );
    }

    // dispatch(
    //   setSelectedUserData({
    //     name: listing?.FullName,
    //     id: listing?.UserID,
    //     businessName: listing?.BusinessName,
    //     selectedListingLink: `${process.env.REACT_APP_URL}/listings/${lowerCaseListing}-listing/${listing?.ListingID}`,
    //     selectedListingId: listing?.ListingID,
    //   })
    // );
  }, [listing]);

  useEffect(() => {
    // Establish SSE connection when component mounts
    const newEventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/messages/notify-reciever/${id}`
    );
    setEventSource(newEventSource);

    newEventSource.onmessage = function (event) {
      // Handle incoming messages
      // Assuming the server sends the updated message list or a signal to fetch new messages

      refetch(); // Assuming refetch() fetches the latest messages and updates the state
      window.scrollTo(0, document.body.scrollHeight + 10);
    };

    // Clean up the SSE connection when the component unmounts
    return () => {
      newEventSource.close();
    };
  }, [id]);

  useEffect(
    () =>
      // This function will be called when the component unmounts
      () => {
        // Only call the `updateSeenStatus` mutation if `id` is a valid number and not NaN
        if (!Number.isNaN(urlListingId) && urlListingId !== null) {
          updateSeenStatus(urlListingId);
        }
      },
    [isMobile, chatListingId, selectedListingId, updateSeenStatus]
  );

  useEffect(() => {
    if (doesListingExist(urlListingId)) {
      setChatStatus(false);
    } else {
      setChatStatus(true);
      setText(
        ` ${t('Message.HelloThisIs')} ${name}. ${t('Message.IWouldLikeTo')}`
      );
    }
  }, [urlListingId]);

  const [sendMessageToUser, { error: messageSendError }] =
    useSendMessageToUserMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <Loader />;
  }
  if (isError) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  if (error) {
    return <Typography>There was as error fetching messages</Typography>;
  }

  const handleSendMessage = async () => {
    if (text.trim() === '') {
      toast.error(`${t('Notifications.PleaseEnterSomeText')}`);
      return;
    }
    const requestBody = {
      receiverId: selectedUserId,
      listingId: urlListingId,
      messageText: text,
    };

    try {
      await sendMessageToUser(requestBody).unwrap();

      setText('');
      if (newChat) {
        dispatch(setChatStatus(false));
      }
      refetch();
      // eslint-disable-next-line no-shadow
    } catch (error) {
      toast.error(error?.data?.message);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {isMobile && <ChatTitleHeader title={selectedUserName} color="#4A4A4A" />}

      <ChatComponent
        messages={messages}
        currentUserId={id}
        selectedListingId={urlListingId}
      />

      {newChat ? (
        <>
          {/* {!isMobile && (
            <ChatTitleHeader title={selectedUserName} color="#4A4A4A" />
          )} */}
          {!isMobile && (
            <ChatTitleHeader title={selectedUserName} color="#4A4A4A" />
          )}
          <Box
            sx={{
              mx: isMobile ? 2 : 0,
              marginTop: 'auto',
            }}
          >
            {/* <Card
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '10px',
                padding: 1,
                pb: 0,
                marginTop: 'auto',
              }}
            >
              <CardMedia
                component="img"
                sx={{ width: 121, borderRadius: 2, maxHeight: 100 }}
                image={
                  listing?.ListingID === undefined
                    ? ListingRemovedImage
                    : listing?.Images?.split(',')?.[0]
                } // Replace with your image path
                alt="ListingImage"
              />
              <CardContent
                sx={{ pr: 1, pt: 0, pb: 0, pl: 1, flex: '0 1 auto', mb: 0 }}
                style={{ paddingBottom: '12px' }}
              >
                <Typography
                  sx={{ fontSize: 12, fontWeight: 600, pr: 1 }}
                  color="#4A4A4A"
                >
                  {listing?.[
                    language === 'EN'
                      ? 'SubcategoryName_EN'
                      : 'SubcategoryName_MM'
                  ] ??
                    listing?.[
                      language !== 'EN'
                        ? 'SubcategoryName_EN'
                        : 'SubcategoryName_MM'
                    ]}{' '}
                  -{' '}
                  {listing?.[
                    language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                  ] ??
                    listing?.[
                      language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                    ]}{' '}
                  listing by {listing?.FullName}
                </Typography>
                <Link
                  style={{
                    color: '#0059B9',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: 1.2,
                    textDecoration: 'underline',
                  }}
                  to={`/listings/${
                    listing?.ListingType === 'Seller' ? 'seller' : 'buyer'
                  }-listing/${listing?.ListingID}`}
                >
                  Go to listing details page
                </Link>
              </CardContent>
            </Card> */}
            <FormControl
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '15px',
                marginTop: 'auto',
                mx: isMobile ? 0 : 4,
              }}
            >
              <OutlinedInput
                sx={{
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adjust the values as needed
                  backgroundColor: 'white',
                  flex: '1 0',
                }}
                size="small"
                multiline
                rows={3}
                placeholder="Enter message here"
                name="message"
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <Box
                sx={{
                  border: '1px solid #6964E6',
                  px: 1.25,
                  pt: 0.5,
                  borderRadius: 1,
                  cursor: 'pointer',
                }}
                onClick={handleSendMessage}
              >
                <Box
                  component="img"
                  sx={{
                    width: 18,
                  }}
                  src={SendIcon}
                  alt="Send Icon"
                />
              </Box>
            </FormControl>
          </Box>
        </>
      ) : (
        <FormControl
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gap: '15px',

            marginTop: 'auto',

            backgroundColor: 'white',
            mx: isMobile ? 2 : 4,
            py: 1,
          }}
        >
          <OutlinedInput
            sx={{
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adjust the values as needed
              backgroundColor: 'white',
              flex: '1 0',
            }}
            size="small"
            multiline
            rows={2}
            placeholder="Enter message here"
            name="message"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Box
            sx={{
              border: '1px solid #6964E6',
              px: 1.25,
              pt: 0.5,
              borderRadius: 1,
              cursor: 'pointer',
            }}
            onClick={handleSendMessage}
          >
            <Box
              component="img"
              sx={{
                width: 18,
              }}
              src={SendIcon}
              alt="Send Icon"
            />
          </Box>
        </FormControl>
      )}
    </Box>
  );
}

export default ChatPage;
