/* eslint-disable no-param-reassign */
// errorSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    selectedUserName: '',
    selectedUserId: '',
    selectedUserBusinessname: '',
    selectedListingLink: '',
    selectedListingId: '',
    newChat: true,
  },
  reducers: {
    setSelectedUserData: (state, action) => {
      state.selectedUserName = action.payload.name;
      state.selectedUserId = action.payload.id;
      state.selectedUserBusinessname = action.payload.businessName;
      state.selectedListingLink = action.payload.selectedListingLink;
      state.selectedListingId = action.payload.selectedListingId;
    },

    setChatStatus: (state, action) => {
      state.newChat = action.payload;
    },
    setSelectedListingId: (state, action) => {
      state.selectedListingId = action.payload;
    },
    setSelectedListingLink: (state, action) => {
      state.selectedListingLink = action.payload;
    },

    setChatToInitialState: (state) => {
      state.selectedUserName = '';
      state.selectedUserId = '';
      state.selectedUserBusinessname = '';
      state.selectedListingLink = '';
      state.selectedListingId = '';
      state.newChat = true;
    },
  },
});

export const {
  setSelectedUserData,
  setChatStatus,
  setSelectedListingId,
  setChatToInitialState,
  setSelectedListingLink,
} = chatSlice.actions;
export default chatSlice.reducer;
