/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Toolbar, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTranslation } from 'react-i18next';
import SellerListingGray from '../assets/images/Seller listings (gray).png';
import SellerListingActive from '../assets/images/Seller listings icon (open).png';
import BuyerListingGray from '../assets/images/Buyer listings icon.png';
import BuyerListingActive from '../assets/images/Buyer listings icon (open).png';
import ChatGray from '../assets/images/Conversations icon.png';
import ChatActive from '../assets/images/Conversations icon (open).png';
import NotificationGray from '../assets/images/Notifications icon.png';
import NotificationActive from '../assets/images/Notifications icon (open).png';
import {
  setListingType,
  setCurrentPageFooter,
} from '../store/reducers/listing';
import { useGetNotificationsQuery } from '../store/middlewares/notifications';
import Loader from './Loader';
import { useGetUnreadMessageCountQuery } from '../store/middlewares/messages';

function ListingsFooter() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const { color, currentPageFooter } = useSelector((state) => state.listing);
  const { id } = useSelector((state) => state.auth);
  const [eventSource, setEventSource] = useState(null);
  const { t } = useTranslation();

  const {
    refetch,
    data: notifications,
    isLoading,
    isFetching,
    error,
  } = useGetNotificationsQuery();

  const {
    refetch: messageCountRefetch,
    data: messageCount,
    isLoading: isMessageCountLoading,
    isError: isMessageCountError,
  } = useGetUnreadMessageCountQuery();

  useEffect(() => {
    // Establish SSE connection when component mounts
    const newEventSource = new EventSource(
      `${process.env.REACT_APP_API_URL}/messages/notify-reciever/${id}`
    );
    setEventSource(newEventSource);

    newEventSource.onmessage = function (event) {
      // Handle incoming messages
      // Assuming the server sends the updated message list or a signal to fetch new messages

      messageCountRefetch(); // Assuming refetch() fetches the latest messages and updates the state
    };

    // Clean up the SSE connection when the component unmounts
    return () => {
      newEventSource.close();
    };
  }, [id]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterListings = (page) => {
    dispatch(setCurrentPageFooter(page));
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    // Whenever currentPage changes, refetch notifications
    refetch();
    messageCountRefetch();
  }, [currentPageFooter, refetch, messageCountRefetch]);

  if (isLoading || isMessageCountLoading) {
    return <Loader />;
  }

  // Handle error state
  if (isMessageCountError) {
    return <div>Error: </div>;
  }

  function checkForUnreadNotifications() {
    return notifications?.some((notification) => notification.IsRead === 0);
  }

  return (
    <AppBar
      position={isMobile ? 'fixed' : 'relative'}
      sx={{
        top: 'auto',
        bottom: 0,
        // boxShadow: 'none',
        backgroundColor: 'white',
        pb: isMobile ? 0.5 : 0,
        boxShadow: isMobile ? 'auto' : 'none',
        width: isMobile ? '100%' : 'auto',
        flex: 1,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          paddingLeft: isMobile ? 'auto' : 0,
          paddingRight: isMobile ? 'auto' : 0,
        }}
      >
        <Button
          color="inherit"
          onClick={() => {
            filterListings('Seller');
            dispatch(setListingType('Seller'));
            handleNavigate('/listings/seller-listings');
          }}
          sx={{
            backgroundColor: 'white',
            borderColor: 'white',
            color: currentPageFooter === 'Seller' ? '#368C36' : '#A5A5A5',
            fontSize: 13,
            wordWrap: 'normal',
            whiteSpace: 'nowrap',
            boxShadow:
              currentPageFooter === 'Seller'
                ? '0px 2px 4px rgba(0, 0, 0, 0.2)'
                : 'none',
            borderRadius: 5,
            fontWeight: 600,
            py: 1.2,
            px: currentPageFooter === 'Seller' ? 4 : 2,
          }}
        >
          <Box
            component="img"
            width={18}
            src={
              currentPageFooter === 'Seller'
                ? SellerListingActive
                : SellerListingGray
            }
            alt="Listing Icon"
            sx={{ mr: 1 }}
          />
          {(currentPageFooter === 'Seller' || !isMobile) &&
            `${t('Footer.SellerListings')}`}
        </Button>
        <Button
          onClick={() => {
            filterListings('Buyer');
            dispatch(setListingType('Buyer'));
            handleNavigate('/listings/buyer-listings');
          }}
          sx={{
            backgroundColor: 'white',
            borderColor: 'white',
            color: currentPageFooter === 'Buyer' ? '#E27614' : '#A5A5A5',
            fontSize: 13,

            wordWrap: 'normal',
            whiteSpace: 'nowrap',
            boxShadow:
              currentPageFooter === 'Buyer'
                ? '0px 2px 4px rgba(0, 0, 0, 0.2)'
                : 'none',
            borderRadius: 5,
            fontWeight: 600,
            py: 1.2,
            px: currentPageFooter === 'Buyer' ? 4 : 2,
          }}
        >
          {' '}
          <Box
            component="img"
            width={23}
            src={
              currentPageFooter === 'Buyer'
                ? BuyerListingActive
                : BuyerListingGray
            }
            alt="Listing Icon"
            sx={{ mr: 1, color }}
          />
          {(currentPageFooter === 'Buyer' || !isMobile) &&
            `${t('Footer.BuyerListings')}`}
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            filterListings('Conversations');
            handleNavigate('/conversations');
          }}
          sx={{
            backgroundColor: 'white',
            borderColor: 'white',
            color:
              currentPageFooter === 'Conversations' ? '#6964E6' : '#A5A5A5',
            fontSize: 13,
            wordWrap: 'normal',
            whiteSpace: 'nowrap',
            boxShadow:
              currentPageFooter === 'Conversations'
                ? '0px 2px 4px rgba(0, 0, 0, 0.2)'
                : 'none',
            borderRadius: 5,
            fontWeight: 600,
            py: 1.2,
            px: currentPageFooter === 'Conversations' ? 4 : 2,
            position: 'relative',
          }}
        >
          <Box
            component="img"
            width={18}
            src={currentPageFooter === 'Conversations' ? ChatActive : ChatGray}
            alt="Listing Icon"
            sx={{ mr: 1 }}
          />
          {messageCount?.count > 0 && (
            <FiberManualRecordIcon
              sx={{
                fontSize: '10px',
                color: '#6964E6',
                position: 'absolute',
                top: 4,
                right: currentPageFooter === 'Conversations' ? 10 : 17,
              }}
            />
          )}
          {(currentPageFooter === 'Conversations' || !isMobile) &&
            `${t('Footer.Conversations')}`}
        </Button>
        <Button
          color="inherit"
          onClick={() => {
            filterListings('Notifications');
            handleNavigate('/notifications');
          }}
          sx={{
            backgroundColor: 'white',
            borderColor: 'white',
            color:
              currentPageFooter === 'Notifications' ? '#6964E6' : '#A5A5A5',
            fontSize: 13,
            wordWrap: 'normal',
            whiteSpace: 'nowrap',
            boxShadow:
              currentPageFooter === 'Notifications'
                ? '0px 2px 4px rgba(0, 0, 0, 0.2)'
                : 'none',
            borderRadius: 5,
            fontWeight: 600,
            py: 1.2,
            px: currentPageFooter === 'Notifications' ? 4 : 2,
            position: 'relative',
          }}
        >
          <Box
            component="img"
            width={18}
            src={
              currentPageFooter === 'Notifications'
                ? NotificationActive
                : NotificationGray
            }
            alt="Listing Icon"
            sx={{ mr: 1 }}
          />
          {checkForUnreadNotifications() && (
            <FiberManualRecordIcon
              sx={{
                fontSize: '10px',
                color: '#6964E6',
                position: 'absolute',
                top: 4,
                right: 17,
              }}
            />
          )}
          {(currentPageFooter === 'Notifications' || !isMobile) &&
            `${t('Footer.Notifications')}`}
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default ListingsFooter;
