/* eslint-disable import/order */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */

import {
  Box,
  Button,
  Container,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
  useMediaQuery,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import ShareIcon from '@mui/icons-material/Share';
import { toast } from 'react-toastify';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TitleHeader from '../../components/TitleHeader';
import ImageCarousel from '../../components/ImageCarousel';
import LocationSeller from '../../assets/images/Location icon (seller listings).png';
import LocationBuyer from '../../assets/images/Location icon (buyer listings).png';
import delilveryTruck from '../../assets/images/Set delivery status icon.png';
import siteInspectionStatus from '../../assets/images/Set site inspection status icon.png';
import ListingsFooter from '../../components/ListingsFooter';
import {
  useDeleteListingMutation,
  useFindListingByIdQuery,
  useUpdateListingStatusMutation,
} from '../../store/middlewares/listingsApi';
import LogoutDialog from '../../components/LogoutDialog';
import ReportDialog from '../../components/ReportDialog';
import ShareDialog from '../../components/ShareDialog';
import { useGetUserChatListingsQuery } from '../../store/middlewares/messages';
import {
  setChatStatus,
  setSelectedListingId,
  setSelectedUserData,
} from '../../store/reducers/chat';
import Loader from '../../components/Loader';
import useStyles from '../CreateAccount/style';
import {
  setCurrentListing,
  setCurrentListingId,
  setIsPreview,
  setListingType,
} from '../../store/reducers/listing';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WebHeader from '../../components/WebHeader';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { setSuccessMessage } from '../../store/reducers/auth';
import SucessMessage from '../../components/SucessMessage';
import ListingRemoved from '../../components/ListingRemoved';
import formatNumber from '../../utils/formatNumber';

function UserListingDetails() {
  const isMobile = useMediaQuery('(max-width:600px)');

  const { fontColor, currentListing } = useSelector((state) => state.listing);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { name, businessName, id, language } = useSelector(
    (state) => state.auth
  );

  const [listingOption, setListingOption] = useState('Listing Options');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { ListingID } = useParams(); // "chatId" should match the parameter name defined in your route

  const { t } = useTranslation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars

  const [updateListingStatus] = useUpdateListingStatusMutation();

  const {
    data: listing,
    isLoading,
    isError,
    error,
    refetch,
  } = useFindListingByIdQuery(ListingID);

  const { data: userChatListings } = useGetUserChatListingsQuery();

  const [deleteListing] = useDeleteListingMutation();

  // report
  const [reportConfirm, setReportConfirm] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  // sharing
  const [isShareDialogOpen, setShareDialogOpen] = useState(false);
  // const [currentUrl, setCurrentUrl] = useState(
  //   'https://waste-to-wealth.com/seller/cotton/fabrics/0543'
  // );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function formatDate(dateString) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    function getOrdinalSuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    }

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day}${getOrdinalSuffix(day)} ${months[monthIndex]} ${year}`;
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleReportConfirmClick = () => {
    setReportConfirm(false);
    setReportDialogOpen(true);
  };

  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  function doesListingExist(listingId) {
    return userChatListings?.some(
      (chatListing) => chatListing.ListingID === listingId
    );
  }

  const handleChatClick = () => {
    if (listing?.UserID === id) {
      toast.info(`${t('Notifications.YouAreTheOwner')}`);
      return;
    }
    if (doesListingExist(ListingID)) {
      dispatch(setChatStatus(false));
    } else {
      dispatch(setChatStatus(true));
    }
    const lowerCaseListing = listing?.ListingType?.toLowerCase();
    dispatch(
      setSelectedUserData({
        name: currentListing?.PostedBy,
        id: listing?.UserID,
        businessName: listing?.BusinessName,
        selectedListingLink: `${process.env.REACT_APP_URL}/listings/${lowerCaseListing}-listing/${ListingID}`,
        selectedListingId: ListingID,
      })
    );
    navigate(`/conversations/chat/${ListingID}`);
  };

  const handleEditListing = (listing) => {
    dispatch(setListingType(listing.ListingType));
    dispatch(setCurrentListing(listing));
    dispatch(setCurrentListingId(listing.ListingID));
    dispatch(setIsPreview(false));
    navigate('/listings/edit-listing');
  };

  const handleDeleteListing = (listing) => {
    setSelectedListingId({
      listingId: listing?.ListingID,
      category:
        language === 'EN'
          ? listing?.CategoryName_EN
          : listing?.CategoryName_MM ?? language !== 'EN'
            ? listing?.CategoryName_EN
            : listing?.CategoryName_MM,
      subcategory:
        language === 'EN'
          ? listing?.SubcategoryName_EN
          : listing?.SubcategoryName_MM ?? language !== 'EN'
            ? listing?.SubcategoryName_EN
            : listing?.SubcategoryName_MM,
    });
    setConfirmDelete(true);
  };

  const handleDelete = async () => {
    try {
      await deleteListing(listing.ListingID).unwrap();
      refetch();

      dispatch(
        setSuccessMessage(`${t('Notifications.ListingDeletedSuccessfully')}`)
      );
    } catch (err) {
      toast.error(`${t('Notifications.FailedToDeleteListing')}`);

      // Handle error, e.g., show an error message
    } finally {
      setConfirmDelete(false);
      setListingOption('Listing Options');
      navigate('/listings/my-listings');
    }
  };

  const handleStatusChange = async (listingId, status) => {
    try {
      // Call the mutation trigger with the new status
      const updateResponse = await updateListingStatus({
        listingId,
        status,
      }).unwrap();
      refetch();

      dispatch(setSuccessMessage(`${t('Notifications.UpdateSuccessfull')}`));
    } catch (err) {
      // Handle any error that occurred during the mutation
      toast.error(`${t('Notifications.FailedToUpdateListingStatus')}`);
    } finally {
      setListingOption('Listing Options');
    }
  };

  const handleListingOptionsClick = (e) => {
    setListingOption(e.target.value);

    if (e.target.value === 'Edit Listing') {
      handleEditListing(listing);
      return;
    }
    if (e.target.value === 'Delete Listing') {
      handleDeleteListing(listing);
      return;
    }

    if (e.target.value === 'Unpublish Listing') {
      handleStatusChange(listing.ListingID, 'Unpublished');
      return;
    }
    if (e.target.value === 'Publish Listing') {
      handleStatusChange(listing.ListingID, 'Published');
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError && error) {
    return (
      <>
        {!isMobile && <WebHeader />}
        <Box sx={{ px: 3, my: isMobile ? 8 : 0 }}>
          {' '}
          {isMobile && (
            <TitleHeader title={`Listing Removed`} color={fontColor} />
          )}
          <ListingRemoved />
        </Box>
      </>
    );
  }

  return (
    <>
      {!isMobile && <WebHeader />}
      <Box sx={{ px: 3, my: isMobile ? 8 : 0 }}>
        {' '}
        {isMobile && (
          <TitleHeader
            title={`${t(`ListingDetails.${listing?.ListingType} Listing`)} `}
            color={fontColor}
            listingId={`${listing?.ListingID}`}
          />
        )}
        {!isMobile && (
          <Typography
            variant="subtitle2"
            sx={{
              color: '#5C5C5C',
              fontSize: 12,
              fontWeight: 600,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              ml: '6%',
              mt: 2,
            }}
            gutterBottom
          >
            My Listings <KeyboardArrowRightIcon />{' '}
            {listing?.[
              language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
            ] ??
              listing?.[
                language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
              ]}
            <KeyboardArrowRightIcon />{' '}
            {listing?.[
              language === 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
            ] ??
              listing?.[
                language !== 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
              ]}{' '}
            <KeyboardArrowRightIcon />
            {listing?.ListingType} &nbsp;Listing&nbsp;
            {listing?.ListingID}
          </Typography>
        )}
        <Container maxWidth="lg">
          <SucessMessage />
          <FormControl
            sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}
          >
            <Select
              size="small"
              name="listing options"
              className={classes.paperCustom}
              value={listingOption}
              onChange={handleListingOptionsClick}
              sx={{
                width: '180px',
                marginLeft: 'auto',
                borderRadius: '5px',
                fontSize: 14,
                mr: -2,
                color: '#707070',
              }}
              IconComponent={KeyboardArrowDownIcon} // This is where you change the dropdown icon
            >
              {/* Replace these with actual units */}
              <MenuItem
                value={'Listing Options'}
                sx={{
                  color: '#707070',
                  fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                  fontWeight: 600,
                  fontSize: '14px', // Adjust the font size as needed
                  padding: '10px 10px', // Adjust padding as needed
                  borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                  display: 'none',
                  '&:last-child': {
                    borderBottom: 'none', // Remove border for the last item
                  },
                }}
              >
                {t('MyListing.ListingOptions')}
              </MenuItem>
              <MenuItem
                value="Edit Listing"
                sx={{
                  color: '#707070',
                  fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                  fontWeight: 600,
                  fontSize: '14px', // Adjust the font size as needed
                  padding: '10px 10px', // Adjust padding as needed
                  borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                  '&:last-child': {
                    borderBottom: 'none', // Remove border for the last item
                  },
                }}
              >
                {t('MyListing.Edit Listing')}
              </MenuItem>
              <MenuItem
                value="Delete Listing"
                sx={{
                  color: '#707070',
                  fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                  fontWeight: 600,
                  fontSize: '14px', // Adjust the font size as needed
                  padding: '10px 10px', // Adjust padding as needed
                  borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                  '&:last-child': {
                    borderBottom: 'none', // Remove border for the last item
                  },
                }}
              >
                {t('MyListing.Delete Listing')}
              </MenuItem>
              <MenuItem
                value={
                  listing?.ListingStatus === 'Published'
                    ? 'Unpublish Listing'
                    : 'Publish Listing'
                }
                sx={{
                  color: '#707070',
                  fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                  fontWeight: 600,
                  fontSize: '14px', // Adjust the font size as needed
                  padding: '10px 10px', // Adjust padding as needed
                  borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                  '&:last-child': {
                    borderBottom: 'none', // Remove border for the last item
                  },
                }}
              >
                {listing?.ListingStatus === 'Published'
                  ? t('MyListing.Unpublish Listing')
                  : t('MyListing.Publish Listing')}
              </MenuItem>
            </Select>
          </FormControl>
        </Container>
        <Box
          display={isMobile ? 'block' : 'flex'}
          justifyContent="space-evenly"
          gap="10%"
          sx={{ ml: isMobile ? 0 : '6%', mr: isMobile ? 0 : '10%' }}
        >
          <Box flex={1}>
            <Typography
              variant="subtitle2"
              sx={{ color: '#5C5C5C', fontSize: 12, fontWeight: 600, mb: 1 }}
            >
              {`${t('ListingDetails.PostedOn')} ${formatDate(
                listing?.CreatedAt
              )}`}
            </Typography>
            <ImageCarousel images={listing?.Images?.split(',') ?? []} />
            {!isMobile && (
              <>
                {/* <FormControl fullWidth sx={{ mt: 4, mb: 3 }}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontWeight: '600',
                      fontSize: 15,
                      fontFamily: 'Inter,SemiBold',
                      display: 'flex',
                      gap: '10px',
                      backgroundColor:
                        listing.ListingType === 'Seller'
                          ? '#5BA15B'
                          : '#EB9D28',
                      '&&:hover': {
                        backgroundColor:
                          listing.ListingType === 'Seller'
                            ? '#5BA15B'
                            : '#EB9D28',
                      },
                    }}
                    onClick={handleChatClick}
                  >
                    {t(`ListingDetails.Chat with ${listing.ListingType}`)}
                  </Button>
                </FormControl> */}
                <Box sx={{ mt: 10 }}>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={handleOpenShareDialog}
                  >
                    <ShareIcon sx={{ color: '#707070', fontSize: 18 }} />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t(`ListingDetails.ShareListing`)}
                    </Typography>
                  </Paper>
                </Box>
              </>
            )}
          </Box>
          <Box flex={1.3} paddingTop={isMobile ? 0 : 4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="8%"
            >
              <Box width="46%">
                <Typography
                  sx={{
                    fontWeight: 700,
                    color: fontColor,
                    fontSize: 14,
                    lineHeight: 1.2,
                  }}
                  gutterBottom
                >
                  {listing?.[
                    language === 'EN'
                      ? 'SubcategoryName_EN'
                      : 'SubcategoryName_MM'
                  ] ??
                    listing?.[
                      language !== 'EN'
                        ? 'SubcategoryName_EN'
                        : 'SubcategoryName_MM'
                    ]}
                </Typography>
                <Typography
                  sx={{ color: '#5C5C5C', fontSize: 10, fontWeight: 600 }}
                >
                  {t('ListingDetails.Subcategory')}
                </Typography>
              </Box>

              <Box width="46%">
                <Typography
                  sx={{ fontWeight: 700, color: fontColor, fontSize: 14 }}
                >
                  {listing?.[
                    language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                  ] ??
                    listing?.[
                      language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                    ]}
                </Typography>
                <Typography
                  sx={{ color: '#5C5C5C', fontSize: 10, fontWeight: 600 }}
                >
                  {t('ListingDetails.Category')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography
                sx={{ fontWeight: 700, color: fontColor, fontSize: 14 }}
              >
                {`Ks.${formatNumber(listing?.PriceMin)}`}
                {listing?.PriceMax
                  ? ` - Ks.${formatNumber(listing?.PriceMax)}`
                  : ''}
                {` / ${listing?.Unit}`}
              </Typography>
              <Typography
                sx={{ color: fontColor, fontSize: 11, fontWeight: 600 }}
              >
                {`${formatNumber(listing?.Quantity)} ${listing?.Unit} ${
                  listing?.ListingType === 'Seller'
                    ? t('ListingDetails.available')
                    : t('ListingDetails.needed')
                }`}
              </Typography>
            </Box>
            <Typography
              variant="body"
              sx={{
                fontSize: 11,
                color: '#5C5C5C',
                fontWeight: 600,
                lineHeight: 1.2,
              }}
            >
              {listing?.Description}{' '}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              gap="8%"
              mt={2}
            >
              <Box
                display="flex"
                alignItems="center"
                gap="8px"
                justifyContent="flex-start"
                width="46%"
              >
                <Box
                  component="img"
                  sx={{
                    width: 20,
                  }}
                  src={
                    listing?.ListingType === 'Seller'
                      ? LocationSeller
                      : LocationBuyer
                  }
                  alt="Location Icon"
                />
                <Box>
                  <Typography
                    variant="subtitle2"
                    fontSize={11}
                    fontWeight={600}
                    color="#4A4A4A"
                    fontFamily="Inter,Regular"
                    lineHeight={1.2}
                    gutterBottom
                  >
                    {capitalizeFirstLetter(listing?.Township)}
                  </Typography>
                  <Typography
                    sx={{ mt: 0.5 }}
                    variant="subtitle2"
                    fontWeight={700}
                    fontSize={13}
                    color="#4A4A4A"
                    lineHeight={1.2}
                  >
                    {capitalizeFirstLetter(listing?.City)}
                  </Typography>
                </Box>
              </Box>
              <Box width="46%">
                <Typography
                  sx={{ fontWeight: 600, color: '#4A4A4A', fontSize: 11 }}
                >
                  {listing?.ListingType === 'Seller'
                    ? 'Sold by'
                    : 'Requested by'}
                </Typography>
                <Typography
                  sx={{ fontWeight: 700, color: '#4A4A4A', fontSize: 12 }}
                >
                  {listing?.FullName}
                </Typography>
                <Typography
                  sx={{ color: '#4A4A4A', fontSize: 11, fontWeight: 600 }}
                >
                  {listing?.BusinessName}
                </Typography>
              </Box>
            </Box>
            <Box
              display={isMobile ? 'block' : 'flex'}
              gap="24%"
              marginTop={isMobile ? 2 : 4}
              alignItems={'center'}
            >
              <Box>
                <Paper
                  elevation={1}
                  sx={{
                    border: '1px solid #368C367A',

                    py: 0.6,
                    px: 1,
                    display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
                    alignItems: 'center',
                    gap: '10px',
                    borderRadius: 2,
                    boxShadow: 'none',
                  }}
                >
                  <img
                    src={delilveryTruck}
                    width="20"
                    alt="Site Inspection Status"
                  />
                  <Typography fontSize={12} fontWeight={600} color={fontColor}>
                    {t(`${listing?.DeliveryStatus}`)}
                  </Typography>
                </Paper>
              </Box>

              <Box marginTop={isMobile ? 2 : 0}>
                <Paper
                  elevation={1}
                  sx={{
                    border: '1px solid #368C367A',
                    py: 0.4,
                    px: 1,
                    borderRadius: 2,
                    display: 'inline-flex', // Changed from 'flex' to 'inline-flex'
                    alignItems: 'center',
                    gap: '10px',
                    boxShadow: 'none',
                  }}
                >
                  <img
                    src={siteInspectionStatus}
                    width="17"
                    style={{ paddingBottom: '4px' }}
                    alt="Site Inspection Status"
                  />
                  <Typography fontSize={12} fontWeight={600} color={fontColor}>
                    {t(`${listing?.SiteInspectionStatus}`)}
                  </Typography>
                </Paper>
              </Box>
            </Box>
            {isMobile && (
              <>
                {/* <FormControl fullWidth sx={{ mt: 4, mb: 3 }}>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      fontWeight: '600',
                      fontSize: 15,
                      fontFamily: 'Inter,SemiBold',
                      display: 'flex',
                      gap: '10px',
                      backgroundColor:
                        listing.ListingType === 'Seller'
                          ? '#5BA15B'
                          : '#EB9D28',
                    }}
                    onClick={handleChatClick}
                  >
                    {t(`ListingDetails.Chat with ${listing.ListingType}`)}
                  </Button>
                </FormControl> */}
                <Box sx={{ mt: 4, mb: 10 }}>
                  <Paper
                    elevation={1}
                    sx={{
                      py: 1,
                      px: 2,
                      borderRadius: 2,
                      display: 'flex', // Changed from 'flex' to 'inline-flex'
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '10px',
                      mt: 2,
                      width: '100%',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                    onClick={handleOpenShareDialog}
                  >
                    <ShareIcon sx={{ color: '#707070', fontSize: 18 }} />
                    <Typography fontSize={12} fontWeight={600} color="#707070">
                      {t(`ListingDetails.ShareListing`)}
                    </Typography>
                  </Paper>
                </Box>
              </>
            )}
          </Box>
        </Box>
        {reportConfirm && (
          <LogoutDialog
            handleLogout={handleReportConfirmClick}
            open={reportConfirm}
            handleClose={() => setReportConfirm(false)}
            yes={t('Yes')}
            no={t('No')}
            content={`${t('ListingDetails.AreYou')} ${ListingID} (${
              listing?.Category
            },${listing?.Subcategory})`}
          />
        )}
        {reportDialogOpen && (
          <ReportDialog
            open={reportDialogOpen}
            onClose={() => setReportDialogOpen(false)}
            type={listing?.ListingType}
            id={ListingID}
            ReportType="Listing"
          />
        )}
        {confirmDelete && (
          <LogoutDialog
            handleLogout={handleDelete}
            open={confirmDelete}
            handleClose={() => setConfirmDelete(false)}
            yes={t('Yes')}
            no={t('No')}
            content={`${t('MyListing.AreYouSure')} ${listing?.ListingID} (${
              language === 'EN'
                ? listing?.CategoryName_EN
                : listing?.CategoryName_MM ?? language !== 'EN'
                  ? listing?.CategoryName_EN
                  : listing?.CategoryName_MM
            }, ${
              language === 'EN'
                ? listing?.SubcategoryName_EN
                : listing?.SubcategoryName_MM ?? language !== 'EN'
                  ? listing?.SubcategoryName_EN
                  : listing?.SubcategoryName_MM
            })`}
          />
        )}
        <ShareDialog
          open={isShareDialogOpen}
          onClose={handleCloseShareDialog}
          url={window.location.href}
        />
        {isMobile && <ListingsFooter />}
      </Box>
    </>
  );
}

export default UserListingDetails;
