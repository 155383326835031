/* eslint-disable no-unused-vars */
import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoSmall from '../assets/images/Logo (small).png';
import Sidebar from './Sidebar';
import ListingsFooter from './ListingsFooter';

function WebHeader() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();

  return (
    <Box
      paddingLeft={isMobile ? '24px' : '48px'}
      paddingRight={isMobile ? '24px' : '24px'}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap="40px"
      sx={{
        py: 1,

        boxShadow: isMobile ? 'none' : '0px 2px 4px rgba(0, 0, 0, 0.1)',
      }}
      backgroundColor="white"
    >
      <Box
        component="img"
        sx={{
          width: 120,
          cursor: 'pointer',
        }}
        src={LogoSmall}
        alt="Waste to Wealth Logo"
        onClick={() => navigate('/listings/seller-listings')}
      />
      <ListingsFooter />
      <Sidebar />
    </Box>
  );
}

export default WebHeader;
