/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, useMediaQuery } from '@mui/material';
// import Thailad from '../assets/images/Thailand flag.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Location from '../assets/images/Location icon (seller listings).png';
import LocationBuyer from '../assets/images/Location icon (buyer listings).png';
import {
  setCurrentListing,
  setCurrentListingId,
} from '../store/reducers/listing';
// import waste from '../assets/images/waste.webp';
import NoImageUploaded from '../assets/images/NoImageUploaded.png';
import { useGetUserListingsQuery } from '../store/middlewares/listingsApi';
import formatNumber from '../utils/formatNumber';

function ListingCard({ listing }) {
  const { listingType } = useSelector((state) => state.listing);
  const { language } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');

  const { data: listings } = useGetUserListingsQuery();

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  function isCurrentUsersListing(listingID) {
    return listings?.some((listing) => listing.ListingID === listingID);
  }

  const handleListingDetails = () => {
    dispatch(setCurrentListing(listing));
    dispatch(setCurrentListingId(listing.ListingID));
    if (isCurrentUsersListing(listing.ListingID)) {
      navigate(`/listings/my-listings/${listing.ListingID}`);
    } else {
      navigate(
        listingType === 'Seller'
          ? `/listings/seller-listing/${listing.ListingID}`
          : `/listings/buyer-listing/${listing.ListingID}`
      );
    }
  };

  return (
    <Card
      onClick={handleListingDetails}
      sx={{
        borderRadius: '16px',
        m: 2,
        p: 1.5,
        cursor: 'pointer',
      }}
    >
      {listingType === 'Buyer' && (
        <Box sx={{ mb: 2 }}>
          <Typography
            fontFamily="Inter,Regular"
            fontWeight={600}
            fontSize={13}
            color="#4A4A4A"
            gutterBottom
            sx={{ display: 'flex', gap: '10px', pl: 2 }}
          >
            {t('AddListing.Requested by')} :{' '}
            <b
              style={{
                color: '#4A4A4A',
                marginTop: '-2px',
                fontSize: '14px',
              }}
            >
              {listing?.PostedBy}{' '}
            </b>
          </Typography>
          <Box>
            <Typography
              variant="subtitle2"
              fontWeight={700}
              fontSize={14}
              color="#E27614"
              lineHeight={1}
              sx={{ mb: 1.5, ml: 2 }}
            >
              {`Ks.${formatNumber(listing?.PriceMin)}`}
              {listing?.PriceMax
                ? ` - Ks.${formatNumber(listing?.PriceMax)}`
                : ''}
              {` / ${listing?.Unit}`}
            </Typography>
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent={'space-between'}
              gap="10px"
              sx={{ mx: 2 }}
            >
              <Typography
                variant="h6"
                fontWeight={600}
                fontSize={13}
                color="#E27614"
                gutterBottom
                sx={{
                  pt: -0.5,
                  mt: -0.3,
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 1, // Only one line
                  WebkitBoxOrient: 'vertical',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap', // Changed from pre-wrap to nowrap
                }}
                lineHeight={1.2}
              >
                {formatNumber(listing?.Quantity)} {listing?.Unit}{' '}
                {t('AddListing.needed')}
              </Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <Box
                  component="img"
                  sx={{ width: 20 }}
                  src={LocationBuyer}
                  alt="Location Icon"
                />
                <Box sx={{ justifySelf: 'flex-start' }}>
                  <Typography
                    variant="subtitle2"
                    fontSize={12}
                    fontWeight={600}
                    color="#4A4A4A"
                    fontFamily="Inter,Regular"
                    textTransform="camelcase"
                    lineHeight={1.2}
                    gutterBottom
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                  >
                    {capitalizeFirstLetter(listing?.Township)}
                  </Typography>
                  <Typography
                    sx={{ mt: -0.4 }}
                    variant="subtitle2"
                    fontWeight={700}
                    lineHeight={1.1}
                    color="#4A4A4A"
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                  >
                    {capitalizeFirstLetter(listing?.City)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection={listingType === 'Buyer' ? 'column-reverse' : 'column'}
      >
        <CardMedia
          component="img"
          image={(
            listing?.Images?.preview ||
            listing?.Images?.split(',')[0] ||
            NoImageUploaded
          )?.toString()} // Replace with your image path
          alt="Listing Image"
          sx={{
            borderTopLeftRadius: listingType === 'Seller' ? '8px' : '0px',
            borderTopRightRadius: listingType === 'Seller' ? '8px' : 'opx',
            borderBottomLeftRadius: listingType === 'Seller' ? '1px' : '8px',
            borderBottomRightRadius: listingType === 'Seller' ? '1px' : '8px',
            maxHeight: '143px',
            minHeight: '140px',
          }}
        />

        <CardContent
          sx={{
            p: 0,
            '&:last-child': {
              paddingBottom: 0,
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              backgroundColor: listingType === 'Seller' ? '#94C754' : '#F2BD38',
              py: 0.7,
              px: 2,
              borderTopLeftRadius: listingType === 'Seller' ? '1px' : '8px',
              borderTopRightRadius: listingType === 'Seller' ? '1px' : '8px',
              borderBottomLeftRadius: listingType === 'Seller' ? '8px' : '1px',
              borderBottomRightRadius: listingType === 'Seller' ? '8px' : '1px',
            }}
          >
            <Typography
              gutterBottom
              fontWeight={600}
              fontSize={15}
              sx={{
                color: '#FFFFFF',
                mb: 0,
                maxWidth: isMobile ? '200px' : '240px', // Set a max width
                overflow: 'hidden',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', // Ensure text doesn't wrap
                mr: 0.5, // Added right margin of 16px
              }}
              lineHeight={1.2}
            >
              {listing?.[
                language === 'EN' ? 'SubcategoryName_EN' : 'SubcategoryName_MM'
              ] ??
                listing?.[
                  language !== 'EN'
                    ? 'SubcategoryName_EN'
                    : 'SubcategoryName_MM'
                ]}
            </Typography>
            <Typography
              gutterBottom
              fontSize={13}
              fontWeight={600}
              fontFamily={'Inter,Regular'}
              sx={{
                color: '#FFFFFF',
                mb: 0,
                mt: 0.3,
                maxWidth: isMobile ? '70px' : '100px', // Set a max width
                overflow: 'hidden',
                display: 'inline-block',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', // Ensure text doesn't wrap
                ml: 1, // Added left margin of 8px
              }}
            >
              | &nbsp;
              {listing?.[
                language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
              ] ??
                listing?.[
                  language !== 'EN' ? 'CategoryName_EN' : 'CategoryName_MM'
                ]}
            </Typography>
          </Box>

          {listingType === 'Seller' && (
            <Box>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                fontSize={15}
                color="#368C36"
                gutterBottom
                lineHeight={1.2}
                display="block"
                sx={{ mt: 1, ml: 2 }}
              >
                {`Ks.${formatNumber(listing?.PriceMin)}`}
                {listing?.PriceMax
                  ? ` - Ks.${formatNumber(listing?.PriceMax)}`
                  : ''}
                {` / ${listing?.Unit}`}
              </Typography>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ ml: 2 }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={14}
                  color="#368C36"
                  gutterBottom
                  sx={{ pt: -0.5, mt: -0.3 }}
                  lineHeight={1}
                >
                  {formatNumber(listing?.Quantity)} {listing?.Unit}{' '}
                  {t('AddListing.available')}
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="5px"
                sx={{ ml: 2 }}
              >
                <Typography
                  fontFamily="Inter,Regular"
                  fontWeight={600}
                  fontSize={13}
                  color="#4A4A4A"
                  lineHeight={1.3}
                  sx={{
                    mt: 0.5,
                    maxWidth: isMobile ? '200px' : '240px', // Set a max width
                    overflow: 'hidden',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap', // Ensure text doesn't wrap
                  }}
                >
                  {t('AddListing.Sold by')}:{' '}
                  <b style={{ color: '#4A4A4A', fontSize: '15px' }}>
                    {listing?.PostedBy}
                  </b>
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="4px"
                  justifyContent="center"
                >
                  <Box
                    component="img"
                    sx={{ width: 20 }}
                    src={Location}
                    alt="Location Icon"
                  />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      fontSize={13}
                      fontWeight={600}
                      color="#4A4A4A"
                      lineHeight={1.2}
                      fontFamily="Inter,Regular"
                      gutterBottom
                      whiteSpace={'nowrap'}
                      textOverflow={'ellipsis'}
                    >
                      {capitalizeFirstLetter(listing?.Township)}
                    </Typography>
                    <Typography
                      sx={{ mt: -0.4 }}
                      variant="subtitle2"
                      fontWeight={700}
                      color="#4A4A4A"
                      lineHeight={1.1}
                      whiteSpace={'nowrap'}
                      textOverflow={'ellipsis'}
                    >
                      {capitalizeFirstLetter(listing?.City)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
}

export default ListingCard;
