import { useSelector } from 'react-redux';
import { usePostErrorLogMutation } from '../store/middlewares/errorLogApi';

function useErrorLogger() {
  const [postErrorLog] = usePostErrorLogMutation();
  const auth = useSelector((state) => state.auth);

  const logError = (error, errorInfo = {}) => {
    const errorData = {
      errorMessage: error.toString(),
      errorStack: errorInfo.componentStack || '',
      userInfo: {
        user: auth?.user,
        role: auth?.role,
        name: auth?.name,
        id: auth?.id,
      }, // Replace with actual user info if available
      pageUrl: window.location.href,
      browserInfo: navigator.userAgent,
    };

    postErrorLog(errorData);
  };

  return logError;
}
export default useErrorLogger;
