/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function ChatWithBuyerPopUp({ open, setOpen }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
      sx={{ px: 2 }}
    >
      <DialogTitle
        id="logout-dialog-title"
        sx={{
          fontSize: '14px',
          fontFamily: 'Inter,Regular',
          fontWeight: 700,
          color: '#707070',
          mt: 2,
        }}
        maxWidth={'300px'}
      >
        {t('AddListing.PleaseCreateA')}{' '}
        <span style={{ color: '#368C36' }}>
          {t('ListingDetails.Seller Listing')}
        </span>{' '}
        {t('AddListing.WithinSame')}{' '}
        <span style={{ color: '#E27614' }}>
          {t('AddListing.ChattingWithBuyer')}
        </span>
      </DialogTitle>
      <DialogContent
        sx={{
          fontSize: 12,
          color: '#707070',
          fontWeight: 600,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {t('AddListing.ThisIsDoneIn')}
        <Button
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);

            navigate('/listings/add-seller-listing');
          }}
          variant="contained"
          sx={{
            color: 'white',
            fontWeight: 600,
            fontSize: 12,
            backgroundColor: '#5BA15B',
            mt: 3,
          }}
        >
          {t('AddListing.Add Seller Listing')}
        </Button>
        <Link
          sx={{
            margin: 'auto',
            color: '#0059B9',
            textDecoration: 'underline',
            fontSize: 12,
            fontWeight: 600,
            mt: 1,
            cursor: 'pointer',
          }}
          onClick={() => setOpen(false)}
        >
          {t('AddListing.I will do it later')}
        </Link>
      </DialogContent>
    </Dialog>
  );
}

export default ChatWithBuyerPopUp;
