/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoSmall from '../../assets/images/Logo (small).png';
import useStyles from '../CreateAccount/style';

function TermsOfUse() {
  const classes = useStyles();
  const { language } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.rootContainer} sx={{ marginBottom: '80px' }}>
      <Box
        component="img"
        sx={{
          width: 100,
          marginBottom: 4,
        }}
        src={LogoSmall}
        alt="Waste to Wealth Logo"
        onClick={() => navigate('/')}
      />
      <Typography
        fontSize="28px"
        className={classes.title}
        color="#368C36"
        fontFamily="Inter,Bold"
        fontWeight="650"
        sx={{ marginBottom: '20px' }}
      >
        {language === 'MM' ? `ကိုယ်ရေးအချက်အလက်မူဝါဒ` : `Privacy Policy`}
      </Typography>

      <Typography
        variant="h6"
        className={classes.title}
        color="#5C5C5C"
        fontFamily="Inter,Bold"
        fontWeight="650"
        fontSize={16}
        gutterBottom
      >
        {language === 'MM' ? '၁.လက်ခံပါသလား?' : '1. Acceptance'}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ color: 'black' }}
        fontSize="12px"
        fontFamily="Inter,Bold"
        gutterBottom
      >
        {language === 'MM'
          ? 'ဤစည်းကမ်းသတ်မှတ်ချက်များ (အသုံးပြုမှုစည်းမျဉ်းများ) နှင့် ဆက်စပ်သော ကိုယ်ရေးကိုယ်တာမူဝါဒ (ကိုယ်ရေးကိုယ်တာမူဝါဒ) တို့သည် ရွှေအမှိုက် Digital Platform /ဒစ်ဂျစ်တယ်ပလက်ဖောင်းအပေါ် သင်၏အသုံးပြုမှုကို ထိန်းချုပ်မည်ဖြစ်သည်။ဤစည်းမျဥ်းစည်းကမ်းများနှင့် '
          : `This privacy policy (“privacy policy”) and related terms and conditions
        ("terms and conditions") shall govern your usage of this Waste To Wealth
        digital platform.`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="#5C5C5C"
        fontSize={12}
        fontFamily="Inter,Regular"
        fontWeight="600"
        sx={{ marginBottom: '20px', mt: 1 }}
      >
        {language === 'MM'
          ? 'ဤစည်းမျဥ်းစည်းကမ်းများနှင့် ဆက်စပ်သောကိုယ်ရေးကိုယ်တာမူဝါဒကို သင်သဘောမတူပါက၊ သင်သည် ဤဒစ်ဂျစ်တယ်ပလက်ဖောင်း/digital platform ကို အသုံးမပြုရပါ။'
          : `If you do not agree to these terms and conditions and the related
        privacy policy, you must not use the digital platform.`}
      </Typography>

      <Typography
        variant="h6"
        className={classes.title}
        color="#5C5C5C"
        fontFamily="Inter,Bold"
        fontWeight="650"
        fontSize={16}
        gutterBottom
      >
        {language === 'MM'
          ? '၂။ ကိုယ်ရေးကိုယ်တာအချက်အလက်များ၏ အဓိပ္ပာယ်ဖွင့်ဆိုချက်'
          : `2. Definition of Personal Information`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="black"
        fontSize={12}
        fontFamily="Inter,Regular"
        fontWeight="500"
        sx={{ marginBottom: '20px' }}
      >
        {language === 'MM'
          ? 'သင်၏ ကိုယ်ရေးကိုယ်တာအချက်အလက်များတွင် သင့်အမည်၊ အီးမေးလ်လိပ်စာ၊ နေထိုင်သည့်နိုင်ငံ၊ ဖုန်းနံပါတ် (“မိမိကိုယ်ရေးကိုယ်တာ အချက်အလက်”) တို့ပါဝင်သည်။ ဤ ရွှေအမှိုက် Digital Platform ပေါ်တွင် သင်ပေးပို့ထားသော ကိုယ်ရေးအချက်အလက်များနှင့် သင့်အမည်၊ အီးမေးလ်လိပ်စာ သို့မဟုတ် ဆက်သွယ်ရန်ဖုန်းနံပါတ် ကဲ့သိုသော သင့်အားဖော်ပြနိုင်သည့် အခြားအချက်အလက်မှတဆင့် ဆက်သွယ်ဖော်ထုတ်နိုင်ပါသည်။ '
          : `Personal information will include your name, e-mail address, country of
        residence, phone number (“personal data”) and any other information that
        can directly be identified and linked with your name, email id and/or
        phone number and has been submitted by you on or through this Waste to Wealth
        digital platform.`}
      </Typography>

      <Typography
        variant="h6"
        className={classes.title}
        fontSize={16}
        color="#5C5C5C"
        fontFamily="Inter,Bold"
        fontWeight="650"
        gutterBottom
      >
        {language === 'MM'
          ? '၃။ အထက်ဖော်ပြပါ ကိုယ်ရေးကိုယ်တာအချက်အလက်များကို ကျွန်ုပ်တို့ အဘယ်ကြောင့် ကောက်ခံရသနည်း။'
          : `3. Why do we collect the above defined Personal Information?`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="black"
        fontSize={12}
        fontFamily="Inter,Regular"
        fontWeight="500"
        sx={{ marginBottom: '20px' }}
      >
        {language === 'MM'
          ? 'ရွှေအမှိုက် Digital Platfrom ကို အသုံးပြုတဲ့အခါ၊ ကျွန်ုပ်တို့က အသုံးပြုသူနှင့် အချက်အလက်သုံးစွဲမှုများကို စုဆောင်းထားပါသည်။ ဤအချက်အလက်များက ရွှေအမှိုက် Digital Platform အပေါ် အသုံးပြုခြင်းအတွေ့အကြုံကို မြှင့်တင်ဖို့အတွက် ကျွန်ုပ်တို့ကို နည်းလမ်းများစွာဖြင့် ကူညီပေးနိုင်ပါသည်။ ထို့အပြင်စီစဉ်သူများ၏ ရုံးတွင်းဆိုင်ရာ စိစစ်ရန်အတွက် ထုတ်နှုတ်ထားသော စုပေါင်းထားသည့်အချက်အလက်များပါဝင်သည့် အခြားသူများ၏ကိုယ်ရေးကိုယ်တာ အကြောင်းအချက်အလက်များအား ကိုးကားထားခြင်းမရှိသော အချက်အလက်များကို ရုံးတွင်းစီစစ်ရန်စီစဉ်သူများမှာ ထုတ်နှုတ်မည်ဖြစ်သည်။ ကျွန်ုပ်တို့သည် သင့်ကိုယ်ရေးကိုယ်တာအချက်အလက်များကို အခြားမည်သူနှင့်မျှ ဝေမျှမည်မဟုတ်ပါ။ '
          : `When you use this digital platform, we collect some data relating to the
        user and usage activity. This information helps us in many ways to
        improve your experience of using the digital platform. Additionally,
        aggregated data that contains no reference to any individual personal
        information may be extracted for internal analysis by the Organizers. We
        do not share your personal information with anyone else.`}
      </Typography>

      <Typography
        variant="h6"
        className={classes.title}
        fontSize={16}
        color="#5C5C5C"
        fontFamily="Inter,Bold"
        fontWeight="650"
        gutterBottom
      >
        {language === 'MM' ? '၄။ လုံခြုံမှု ' : `4. Security`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="black"
        fontSize={12}
        fontFamily="Inter,Regular"
        fontWeight="500"
        sx={{ marginBottom: '20px' }}
      >
        {language === 'MM'
          ? 'ကျွန်ုပ်တို့၏ Digital Platform အသုံးပြုသူများအား အလွန်လုံခြုံပြီး အာမခံချက်အပြည့်အဝ ရှိသော အတွေ့အကြုံကိုပေးရန်အတွက် ကမ္ဘာတစ်ဝှမ်းတွင် နောက်ဆုံးပေါ် ရေပန်းစားသောနည်းပညာများကို အသုံးပြုထားပါသည်။ သို့သော် download ပြုလုပ်ရန် သင်အသုံးပြုခဲ့သည့် တခြား digital platform နှင့် ယခု digital platform ကို အသုံးပြုပြီးသင်ဝင်ရောက်မည့် တခြားနေရာများရှိနိုင်ခြင်းကြောင့် သင် ကိုယ်ရေးကိုယ်တာလျှိ့ဝှက်ချက်ကို ကျွန်ုပ်တို့အပြည့်အဝတာဝန်ယူထားနိုင်မည်မဟုတ်ပါ။ '
          : `We use modern, globally popular technologies to give our users a very
        safe and secure experience on the digital platform. However, we are not
        responsible in any way for your privacy on all other digital properties
        from where you may come from to download and use this digital platform
        or where you may go to after using this digital platform.`}
      </Typography>

      <Typography
        variant="h6"
        className={classes.title}
        fontSize={16}
        color="#5C5C5C"
        fontFamily="Inter,Bold"
        fontWeight="650"
        gutterBottom
      >
        {language === 'MM'
          ? '၅။ အထွေထွေ ရှင်းလင်းချက်'
          : `5. General Disclaimer`}
      </Typography>
      <Typography
        variant="subtitle2"
        color="black"
        fontSize={12}
        fontFamily="Inter,Regular"
        fontWeight="500"
        sx={{ marginBottom: '20px' }}
      >
        {language === 'MM'
          ? 'ရွှေအမှိုက် Digital Platform ကို အိန္ဒိယမှ ပုံဖော်ပြီး စီမံခန့်ခွဲပါသည်။ ရွှေအမှိုက် Digital Platform ကို တခြားထိန်းချုပ်ထားသောနေရာတစ်ခုမှ ဝင်ရောက်မည် ဆိုပါက သင်သည် ဌာနေမှ ချမှတ်ထားသော ဥပဒေများကိုလိုက်နာရန် အပြည့်အဝတာဝန်ရှိမည် ဖြစ်သည်။'
          : `The Waste to Wealth digital platform is hosted and made available from India. In
        the event you access Waste to Wealth digital platform from other jurisdictions, you
        are solely responsible for complying with the local law as it may be
        applicable.`}
      </Typography>
    </Box>
  );
}

export default TermsOfUse;
