/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { Paper, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Flag from 'react-world-flags';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from '../store/reducers/auth';

function LanguageSelect() {
  const { language } = useSelector((state) => state.auth);
  const [showDropDown, setShowDropDown] = useState(false);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const handleChange = () => {
    dispatch(setLanguage(language === 'EN' ? 'MM' : 'EN'));
    i18n.changeLanguage(language === 'EN' ? 'MM' : 'EN');
    localStorage.setItem('language', language === 'EN' ? 'MM' : 'EN'); // Store the selected language

    setShowDropDown(!showDropDown);
  };

  return (
    <Box display="flex" flexDirection="column">
      <div style={{ position: 'relative' }}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: 2,
            py: 0.6,
            pl: 1.5,
            pr: 0.5,
            width: 145,

            position: 'relative',
            zIndex: 800,
            backgroundColor: 'white',
            cursor: 'pointer',
          }}
          onClick={() => setShowDropDown(!showDropDown)}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            color="#368C36"
            fontWeight={600}
          >
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              sx={{ fontSize: language === 'EN' ? 16 : 14 }}
            >
              <Flag
                height={language === 'EN' ? 12 : 16}
                code={language === 'EN' ? 'GB' : 'MM'}
              />
              {language === 'EN' ? 'English' : 'မြန်မာ'}
            </Box>
            <KeyboardArrowDownIcon sx={{ color: 'gray' }} />
          </Box>
        </Paper>
        <Paper
          elevation={2}
          sx={{
            borderRadius: 2,
            mt: -1.2,
            pt: 1.7,
            pl: 1.5,
            pr: 0.5,
            pb: 0.7,
            width: 145,
            position: 'absolute',
            zIndex: 100,
            top: 32,
            left: 0,
            backgroundColor: 'white',
            // Adjust this value to control the vertical position
            // Transition for smooth dropdown effect
            transition: 'transform 0.3s ease-in-out',
            transform: showDropDown ? 'translateY(5%)' : 'translateY(-15%)',
            visibility: showDropDown ? 'visible' : 'hidden', // Manage visibility
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleChange}
            sx={{ cursor: 'pointer', color: '#4A4A4A' }}
          >
            <Box
              display="flex"
              alignItems="center"
              fontWeight={600}
              sx={{ fontSize: language === 'EN' ? 14 : 16 }}
            >
              {' '}
              <Flag
                height={language === 'EN' ? 16 : 12}
                code={language === 'EN' ? 'MM' : 'GB'}
                style={{ marginRight: '8px' }}
              />
              {language === 'EN' ? 'မြန်မာ' : 'English'}{' '}
            </Box>
          </Box>
        </Paper>
      </div>
    </Box>
  );
}

export default LanguageSelect;
