const checkTopLevelCategoryExists = (name, language, categories) => {
  const nameKey = language === 'EN' ? 'CategoryName_EN' : 'CategoryName_MM';
  const lowerCaseNameToCheck = name.toLowerCase();

  return categories.some(
    (category) =>
      category.ParentCategoryID === null &&
      category[nameKey]?.toLowerCase() === lowerCaseNameToCheck
  );
};

export default checkTopLevelCategoryExists;
