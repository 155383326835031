/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-console */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { toast } from 'react-toastify';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useStyles from '../CreateAccount/style';
import delilveryTruck from '../../assets/images/Set delivery status icon.png';
import siteInspectionStatus from '../../assets/images/Set site inspection status icon.png';
import uploadImage from '../../assets/images/Upload icon.png';
import PreviewListing from '../../assets/images/Preview listing icon.png';
import DraftListing from '../../assets/images/Draft listing saved.png';
import closeIcon from '../../assets/images/Cross with gray border.png';
import DraftListingDisabled from '../../assets/images/Save draft listing icon.png';

import { useGetCategoriesQuery } from '../../store/middlewares/categoriesApi';
import {
  useDeleteListingImageMutation,
  useFindListingByIdQuery,
  useUpdateListingMutation,
} from '../../store/middlewares/listingsApi';
import { setIsPreview } from '../../store/reducers/listing';
import checkTopLevelCategoryExists from '../../utils/checkTopLevelCategoriesExist';
import checkSubCategoryExists from '../../utils/checkCategoriesExsits';
import {
  buyerSiteInspection,
  buyerPickUpStatus,
  sellerDeliveryStatus,
  sellerSiteInspection,
} from '../AddSellerListing/statusArrays';
import TitleHeader from '../../components/TitleHeader';
import Loader from '../../components/Loader';
import AnimatedProgress from '../../components/SuccessAnimation';
import DetectLocation from '../../components/DetectLocation';
import Categories from '../../components/Categories';
import MyDialog from '../../components/PreviewCard';
import NoImageUploaded from '../../assets/images/NoImageUploaded.png';
import WebHeader from '../../components/WebHeader';

function EditListing() {
  const { currentListing, currentListingId } = useSelector(
    (state) => state.listing
  );
  const isMobile = useMediaQuery('(max-width:600px)');

  const { t } = useTranslation();

  const [imagesToBeDeleted, setImagesToBeDeleted] = useState([]);

  const {
    data: listing,
    isLoading: listingLoding,
    isError: listingError,
    error,
    refetch,
  } = useFindListingByIdQuery(currentListingId);
  useEffect(() => {
    window.scrollTo(0, 0);
    refetch();
  }, [refetch]);

  const dispatch = useDispatch();
  const {
    data: categories,
    isError,
    isLoading,
    refetch: categoryRefetch,
  } = useGetCategoriesQuery();

  const [
    updateListing,
    { isError: listingAddError, isSuccess, addListingLoading },
  ] = useUpdateListingMutation();

  const [
    deleteListingImage,
    { isSuccess: imageDeleteSuccess, isError: imageDeleteError },
  ] = useDeleteListingImageMutation();
  const navigate = useNavigate();
  const { listingType, fontColor, color } = useSelector(
    (state) => state.listing
  );
  const { language } = useSelector((state) => state.auth);

  const [showAnimation, setShowAnimation] = useState(false);
  const [imageFiles, setImageFiles] = useState(
    listing?.Images?.length > 0 ? listing?.Images?.split(',') : []
  ); // State for storing actual file objects
  const [imagePreviews, setImagePreviews] = useState(
    listing?.Images?.length > 0 ? listing?.Images?.split(',') : []
  ); // State for storing preview URLs

  const [errors, setErrors] = useState({});

  const [fixedPrice, setFixedPrice] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [savedAsDraft, setSavedAsDraft] = useState(false);

  useEffect(() => {
    categoryRefetch();
  }, [categoryRefetch]);

  useEffect(() => {
    if (listing) {
      setFormValues({
        CategoryID: listing?.CategoryID ?? '',
        SubCategoryId: listing?.SubCategoryID ?? '',
        customCategory: false,
        customSubCategory: false,
        CustomCategoryValue: '',
        CustomSubCategoryValue: '',
        Quantity: listing?.Quantity ?? '',
        Unit: listing?.Unit ?? '',
        PriceMin: listing?.PriceMin ?? '',
        PriceMax: listing?.PriceMax ?? '',
        Township: listing?.Township ?? '',
        City: listing?.City ?? '',
        DeliveryStatus: listing?.DeliveryStatus ?? '',
        SiteInspectionStatus: listing?.SiteInspectionStatus ?? '',
        Description: listing?.Description ?? '',
        ListingStatus: listing?.ListingStatus ?? '',
        ListingType: listing?.ListingType ?? '',
        Longitude: listing?.Longitude ?? null,
        Latitude: listing?.Latitude ?? null,
      });

      setImageFiles(
        listing?.Images?.length > 0 ? listing?.Images?.split(',') : []
      );
      setImagePreviews(
        listing?.Images?.length > 0 ? listing?.Images?.split(',') : []
      );
    }
  }, [listing]);

  const classes = useStyles();

  // Handle loading state
  if (isLoading || listingLoding) {
    return <Loader />;
  }
  if (isError && error && listingError) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '90vh',
        }}
      >
        There was an error fetching this listing, please try again later
      </div>
    );
  }

  const deliveryStatus =
    listing?.ListingType === 'Seller'
      ? sellerDeliveryStatus
      : buyerPickUpStatus;
  const siteInspection =
    listing?.ListingType === 'Seller'
      ? sellerSiteInspection
      : buyerSiteInspection;

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    if (name === 'CategoryID' && event.target.value !== 'Other') {
      setFormValues({
        ...formValues,
        customCategory: false,
        customSubCategory: false,
        SubCategoryId: '',
        [name]: value,
      });
      return;
    }
    if (name === 'CategoryID' && event.target.value === 'Other') {
      setFormValues({
        ...formValues,
        customCategory: true,
        CategoryID: 'Other',
        customSubCategory: true,
        SubCategoryId: 'Other',
      });
      return;
    }

    if (name === 'SubCategoryId' && event.target.value !== 'Other') {
      setFormValues({
        ...formValues,
        customSubCategory: false,
        [name]: value,
      });
      return;
    }

    if (name === 'SubCategoryId' && event.target.value === 'Other') {
      setFormValues({
        ...formValues,
        customSubCategory: true,
        SubCategoryId: 'Other',
      });
      return;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleImageChange = (event) => {
    if (event.target.files.length + imageFiles.length > 4) {
      toast.error(`${t('Notifications.YouCanUploadFour')}`);
      return;
    }

    const newFilesArray = Array.from(event.target.files);
    const validFormats = [
      'image/jpeg',
      'image/png',
      'image/bmp',
      'image/svg+xml',
      'image/webp',
    ];
    let isValid = true;
    const invalidFileMessages = [];

    const newImagePreviews = newFilesArray
      .map((file) => {
        if (file.size > 2097152) {
          invalidFileMessages.push(
            `${t('Notifications.FileSizeShouldNot')} ${file.name}`
          );
          isValid = false;
          return null;
        }

        if (!validFormats.includes(file.type)) {
          invalidFileMessages.push(
            `${t('Notifications.InvalidFileFormat')} ${file.name}`
          );
          isValid = false;
          return null;
        }

        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      })
      .filter((file) => file !== null);

    if (!isValid) {
      // Show a single toast with all error messages, or individual toasts for each error
      toast.error(invalidFileMessages.join('\n'));
      return;
    }

    setImageFiles((prevFiles) => [...prevFiles, ...newFilesArray]);
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newImagePreviews]);
    setErrors((prevErrors) => ({ ...prevErrors, images: '' }));
  };

  // Handle image removal
  const handleRemoveImage = (imageIndex) => {
    const previewUrlToRemove = imagePreviews[imageIndex];
    if (typeof previewUrlToRemove === 'string') {
      const imageUrl = encodeURIComponent(previewUrlToRemove);

      try {
        const imageDelete = deleteListingImage({
          listingId: listing.ListingID,
          imageUrl,
        });
      } catch (error) {
        toast.error(`${t('Notifications.ErrorRemovingImageFromDatabase')}`);
        return;
      }
    }

    setImagePreviews((prevPreviews) =>
      prevPreviews.filter((_, index) => index !== imageIndex)
    );
    setImageFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== imageIndex)
    );
    URL.revokeObjectURL(previewUrlToRemove);
  };

  // Form validation function
  const validate = (values) => {
    const tempErrors = {};
    if (!values.customCategory) {
      if (!values.CategoryID) tempErrors.CategoryID = 'Category is required';
    }
    if (!values.customSubCategory) {
      if (!values.SubCategoryId)
        tempErrors.SubCategoryId = 'Sub category is required';
    }

    if (!values.Quantity) tempErrors.Quantity = 'Quantity is required';
    if (!values.Unit) tempErrors.Unit = 'Unit is required';
    if (!values.PriceMin)
      tempErrors.PriceMin = 'Starting price range is required';
    if (!values.PriceMax)
      tempErrors.PriceMax = 'Ending price range is required';
    if (values.customCategory && !values.CustomCategoryValue) {
      tempErrors.CustomCategoryValue = 'Custom Category is required';
    }
    if (values.customSubCategory && !values.CustomSubCategoryValue) {
      tempErrors.CustomSubCategoryValue = 'Custom Category is required';
    }
    if (!values.Township) tempErrors.Township = 'Township is required';
    if (!values.City) tempErrors.City = 'City is required';
    if (
      (!imageFiles || imageFiles.length === 0) &&
      listing?.ListingType !== 'Buyer'
    )
      tempErrors.images = 'At least one image is required';

    // Add more validation checks as needed

    return tempErrors;
  };

  // Handle form submission
  const handleSubmit = async (event, status) => {
    event.preventDefault();
    const tempErrors = validate(formValues);
    if (status === 'Published') {
      setErrors(tempErrors);
    }
    // checking for category duplicacy
    if (
      formValues?.customCategory &&
      checkTopLevelCategoryExists(
        formValues?.CustomCategoryValue,
        language,
        categories
      )
    ) {
      toast.error(`${t('Notifications.CategoryExists')}`);
      return;
    }
    // checking for subcategory duplicacy
    if (
      !formValues?.customCategory &&
      formValues?.customSubCategory &&
      checkSubCategoryExists(
        formValues?.CategoryID,
        formValues?.CustomSubCategoryValue,
        categories,
        language
      )
    ) {
      toast.error(`${t('Notifications.SubCategoryExists')}`);
      return;
    }

    if (status === 'Draft' || Object.keys(tempErrors).length === 0) {
      // No errors, submit the form

      const formValuesCopy = {
        ...formValues,
        PriceMax: fixedPrice ? formValues?.PriceMin : formValues?.PriceMax,
        ListingStatus: status,
        LanguageId: language,
        SiteInspectionStatus:
          formValues.SiteInspectionStatus === 'Set Site Inspection Status'
            ? listingType === 'Seller'
              ? 'Site Inspection Available'
              : 'Site Inspection Needed'
            : formValues.SiteInspectionStatus,
        DeliveryStatus:
          formValues.DeliveryStatus === 'Set Delivery Status'
            ? listingType === 'Seller'
              ? 'Delivery Available'
              : 'Pickup Possible'
            : formValues.DeliveryStatus,
      };

      const formData = new FormData();

      // Append regular fields to formData
      Object.keys(formValuesCopy).forEach((key) => {
        if (key !== 'imageFiles') {
          formData.append(key, formValuesCopy[key]);
        }
      });

      const imageFilesCopy = imageFiles?.filter(
        (img) => typeof img !== 'string'
      );

      // Append image files to formData
      imageFilesCopy.forEach((file) => {
        formData.append('images', file); // 'images' should match the name expected by multer
      });

      try {
        await updateListing({
          listingId: listing.ListingID,
          updatedListing: formData,
        }).unwrap();
        if (status !== 'Draft') {
          setShowAnimation(true);
          toast.success(`${t('Notifications.ListingUpdatedSuccessfully')}`);
          navigate('/listings/my-listings');
        } else {
          setSavedAsDraft(true);
          setTimeout(() => {
            setSavedAsDraft(false);
          }, 3000);
        }

        // Handle success - maybe show a success message or redirect
      } catch (error) {
        // Handle the error - maybe show an error message  console.log(error);

        toast.error(error?.data?.message);
      }
      // Submit your form values to backend here
    } else {
      toast.warning(`${t('Notifications.PleaseFillAllFields')}`);
    }
  };

  const units = ['kg', 'pieces', 'Viss', 'ton'];

  const handlePreview = () => {
    dispatch(setIsPreview(true));
  };

  return (
    <>
      {' '}
      {!isMobile && <WebHeader />}
      {!isMobile && (
        <Typography
          variant="subtitle2"
          sx={{
            color: '#5C5C5C',
            fontSize: 12,
            fontWeight: 600,

            display: 'flex',
            alignItems: 'center',
            ml: '6%',
          }}
          gutterBottom
        >
          {listingType} Listings <KeyboardArrowRightIcon /> Edit {listingType}{' '}
          &nbsp;Listing
        </Typography>
      )}
      <Container maxWidth="md" sx={{ mt: isMobile ? 8 : 3, px: 2, pb: 8 }}>
        {isMobile ? (
          <TitleHeader
            title={t(`EditListing.Edit ${listing?.ListingType} Listing`)}
            color={fontColor}
          />
        ) : (
          <Typography sx={{ color: fontColor, fontSize: 19, fontWeight: 700 }}>
            {t(`EditListing.Edit ${listing?.ListingType} Listing`)}
          </Typography>
        )}
        <Typography
          align="right"
          sx={{ color: '#66492E', fontSize: 12, fontWeight: 600 }}
        >
          * {t('AddListing.Denotes')}
        </Typography>
        <Categories
          formValues={formValues}
          errors={errors}
          isLoading={isLoading}
          isError={isError}
          categories={categories}
          handleInputChange={handleInputChange}
        />
        {/* Quantity and Unit Select Input */}
        <Grid container>
          <Grid item xs={12} md={5}>
            <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
              <FormControl fullWidth>
                <FormHelperText
                  sx={{
                    ml: 1,
                    color: '#66492E',
                    fontWeight: '600',
                    fontFamily: 'Inter,Medium',
                    fontSize: '14px',
                  }}
                >
                  {t('AddListing.SetQuantity')}*
                </FormHelperText>
                <OutlinedInput
                  className={classes.paperCustom}
                  size="small"
                  type="number"
                  name="Quantity"
                  value={formValues?.Quantity ?? ''}
                  onChange={handleInputChange}
                  error={!!errors.Quantity}
                />
              </FormControl>
              <FormControl fullWidth>
                <FormHelperText
                  sx={{
                    ml: 1,
                    color: '#66492E',
                    fontWeight: '600',
                    fontFamily: 'Inter,Medium',
                    fontSize: '14px',
                  }}
                >
                  {t('AddListing.SetUnit')}*
                </FormHelperText>
                <Select
                  size="small"
                  name="Unit"
                  value={formValues?.Unit ?? ''}
                  onChange={handleInputChange}
                  error={!!errors.Unit}
                  className={classes.paperCustom}
                  IconComponent={KeyboardArrowDownIcon} // Custom dropdown icon
                >
                  {/* Replace these with actual units */}
                  {units?.map((unit) => (
                    <MenuItem
                      value={unit}
                      key={unit}
                      sx={{
                        color: '#4A4A4A',
                        fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                        fontWeight: 600,
                        fontSize: '16px', // Adjust the font size as needed
                        padding: '10px 16px', // Adjust padding as needed
                        borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                        '&:last-child': {
                          borderBottom: 'none', // Remove border for the last item
                        },
                      }}
                    >
                      {unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          {!isMobile && <Grid item md={2} />}
          <Grid item xs={12} md={5}>
            {/* Price Range Input */}
            <Box sx={{ gap: '8px', mb: 2 }}>
              <FormHelperText
                sx={{
                  ml: 1,
                  color: '#66492E',
                  fontWeight: '600',
                  fontFamily: 'Inter,Medium',
                  fontSize: '14px',
                }}
              >
                {t('AddListing.SetPriceRange')} (KS.)*
              </FormHelperText>
              <Box display="flex" gap="5px" alignItems="center">
                <FormControl>
                  {/* <Box display="flex" gap="7px" alignItems="center"> */}

                  <OutlinedInput
                    className={classes.paperCustom}
                    size="small"
                    type="number"
                    name="PriceMin"
                    value={formValues?.PriceMin ?? ''}
                    onChange={handleInputChange}
                    error={!!errors.PriceMin}
                  />
                </FormControl>
                <Typography
                  sx={{ display: fixedPrice ? 'none' : 'block' }}
                  fontWeight={700}
                >
                  -
                </Typography>
                <FormControl sx={{ display: fixedPrice ? 'none' : 'block' }}>
                  <OutlinedInput
                    className={classes.paperCustom}
                    size="small"
                    name="PriceMax"
                    type="number"
                    value={formValues?.PriceMax ?? ''}
                    onChange={handleInputChange}
                    error={!!errors.PriceMax}
                  />
                </FormControl>
                <Typography fontWeight={700}>/</Typography>

                <OutlinedInput
                  className={classes.paperCustom}
                  size="small"
                  name="Unit"
                  value={formValues?.Unit ?? ''}
                  sx={{ width: '35%' }}
                />
              </Box>

              <FormControlLabel
                control={
                  <Checkbox
                    name="terms"
                    value={fixedPrice}
                    onChange={() => setFixedPrice(!fixedPrice)}
                    color="primary"
                    sx={{
                      ml: 0.5,
                      '& .MuiSvgIcon-root': { fontSize: 26 },
                      '&.Mui-checked': {
                        color: 'success',
                      },
                      '& .MuiIconButton-root': {
                        color: 'warning', // color when unchecked
                        '&:not(.Mui-checked)': {
                          borderColor: 'warning', // border color when unchecked
                        },
                      },
                    }}
                  />
                }
                label={
                  <Typography fontSize="14px" fontWeight={600} color="#66492E">
                    {t('AddListing.FixedPrice')}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5}>
            <DetectLocation
              setFormValues={setFormValues}
              formValues={formValues}
              errors={errors}
              setErrors={setErrors}
            />
          </Grid>
          {!isMobile && <Grid item md={2} />}
          <Grid item xs={12} md={5}>
            <FormControl
              variant="outlined"
              sx={{
                mb: 2,
                minWidth: 150,
                mt: isMobile ? 0 : 3,

                '& .MuiInput-underline:before': {
                  borderBottom: 'none',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: 'none',
                },
              }}
              size="small"
            >
              <Select
                className={classes.paperCustom}
                labelId="deliveryStatus"
                id="deliveryStatus"
                name="DeliveryStatus"
                value={formValues?.DeliveryStatus ?? ''}
                onChange={handleInputChange}
                error={!!errors.DeliveryStatus}
                inputProps={{
                  'aria-label': 'Without label',
                  fontWeight: 'bold',
                }}
                IconComponent={KeyboardArrowDownIcon} // Custom dropdown icon
                input={
                  <Input
                    sx={{ borderRadius: 6 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={delilveryTruck}
                          width="20"
                          alt="Delivery Truck"
                          style={{ paddingBottom: '4px' }}
                        />
                      </InputAdornment>
                    }
                  />
                }
                sx={{
                  px: 1,
                  pt: 0.8,
                  pb: 0.5,

                  fontWeight: 600,

                  color: fontColor,
                  fontSize: 15,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: 3,
                }}
              >
                {/* You'll need to replace these with actual country codes and flags */}
                {deliveryStatus?.map((status) => (
                  <MenuItem
                    sx={{
                      color: '#4A4A4A',
                      fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                      fontWeight: 600,
                      fontSize: '15px', // Adjust the font size as needed
                      padding: '10px 16px', // Adjust padding as needed
                      borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                      '&:last-child': {
                        borderBottom: 'none', // Remove border for the last item
                      },
                    }}
                    key={status}
                    value={status}
                  >
                    {t(`${status}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              variant="outlined"
              sx={{
                mb: 2,
                minWidth: 150,
                display: 'block',
                '& .MuiInput-underline:before': {
                  borderBottom: 'none',
                },
                '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                  borderBottom: 'none',
                },
              }}
              size="small"
            >
              <Select
                className={classes.paperCustom}
                labelId="inspectionStatus"
                id="inspectionStatus"
                name="SiteInspectionStatus"
                value={formValues?.SiteInspectionStatus ?? ''}
                onChange={handleInputChange}
                error={!!errors.SiteInspectionStatus}
                inputProps={{
                  'aria-label': 'Without label',
                  fontWeight: 'bold',
                }}
                IconComponent={KeyboardArrowDownIcon} // Custom dropdown icon
                input={
                  <Input
                    sx={{ borderRadius: 6 }}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          src={siteInspectionStatus}
                          width="20"
                          style={{ paddingBottom: '4px' }}
                          alt="Site Inspection Status"
                        />
                      </InputAdornment>
                    }
                  />
                }
                sx={{
                  px: 1,
                  pt: 0.8,
                  pb: 0.5,

                  fontWeight: 600,

                  color: fontColor,
                  fontSize: 15,
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  borderRadius: 3,
                }}
              >
                {/* You'll need to replace these with actual country codes and flags */}
                {siteInspection?.map((status) => (
                  <MenuItem
                    sx={{
                      color: '#4A4A4A',
                      fontFamily: 'Inter, Regular', // Make sure you have this font available in your project
                      fontWeight: 600,
                      fontSize: '15px', // Adjust the font size as needed
                      padding: '10px 16px', // Adjust padding as needed
                      borderBottom: '1px solid #E0E0E0', // Border color as seen in the UI
                      '&:last-child': {
                        borderBottom: 'none', // Remove border for the last item
                      },
                    }}
                    key={`${status}a`}
                    value={status}
                  >
                    {t(`${status}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* Image Upload Input */}
        <Grid container>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Button
                variant="contained"
                component="label"
                sx={{
                  mb: 1,
                  fontWeight: '600',
                  fontSize: 15,
                  fontFamily: 'Inter,SemiBold',
                  display: 'flex',
                  gap: '10px',
                  backgroundColor:
                    listing?.ListingType === 'Seller' ? '#5BA15B' : '#EB9D28',
                  '&:active': {
                    // Active state styles
                    backgroundColor: fontColor, // Darker background color for active state
                    borderColor: color, // Optionally change border color for active state
                  },
                }}
              >
                <img src={uploadImage} alt="upload" width="20" />
                {t('AddListing.UploadImages')}
                <input
                  type="file"
                  hidden
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>

              {errors.images && (
                <Box sx={{ color: 'error.main' }}>{errors.images}</Box>
              )}
            </FormControl>

            <Typography
              fontSize={12}
              variant="subtitle2"
              fontWeight={600}
              color="#707070"
            >
              {t('AddListing.MinimunOne')}
            </Typography>
            <Typography
              variant="subtitle2"
              fontWeight={600}
              fontSize={12}
              color="#707070"
            >
              {t('AddListing.Accepted')}
            </Typography>
            <Typography
              fontSize={12}
              variant="subtitle2"
              fontWeight={600}
              color="#707070"
              sx={{ mb: 2 }}
            >
              {t('AddListing.MaxSize')}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
                mb: imagePreviews.length > 0 ? 2 : 0,
              }}
            >
              {imagePreviews?.map((url, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Card key={index} sx={{ width: 162, position: 'relative' }}>
                  <CardMedia
                    component="img"
                    image={typeof url === 'string' ? url : url.preview}
                    alt={`image-${index}`}
                    sx={{ width: '100%', height: 150 }}
                  />
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveImage(index)}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                  >
                    <img src={closeIcon} alt="remove icon" width="20px" />
                  </IconButton>
                </Card>
              ))}
            </Box>
          </Grid>
          {!isMobile && <Grid item md={2} />}
          <Grid item xs={12} md={5}>
            {/* Description Text Area */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <FormHelperText
                sx={{
                  ml: 1,
                  color: '#66492E',
                  fontWeight: '600',
                  fontFamily: 'Inter,Medium',
                  fontSize: '14px',
                }}
              >
                {t('AddListing.Description')}{' '}
              </FormHelperText>
              <OutlinedInput
                className={classes.paperCustom}
                size="small"
                multiline
                rows={isMobile ? 4 : 8}
                name="Description"
                value={formValues?.Description ?? ''}
                onChange={handleInputChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        {isMobile ? (
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '15px',
            }}
          >
            <Button
              onClick={handlePreview}
              sx={{
                backgroundColor: 'white',
                borderColor: 'white',
                color: '#707070',
                fontSize: 13,
                wordWrap: 'normal',
                whiteSpace: 'nowrap',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',

                borderRadius: 5,
                fontWeight: 600,
                py: 1.2,
                px: 3,
              }}
            >
              <Box
                component="img"
                width={18}
                src={PreviewListing}
                alt="Listing Icon"
                sx={{ mr: 1 }}
              />
              {t('AddListing.PreviewListing')}
            </Button>
            <Button
              onClick={(e) => handleSubmit(e, 'Draft')}
              sx={{
                backgroundColor: 'white',
                borderColor: 'white',
                color: savedAsDraft ? fontColor : '#707070',
                fontSize: 13,

                wordWrap: 'normal',
                whiteSpace: 'nowrap',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',

                borderRadius: 5,
                fontWeight: 600,
                py: 1.2,
                px: 4,
              }}
            >
              {' '}
              <Box
                component="img"
                width={18}
                src={savedAsDraft ? DraftListing : DraftListingDisabled}
                alt="Listing Icon"
                sx={{ mr: 1, color: '#707070' }}
              />
              {savedAsDraft
                ? t('AddListing.DraftSaved')
                : t('AddListing.SaveDraft')}
            </Button>
          </Toolbar>
        ) : (
          <Grid container>
            <Grid item md={5} />
            <Grid item md={2} />
            <Grid item md={5}>
              <Toolbar sx={{ px: 0 }} style={{ padding: 0 }}>
                <Button
                  onClick={handlePreview}
                  sx={{
                    backgroundColor: 'white',
                    borderColor: 'white',
                    color: '#707070',
                    fontSize: 13,
                    wordWrap: 'normal',
                    whiteSpace: 'nowrap',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',

                    fontWeight: 600,
                    py: 1.2,

                    width: '100%',
                  }}
                >
                  <Box
                    component="img"
                    width={18}
                    src={PreviewListing}
                    alt="Listing Icon"
                    sx={{ mr: 1 }}
                  />
                  {t('AddListing.PreviewListing')}
                </Button>
              </Toolbar>
              <Toolbar sx={{ px: 0 }} style={{ padding: 0 }}>
                <Button
                  onClick={(e) => handleSubmit(e, 'Draft')}
                  sx={{
                    backgroundColor: 'white',
                    borderColor: 'white',
                    color: savedAsDraft ? fontColor : '#707070',
                    fontSize: 13,

                    wordWrap: 'normal',
                    whiteSpace: 'nowrap',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',

                    fontWeight: 600,
                    py: 1.2,

                    width: '100%',
                  }}
                >
                  {' '}
                  <Box
                    component="img"
                    width={18}
                    src={savedAsDraft ? DraftListing : DraftListingDisabled}
                    alt="Listing Icon"
                    sx={{ mr: 1, color: '#707070' }}
                  />
                  {savedAsDraft
                    ? t('AddListing.DraftSaved')
                    : t('AddListing.SaveDraft')}
                </Button>
              </Toolbar>
            </Grid>
          </Grid>
        )}
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Button
            variant="contained"
            component="label"
            sx={{
              mb: 1,
              fontWeight: '600',
              fontSize: 15,
              fontFamily: 'Inter,SemiBold',
              display: 'flex',
              gap: '10px',
              backgroundColor:
                listing?.ListingType === 'Seller' ? '#5BA15B' : '#EB9D28',
            }}
            onClick={(e) => handleSubmit(e, 'Published')}
          >
            {listing?.ListingStatus === 'Published'
              ? t('EditListing.Update')
              : t('AddListing.Publish')}{' '}
          </Button>
        </FormControl>
        <MyDialog
          listing={formValues}
          images={imageFiles}
          categories={categories}
        />
      </Container>
    </>
  );
}

export default EditListing;
