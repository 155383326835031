import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    flexGrow: 1,

    backgroundColor: theme.palette.background.default,
    padding: 20,
    [theme.breakpoints.up('lg')]: {
      padding: '1em 25em',
    },
    [theme.breakpoints.up('md')]: {
      padding: '1em 15em',
    },
  },
  paperCustom: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Adjust the values as needed
    backgroundColor: 'white',
  },
}));

export default useStyles;
