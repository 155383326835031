import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './reducers/auth';

import listingReducer from './reducers/listing';
import sellerListingReducer from './reducers/sellerListings';
import buyerListingReducer from './reducers/buyerListings';
import chatReducer from './reducers/chat';
// Import your API slices
import { userApi } from './middlewares/userApi';
import { listingsApi } from './middlewares/listingsApi';
import { categoriesApi } from './middlewares/categoriesApi';
import { errorLogApi } from './middlewares/errorLogApi';
import { listingImageApi } from './middlewares/listingImageApi';
import { notificationsApi } from './middlewares/notifications';
import { messagesApi } from './middlewares/messages';
// ... other imports

const rootReducer = combineReducers({
  // Add your API slices' reducers
  auth: authReducer,
  listing: listingReducer,
  sellerListing: sellerListingReducer,
  buyerListing: buyerListingReducer,
  chat: chatReducer,
  [userApi.reducerPath]: userApi.reducer,
  [listingsApi.reducerPath]: listingsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [errorLogApi.reducerPath]: errorLogApi.reducer,
  [listingImageApi.reducerPath]: listingImageApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [messagesApi.reducerPath]: messagesApi.reducer,

  // ... other reducers
});

export default rootReducer;
