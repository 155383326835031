import { baseApi } from '../baseApi';

export const listingImageApi = baseApi.injectEndpoints({
  reducerPath: 'listingImageApi',
  endpoints: (builder) => ({
    deleteListingImage: builder.mutation({
      query: ({ listingId, imageUrl }) => ({
        url: `/listing-images/${listingId}/${imageUrl}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Listings'], // Invalidate the cache of listings after a listing is deleted
    }),
  }),
});

export const { useDeleteListingImageMutation } = listingImageApi;
