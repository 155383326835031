/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  Box,
  CardContent,
  Typography,
  List,
  ListItem,
  CardMedia,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import formatDateTime from '../utils/formatDateTimeForChat';
import { useFindListingByIdQuery } from '../store/middlewares/listingsApi';
import Loader from './Loader';
import {
  setCurrentListing,
  setCurrentListingId,
} from '../store/reducers/listing';
import ListingRemoved from './ListingRemoved';
import TitleHeader from './TitleHeader';
import WebHeader from './WebHeader';
import ListingRemovedImage from '../assets/images/ListingRemoved.png';

function ChatComponent({ messages = [], currentUserId, selectedListingId }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { newChat } = useSelector((state) => state.chat);
  const { fontColor } = useSelector((state) => state.listing);

  const {
    data: listing,
    isLoading,
    isError,
    error,
  } = useFindListingByIdQuery(selectedListingId);

  useEffect(() => {
    window.scrollTo(0, 900);
    if (!isError && !isLoading) {
      dispatch(setCurrentListing(listing));
      dispatch(setCurrentListingId(listing?.ListingID));
    }
  }, [selectedListingId, listing]);

  if (isLoading) {
    return <Loader />;
  }

  // if (isError && error) {
  //   return (
  //     <Box sx={{ px: 3, my: isMobile ? 8 : 0 }}>
  //       {' '}
  //       {isMobile && (
  //         <TitleHeader title={`Listing Removed`} color={fontColor} />
  //       )}
  //       <ListingRemoved />
  //     </Box>
  //   );
  // }

  return (
    // <Box
    //   sx={{
    //     display: 'flex',
    //     flexDirection: 'column-reverse', // Newest messages at the bottom
    //     height: isMobile ? '100vh' : '90vh', // Adjust the height as needed
    //     overflowY: 'auto', // Scroll for overflow
    //     px: 2,
    //     pb: isMobile ? 1 : 3,
    //   }}
    // >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column-reverse',
        height: isMobile ? '88vh' : '76vh',
        // overflowY: 'scroll',
        overflowX: 'hidden',
        pl: isMobile ? 2 : 4,
        pr: isMobile ? 1 : 2,
        pt: isMobile ? 8 : 4,
        '&::-webkit-scrollbar': {
          width: isMobile ? '5px' : '10px', // Adjust scrollbar width
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: isMobile ? '#D3D3D3' : '#F8F8F8', // Scrollbar track color
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#A8A8A8', // Scrollbar handle color
          borderRadius: '10px', // Scrollbar handle border radius
          border: '1px solid #A8A8A8', // Optional: Scrollbar handle border
        },
      }}
    >
      <List sx={{ mx: 0, px: 0 }}>
        {/* {!newChat && listing?.ListingID !== undefined && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '10px',
              pt: 1,
              pl: 1,
              pr: 0,
              pb: 1,

              maxWidth: '85%',
              marginLeft:
                messages[0]?.ReceiverID === currentUserId ? '1px' : 'auto',
              marginRight:
                messages[0]?.ReceiverID === currentUserId ? 'auto' : '1px',
              border: '2px solid #CECCFF',
              mb:
                messages[0]?.ReceiverID === messages[1]?.ReceiverID ? 0.3 : 0.3,
              borderRadius: 2,
              backgroundColor:
                messages[0]?.ReceiverID === currentUserId ? 'white' : '#9D99FF',
            }}
          >
            <CardMedia
              component="img"
              sx={{
                width: 91,
                minHeight: 55,
                maxHeight: 62,
                borderRadius: 2,
              }}
              image={
                listing?.ListingID === undefined
                  ? ListingRemovedImage
                  : listing?.Images?.split(',')?.[0]
              } // Replace with your image path
              alt="ListingImage"
            />
            <CardContent
              sx={{
                pr: 0,
                pt: 0,
                pb: 0,
                pl: 1,
                flex: '0 1 auto',
                mb: 1,
              }}
              style={{ paddingBottom: '2px' }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  pr: 1,
                  color:
                    messages[0]?.ReceiverID === currentUserId
                      ? '#707070'
                      : 'white',
                }}
              >
                {listing?.Subcategory} - {listing?.Category} listing by{' '}
                {listing?.FullName}
              </Typography>
              <Link
                sx={{
                  fontWeight: 600,
                  lineHeight: 1.2,
                  textDecoration: 'none',
                }}
                href={`${process.env.REACT_APP_URL}/listings/${
                  listing?.ListingType === 'Seller' ? 'seller' : 'buyer'
                }-listing/${listing?.ListingID}`}
                style={{
                  fontSize: '12px',
                  color:
                    messages[0]?.ReceiverID === currentUserId
                      ? '#707070'
                      : 'white',
                  fontWeight: 600,
                }}
              >
                Go to listing details page
              </Link>
            </CardContent>
          </Box>
        )} */}

        {messages?.map((message, index) => (
          <React.Fragment key={message.MessageID}>
            <ListItem
              sx={{
                my: 0,
                py: 0,
                mx: 0,
                px: 0,
                flexDirection: 'column',
                alignItems:
                  message.ReceiverID === currentUserId
                    ? 'flex-end'
                    : 'flex-start', // Align based on the sender
              }}
            >
              <Box
                sx={{
                  borderRadius: '10px', // Rounded corners for the message bubble
                  padding: '10px 10px 10px 10px', // Padding inside the message bubble
                  mb:
                    message.ReceiverID === messages[index + 1]?.ReceiverID
                      ? 0.4
                      : 1,

                  overflowWrap: 'break-word', // Prevent horizontal overflow
                  marginLeft: message.ReceiverID === currentUserId ? 0 : 'auto',
                  marginRight:
                    message.ReceiverID === currentUserId ? 'auto' : 0,
                  backgroundColor:
                    message.ReceiverID === currentUserId ? 'white' : '#9D99FF',
                  maxWidth: '80%',
                  minWidth: '70%',
                  border: '1px solid #9D99FF',
                }}
                style={{ paddingBottom: '10px' }}
              >
                <Typography
                  gutterBottom
                  sx={{
                    color:
                      message.ReceiverID === currentUserId
                        ? '#707070'
                        : 'white',
                    fontSize: '12px',
                    fontWeight: 600,
                  }}
                >
                  {message.MessageText}
                </Typography>
                <Typography
                  align="right"
                  sx={{
                    color:
                      message.ReceiverID === currentUserId
                        ? '#707070'
                        : 'white',
                    fontSize: '9px',
                    fontWeight: 600,
                  }}
                >
                  {formatDateTime(message.CreatedAt)}
                </Typography>
              </Box>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
}

export default ChatComponent;
