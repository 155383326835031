/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar';
import {
  useGetNotificationsQuery,
  useUpdateReadStatusMutation,
} from '../../store/middlewares/notifications';
import Loader from '../../components/Loader';
import ListingsFooter from '../../components/ListingsFooter';
import formatCreatedAt from '../../utils/formatCreatedDate';
import SellerListingIcon from '../../assets/images/Seller lisitng icon.png';
import BuyerListingIcon from '../../assets/images/Buyer listing icon.png';
import {
  setCurrentListingId,
  setCurrentPageFooter,
} from '../../store/reducers/listing';
import ChatGray from '../../assets/images/Conversations icon.png';
import { setChatStatus, setSelectedUserData } from '../../store/reducers/chat';
import ListingDelisted from '../../assets/images/Listing Delisted.png';
import ListingRemoval from '../../assets/images/Listing Removal.png';
import NewBuyerListing from '../../assets/images/Buyer listing notification match.png';
import NewSellerListing from '../../assets/images/Seller listings icon (open).png';
import WebHeader from '../../components/WebHeader';
import { useGetUserChatListingsQuery } from '../../store/middlewares/messages';
import { useGetCategoriesQuery } from '../../store/middlewares/categoriesApi';
import { addItemToFilterArray } from '../../store/reducers/sellerListings';
import { addItemToFilterArrayBuyer } from '../../store/reducers/buyerListings';

function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const {
    data: notifications,
    isLoading,
    isError,
    error,
  } = useGetNotificationsQuery();
  const { t } = useTranslation();

  // load more functionltyy
  const [showAllRead, setShowAllRead] = useState(false);
  const [showAllUnread, setShowAllUnread] = useState(false);

  const { currentPageFooter } = useSelector((state) => state.listing);

  const { data: categories } = useGetCategoriesQuery();

  const { data: userChatListings } = useGetUserChatListingsQuery();
  const [updateReadStatus] = useUpdateReadStatusMutation();

  useEffect(
    () => () => {
      if (currentPageFooter !== 'Notifications') {
        updateReadStatus();
      }
    },
    [currentPageFooter]
  );

  useEffect(() => {
    dispatch(setCurrentPageFooter('Notifications'));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (isError && error) {
    return (
      <Typography>
        {t('Notifications.ThereWasErrorFetchingNotifications')}
      </Typography>
    );
  }

  function doesListingExist(listingIdToCheck) {
    return userChatListings?.some(
      (chatListing) => chatListing.ListingID === listingIdToCheck
    );
  }

  const navigateToListing = (notificationItem) => {
    dispatch(setCurrentListingId(notificationItem.ListingID));
    const listingType =
      notificationItem.ListingType === 'Seller'
        ? 'seller-listing'
        : 'buyer-lisiting';
    navigate(`/listings/${listingType}/${notificationItem.ListingID}`);
  };

  const navigateToMyListings = (notificationItem) => {
    dispatch(setCurrentListingId(notificationItem.ListingID));

    navigate(`/listings/my-listings/${notificationItem.ListingID}`);
  };

  const navigateToChat = (notificationItem) => {
    const lowerCaseListing = notificationItem.ListingType.toLowerCase();

    dispatch(setChatStatus(false));
    const userOwnsListing = doesListingExist(notificationItem.ListingID);
    const path = userOwnsListing
      ? 'my-listings'
      : `${lowerCaseListing}-listing`;

    batch(() => {
      dispatch(
        setSelectedUserData({
          name: notificationItem.OtherUserFullName,
          id: notificationItem.OtherUserID,
          businessName: '',
          selectedListingLink: `${process.env.REACT_APP_URL}/listings/${path}/${notificationItem.ListingID}`,

          selectedListingId: notificationItem.ListingID,
        })
      );

      dispatch(setCurrentListingId(notificationItem.ListingID));
    });

    navigate(`/conversations/chat/${notificationItem.ListingID}`);
  };

  const navigateToBuyerListings = (categoryName) => {
    const categoryMatch = categories?.find(
      (categoryItem) =>
        categoryItem?.CategoryName_EN === categoryName ||
        categoryItem?.CategoryName_MM === categoryName
    );
    if (categoryMatch?.CategoryID) {
      dispatch(addItemToFilterArrayBuyer([categoryMatch?.CategoryID]));
    }
    navigate('/listings/buyer-listings');
  };

  const navigateToSellerListings = (categoryName) => {
    const categoryMatch = categories?.find(
      (categoryItem) =>
        categoryItem?.CategoryName_EN === categoryName ||
        categoryItem?.CategoryName_MM === categoryName
    );
    if (categoryMatch?.CategoryID) {
      dispatch(addItemToFilterArray([categoryMatch?.CategoryID]));
    }
    navigate('/listings/seller-listings');
  };

  const unreadNotifications = notifications
    ?.filter(
      (notification) =>
        notification.IsRead === 0 &&
        notification.NotificationType !== 'Account Suspended'
    )
    ?.slice(0, showAllRead ? notifications?.length - 1 : 8);
  const readNotifications = notifications
    ?.filter(
      (notification) =>
        notification.IsRead === 1 &&
        notification.NotificationType !== 'Account Suspended'
    )
    .slice(0, showAllUnread ? notifications?.length - 1 : 8);

  const renderNotification = (notification) => (
    <Paper
      key={notification.NotificationID}
      elevation={1}
      sx={{
        pt: 1.5,
        mx: isMobile ? 2 : 4,
        pb: 1.5,
        px: 1,
        my: isMobile ? 1 : 2,
        cursor: 'pointer',
        userSelect: 'none',
        display: 'flex',
        gap: '10px',
      }}
    >
      {notification.NotificationType === 'Unpublish Listings Warning' && (
        <>
          <Box
            component="img"
            sx={{
              width: 20,
              height: 20,
              mt: 0.3,
            }}
            src={
              notification?.ListingType === 'Seller'
                ? SellerListingIcon
                : BuyerListingIcon
            }
            alt="Waste to Wealth Logo"
          />

          <Typography
            fontSize={11}
            lineHeight={1.3}
            fontWeight={600}
            color="#6D6D6D"
            onClick={() => navigateToMyListings(notification)}
          >
            <b
              style={{
                textDecoration: 'underline',
                color: '#4A4A4A',
                marginRight: '5px',
              }}
            >
              {'Listing'} {notification.ListingID}
            </b>
            : {notification.NotificationText}
          </Typography>
        </>
      )}
      {notification.NotificationType === 'New Buyer Listing' &&
        notification.ListingType === 'Buyer' && (
          <>
            <Box
              component="img"
              sx={{
                width: 20,
                height: 20,
                mt: 0.3,
              }}
              src={NewBuyerListing}
              alt="Waste to Wealth Logo"
            />

            <Typography
              fontSize={11}
              lineHeight={1.3}
              fontWeight={600}
              color="#6D6D6D"
              onClick={() =>
                navigateToBuyerListings(notification?.CategoryName)
              }
            >
              <b
                style={{
                  textDecoration: 'underline',
                  color: '#4A4A4A',
                  marginRight: '5px',
                }}
              >
                {'Buyer Listings'}
              </b>
              : {notification.NotificationText}
            </Typography>
          </>
        )}

      {notification.NotificationType === 'New Seller Listing' &&
        notification.ListingType === 'Seller' && (
          <>
            <Box
              component="img"
              sx={{
                width: 20,
                height: 20,
                mt: 0.3,
              }}
              src={NewSellerListing}
              alt="Waste to Wealth Logo"
            />

            <Typography
              fontSize={11}
              lineHeight={1.3}
              fontWeight={600}
              color="#6D6D6D"
              onClick={() =>
                navigateToSellerListings(notification?.CategoryName)
              }
            >
              <b
                style={{
                  textDecoration: 'underline',
                  color: '#4A4A4A',
                  marginRight: '5px',
                }}
              >
                {'Seller Listings'}
              </b>
              : {notification.NotificationText}
            </Typography>
          </>
        )}
      {notification.NotificationType === 'Unpublish Listings' && (
        <>
          <Box
            component="img"
            sx={{
              width: 20,
              height: 20,
              mt: 0.3,
            }}
            src={ListingDelisted}
            alt="Waste to Wealth Logo"
          />

          <Typography
            fontSize={11}
            lineHeight={1.3}
            fontWeight={600}
            color="#6D6D6D"
            onClick={() => navigateToMyListings(notification)}
          >
            <b
              style={{
                textDecoration: 'underline',
                color: '#4A4A4A',
                marginRight: '5px',
              }}
            >
              {'Listing'} {notification.ListingID} {'delisted'}
            </b>
            : {notification.NotificationText}
          </Typography>
        </>
      )}
      {notification.NotificationType === 'Reported Listing Removal' && (
        <>
          <Box
            component="img"
            sx={{
              width: 20,
              height: 20,
              mt: 0.3,
            }}
            src={ListingRemoval}
            alt="Waste to Wealth Logo"
          />

          <Typography
            fontSize={11}
            lineHeight={1.3}
            fontWeight={600}
            color="#6D6D6D"
            onClick={() => navigateToListing(notification)}
          >
            <b
              style={{
                // textDecoration: 'underline',
                color: '#4A4A4A',
                marginRight: '5px',
              }}
            >
              {'Listing'} {notification.ListingID} {'removed'}
            </b>
            : {notification.NotificationText}
          </Typography>
        </>
      )}
      {notification.NotificationType === 'Chat Deletion Warning' && (
        <>
          <Box
            component="img"
            sx={{
              width: 20,
              height: 20,
              mt: 0.3,
            }}
            src={ChatGray}
            alt="Chat icon"
          />

          <Typography
            fontSize={11}
            lineHeight={1.3}
            fontWeight={600}
            color="#6D6D6D"
            onClick={() => navigateToChat(notification)}
          >
            <b
              style={{
                textDecoration: 'underline',
                color: '#4A4A4A',
                marginRight: '5px',
              }}
            >
              {'Chat With'} {notification.OtherUserFullName}
            </b>
            :{' '}
            {`Your chat with ${notification.OtherUserFullName} will be deleted for being inactive for 1 year. Send a message?`}
          </Typography>
        </>
      )}
      <Typography
        lineHeight={1.1}
        fontSize={9}
        fontWeight={600}
        color="#707070"
        sx={{ mt: 1, ml: 'auto' }}
        align="right"
      >
        {formatCreatedAt(notification.CreatedAt)}
      </Typography>
    </Paper>
  );

  if (readNotifications?.length === 0 && unreadNotifications === 0) {
    return (
      <Typography
        fontSize={14}
        sx={{ mt: 2, mx: 2 }}
        fontWeight={600}
        color="#4A4A4A"
      >
        {t('Notifications.YouDontHaveAnyNotifications')}
      </Typography>
    );
  }

  return (
    <Box sx={{ pb: isMobile ? 8 : 'auto' }}>
      {isMobile ? (
        <>
          <Box display="flex" justifyContent="flex-end" sx={{ px: 2, pb: 3 }}>
            <Sidebar />
          </Box>

          <Paper
            elevation={1}
            sx={{
              pt: 2,
              px: 2,
              pb: 1.5,
              width: '100%',
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            <Typography fontSize={18} fontWeight={700} color="#4A4A4A">
              {t('Notifications.Unread')}
            </Typography>
          </Paper>
          {unreadNotifications?.map(renderNotification)}
          {unreadNotifications?.length === 0 ? (
            <Typography
              fontSize={14}
              sx={{ my: 2, mx: 2 }}
              fontWeight={600}
              color="#4A4A4A"
            >
              {t('Notifications.ThereAreNoNewNotifications')}
            </Typography>
          ) : (
            <Typography
              fontSize={14}
              sx={{
                mt: 1,
                cursor: 'pointer',
                textDecoration: 'underline',
                mx: 2,
                mb: 2,
              }}
              fontWeight={600}
              color="#4A4A4A"
              onClick={() => setShowAllRead(!showAllRead)}
            >
              {showAllRead
                ? t('Notifications.SeeLess')
                : t('Notifications.LoadMore')}
            </Typography>
          )}

          <Paper
            elevation={1}
            sx={{
              pt: 2,
              px: 2,
              pb: 1.5,
              width: '100%',
              cursor: 'pointer',
              userSelect: 'none',
            }}
          >
            <Typography fontSize={18} fontWeight={700} color="#4A4A4A">
              {t('Notifications.Older')}{' '}
            </Typography>
          </Paper>
          {readNotifications.map(renderNotification)}
          {readNotifications?.length === 0 ? (
            <Typography
              fontSize={14}
              sx={{ mt: 2, mx: 2 }}
              fontWeight={600}
              color="#4A4A4A"
            >
              {t('Notifications.YouDontHaveAnyNotifications')}
            </Typography>
          ) : (
            <Typography
              fontSize={14}
              sx={{
                mt: 1,
                cursor: 'pointer',
                textDecoration: 'underline',
                mx: 2,
                mb: 2,
              }}
              fontWeight={600}
              color="#4A4A4A"
              onClick={() => setShowAllUnread(!showAllUnread)}
            >
              {showAllUnread
                ? t('Notifications.SeeLess')
                : t('Notifications.LoadMore')}
            </Typography>
          )}
          <ListingsFooter />
        </>
      ) : (
        <>
          <WebHeader />

          <Paper
            elevation={1}
            sx={{
              pt: 2,
              px: 2,
              pb: 1.5,
              width: '100%',
              cursor: 'pointer',
              userSelect: 'none',
              display: 'flex',

              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                pl: 4,
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
              fontSize={18}
              fontWeight={700}
              color="#4A4A4A"
            >
              Unread
            </Typography>
            <Typography
              sx={{
                pl: 6,
                width: '50%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
              fontSize={18}
              fontWeight={700}
              color="#4A4A4A"
            >
              Older
            </Typography>
          </Paper>

          <Box
            display="flex"
            alignItems="start"
            justifyContent={'space-evenly'}
          >
            <Box sx={{ flex: 1 }}>
              {unreadNotifications?.map(renderNotification)}
              {unreadNotifications?.length === 0 && (
                <Typography
                  fontSize={14}
                  sx={{ mt: 2, mx: 2, pl: 4 }}
                  fontWeight={600}
                  color="#4A4A4A"
                >
                  There are no new notifications.
                </Typography>
              )}
            </Box>
            <Paper
              elevation={1}
              sx={{
                pt: 2,
                px: 2,
                pb: 1.5,
                mt: 0.2,
                minHeight: '74.25vh',
                cursor: 'pointer',
                userSelect: 'none',
              }}
            />
            <Box sx={{ flex: 1 }}>
              {' '}
              {readNotifications.map(renderNotification)}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Notifications;
