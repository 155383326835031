/* eslint-disable react/prop-types */
// AnimatedProgress.js
import React, { useEffect, useState } from 'react';
import { LinearProgress, Typography } from '@mui/material';
import './animationStyle.css'; // Ensure the path is correct for your project
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function AnimatedProgress({ isLoading, isCompleted, navigateTo }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fontColor } = useSelector((state) => state.listing);

  const [displayLoading, setDisplayLoading] = useState(isLoading);

  useEffect(() => {
    let timer;
    setDisplayLoading(true);
    if (isLoading) {
      setDisplayLoading(true);
    } else {
      // Set a timer to keep displaying the loading state for 2 more seconds
      timer = setTimeout(() => setDisplayLoading(false), 2000);
    }

    return () => clearTimeout(timer); // Clean up the timer
  }, [isLoading]);

  useEffect(() => {
    if (!displayLoading && isCompleted) {
      setTimeout(() => {
        navigate(navigateTo);
      }, 3200);
    }
  }, [displayLoading, isCompleted, navigateTo, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        {displayLoading && <LinearProgress sx={{ width: 150, m: 'auto' }} />}
      </div>
      {!displayLoading && (
        <>
          <div
            className={`circle-loader ${isCompleted ? 'load-complete' : ''}`}
          >
            <div className={`checkmark ${isCompleted ? 'draw view' : ''}`} />
          </div>
          <Typography
            variant="h6"
            align="center"
            fontWeight={600}
            mt={-2}
            color={fontColor}
          >
            {t('AddListing.Listing Added Successfully')}!
          </Typography>
        </>
      )}
    </div>
  );
}

export default AnimatedProgress;
