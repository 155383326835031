export const sellerSiteInspection = [
  'Set Site Inspection Status',
  'Site Inspection Available',
  'Site Inspection Not Available',
];

export const buyerSiteInspection = [
  'Set Site Inspection Status',
  'Site Inspection Needed',
  'Site Inspection Not Needed',
];

export const sellerDeliveryStatus = [
  'Set Delivery Status',
  'Delivery Available',
  'Delivery Not Available',
  "I'm flexible",
];

export const buyerPickUpStatus = [
  'Set Delivery Status',
  'Pickup Possible',
  'Pickup Not Possible',
  "I'm flexible",
];
